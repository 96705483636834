import { ensureDisplayText } from '@icp/utils';
import { findI18nValues } from '@icp/i18n';
import { get } from 'lodash-es';
import { excludeData } from '../ACLElement/ACL';

export const DEFAULT_VALUE_TYPE = 'itemAlwaysArray';
export const DEFAULT_MAPPING = { value: 'value', label: 'label' };

export function originToMapping(item, mapping) {
  if (!item) return item;

  const newItem = {
    ...item,
    value: item[mapping.value],
    label: item[mapping.label],
  };

  if (mapping.value !== 'value') {
    delete newItem[mapping.value];
  }
  if (mapping.label !== 'label') {
    delete newItem[mapping.label];
  }

  // 处理 label 有多语言资源的情况
  const labelI18nValues = findI18nValues(newItem, mapping.label);
  for (const [suffix, i18nValue] of Object.entries(labelI18nValues)) {
    newItem[`label${suffix}`] = i18nValue;
    delete newItem[`${mapping.label}${suffix}`];
  }

  return newItem;
}

export function mappingToOrigin(item, mapping) {
  if (!item) return item;

  const newItem = {
    ...item,
    [mapping.value]: item.value,
    [mapping.label]: item.label,
  };

  if (mapping.value !== 'value') {
    delete newItem.value;
  }
  if (mapping.label !== 'label') {
    delete newItem.label;
  }

  // 处理 label 有多语言资源的情况
  const labelI18nValues = findI18nValues(newItem, 'label');
  for (const [suffix, i18nValue] of Object.entries(labelI18nValues)) {
    newItem[`${mapping.label}${suffix}`] = i18nValue;
    delete newItem[`label${suffix}`];
  }

  return newItem;
}

export function selectFindOption(options, value, mapping, stringEqual) {
  const find = (v) => {
    return options.find((item) => {
      return stringEqual ? String(item[mapping.value]) === String(v) : item[mapping.value] === v;
    });
  };

  // 里面可能会存在找不到的 options，后续操作依此来判断是不是需要用已经存下来的旧 value
  if (Array.isArray(value)) {
    return value.map(find);
  }

  return find(value);
}

function formatOptionsByStringEqual(options, mapping) {
  if (!options || !options.length) {
    return [];
  }
  return options.map((op) => {
    return {
      ...op,
      [mapping.value]: String(op[mapping.value]),
    };
  });
}

export function formatOptions({ options, mapping, stringEqual, dataExclusion }) {
  if (!Array.isArray(options)) {
    return [];
  }

  if (stringEqual) {
    options = formatOptionsByStringEqual(options, mapping);
  }

  if (Array.isArray(dataExclusion) && dataExclusion.length) {
    return excludeData(options, dataExclusion, mapping.value);
  }

  return options;
}

export function getValueOfObjectValue(item, mapping, useOriginValue) {
  if (!item || typeof item !== 'object') return null;
  return useOriginValue ? item[mapping.value] : item.value;
}

export function getLabelOfObjectValue(item, mapping, useOriginValue) {
  if (!item || typeof item !== 'object') return null;
  return useOriginValue ? item[mapping.label] : item.label;
}

export function getDisplayText({
  valueProp,
  options,
  mapping,
  useOriginValue,
  findOption = selectFindOption,
  hideMeaningLessValue,
  stringEqual,
}) {
  if (!valueProp) return '';

  const getLabel = (item) => {
    // 如果是 object 有 label 直接显示，不显示 options 里最新的
    if (typeof item === 'object' && item !== null) {
      const label = getLabelOfObjectValue(item, mapping, useOriginValue);

      if (label !== null && label !== undefined) {
        return label;
      }
    }

    // 否则根据 options 里的 label 显示
    const value = getValueOfObjectValue(item, mapping, useOriginValue) ?? item;
    const option = findOption(options, value, mapping, stringEqual);
    if (option) {
      return option[mapping.label];
    }

    // 找不到可以显示的 label，fallback 显示看不懂的 value
    return !hideMeaningLessValue ? value : '';
  };

  if (Array.isArray(valueProp)) {
    return valueProp.map(getLabel).join(', ');
  }

  return ensureDisplayText(getLabel(valueProp));
}

export function toComponentValue({
  valueProp,
  options,
  findOption = selectFindOption,
  multiple,
  mapping,
  useOriginValue,
  stringEqual,
}) {
  let labeledValue;

  const toLabeledValueItem = (v) => {
    let item;
    if (typeof v === 'object' && v) {
      item = { ...v };
    } else if (typeof v === 'number' || typeof v === 'string' || typeof v === 'boolean') {
      item = { value: v };
    }

    if (useOriginValue) {
      item = originToMapping(item, mapping);
    }

    // 判断 undefined 为了兼容项目开发者偷懒 只写了[{}]的导致页面展示undefined情况
    if (item && stringEqual && item.value !== undefined) {
      item.value = String(item.value);
    }

    // 兼容存的值里面是 { value: 'abc', label: null }，没有 label 的情况。否则 ant select 只会显示成 'abc' 不会去找 options 的 label 来显示
    if (item && typeof item.label !== 'string' && Array.isArray(options)) {
      const op = findOption(options, item.value, mapping, stringEqual);
      // mapping.label 可以是 a[0].b 的形式
      item.label = get(op, mapping.label);
    }

    return item;
  };

  if (multiple) {
    // material 要求 multiple 的时候 value 必须是数组
    labeledValue = []
      .concat(valueProp)
      .filter((x) => x !== undefined && x !== null)
      .map(toLabeledValueItem);
  } else if (Array.isArray(valueProp)) {
    labeledValue = toLabeledValueItem(valueProp[0]);
  } else {
    labeledValue = toLabeledValueItem(valueProp);
  }

  return labeledValue;
}

export function toSimpleValue(value) {
  const getValue = (item) => {
    if (Array.isArray(item)) {
      return item.map(getValue);
    }

    if (typeof item === 'object' && item !== null) {
      return item.value;
    }
    return item;
  };
  return getValue(value);
}

export function toValueItem(option, mapping, useOriginValue, isStandardDataSource) {
  const item = !isStandardDataSource
    ? {}
    : {
        // 表单引擎标准数据源后端dot walking功能，约定hardcode mapping: id -> id
        id: option.id != null ? +option.id : undefined,
      };
  for (const [k, v] of Object.entries(mapping)) {
    item[k] = k === 'value' ? option[v] : get(option, v);

    // eg：mapping 是 { value: 'id', label: 'name' }，
    // 当 options 里存在 { id: 12345, name: 'abc', name_i18n_en-US: 'abc-en' } 等 name 的多语言资源时，
    // 给 item 设置上 item.label_en-US = 'abc-en'。
    const i18nValues = findI18nValues(option, v);
    for (const [suffix, i18nValue] of Object.entries(i18nValues)) {
      item[`${k}${suffix}`] = i18nValue;
    }
  }

  if (!useOriginValue) {
    return item;
  }

  return mappingToOrigin(item, mapping);
}

function findOldValueItem({ key, oldValue, mapping, useOriginValue, stringEqual }) {
  if (key === undefined || key === null) {
    return null;
  }
  // oldValue 是存下来的原始值没有经过任何转换
  return [].concat(oldValue).find((item) => {
    let v;
    if (typeof item === 'object' && item) {
      // 兼容 useOriginValue
      v = useOriginValue ? item[mapping.value] : item.value;
    } else if (typeof item === 'number' || typeof item === 'string' || typeof item === 'boolean') {
      // 兼容 valueType 是 value 或者 valueAlwaysArray
      v = item;
    }
    // 兼容 stringEqual
    if (v !== undefined && stringEqual) {
      v = String(v);
    }
    return v === key;
  });
}

export function toInterfaceValue({
  oldValue,
  newValue,
  options,
  findOption = selectFindOption,
  multiple,
  valueType,
  mapping,
  useOriginValue,
  stringEqual,
  isStandardDataSource,
}) {
  const op = findOption(options, newValue, mapping, stringEqual);

  if (multiple) {
    if (valueType === 'value' || valueType === 'valueAlwaysArray') {
      return newValue;
    }
    return op
      ?.map((option, index) => {
        if (option) {
          return toValueItem(option, mapping, useOriginValue, isStandardDataSource);
        }
        // 如果没有 option，尝试从 oldValue 里找到旧的 value 直接返回，不清已经存下来的旧数据
        const key = newValue[index];
        return findOldValueItem({ key, oldValue, mapping, useOriginValue, stringEqual });
      })
      .filter(Boolean);
  }

  switch (valueType) {
    case 'value':
      return newValue ?? null; // 当使用allowClear时，如果清空了，antd会返回undefined，不会被序列化传给后台，因此需要返回null
    case 'valueAlwaysArray':
      return newValue ? [newValue] : [];
    case 'item':
      return op ? toValueItem(op, mapping, useOriginValue) : null; // 当使用allowClear时，如果清空了，antd会返回undefined，不会被序列化传给后台，因此需要返回null
    case 'itemAlwaysArray':
    default:
      return op ? [toValueItem(op, mapping, useOriginValue, isStandardDataSource)] : [];
  }
}

export function getMaterialDisplayLabel({ options, value, v, mapping }) {
  const innerValueItem = [].concat(value).find((item) => item.value === v);

  // 如果已经保存了 label，直接显示
  if (innerValueItem?.label) {
    return innerValueItem.label;
  }

  const op = (options || []).find((item) => item[mapping.value] === v);

  // 如果有 options，直接显示 options 里最新的值
  if (op) {
    return op[mapping.label];
  }

  return v;
}

function getValueText(op, key) {
  const value = get(op, key);

  const getText = (v) => {
    if (typeof v === 'object' && v) {
      return v.label ?? v.longText ?? '';
    }

    return v ?? '';
  };

  if (Array.isArray(value)) {
    return value.map(getText).join('');
  }
  return getText(value);
}

export function filterOption(inputValue, option, searchTextColIds) {
  const optionTextToSearch = searchTextColIds.map((key) => getValueText(option, key)).join('');
  return `${optionTextToSearch}`.toLowerCase().includes((inputValue || '').toLowerCase());
}
