import PropTypes from 'prop-types';
import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { composeEvent } from '@icp/utils';

function I18nTextareaSingle(props) {
  const { id, value: valueProp, languageOp, onChange, onBlur, changeDelayToBlur, ...other } = props;

  const valueI18nKey = `longText_i18n_${languageOp.value}`;

  const valueI18n = valueProp?.[valueI18nKey];

  const [value, setValue] = useState(valueI18n);

  useEffect(() => {
    setValue(valueI18n);
  }, [valueI18n]);

  const handleChange = (event) => {
    if (changeDelayToBlur) {
      setValue(event.target.value);
    } else {
      onChange(event.target.value, languageOp.value);
    }
  };

  const handleBlur = (event) => {
    if (changeDelayToBlur) {
      onChange(event.target.value, languageOp.value);
    }
  };

  return (
    <div className="textarea-element-i18n-single">
      <div className="textarea-element-addon">{languageOp.label}</div>
      <Input.TextArea
        {...other}
        value={value ?? ''}
        onChange={handleChange}
        onBlur={composeEvent(handleBlur, onBlur)}
      />
    </div>
  );
}

I18nTextareaSingle.propTypes = {
  id: PropTypes.string,
  changeDelayToBlur: PropTypes.bool,
  value: PropTypes.shape({
    longText: PropTypes.string,
  }),
  languageOp: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default I18nTextareaSingle;
