import {
  setFieldInitialValue,
  resetForm,
  selectContext,
  selectErrors,
  selectFieldValue,
  selectInitialValues,
  selectIsFormDataDirty,
  selectValues,
  setErrors,
  setFieldError,
  setFieldValue,
  setValues,
  validateForm,
} from '../store';
import {
  AsyncManager,
  EventService,
  FORM_API_STAGE,
  getDataWithPreprocessor,
  JS_API_EVENT_TYPES,
} from '../index';

// copy 一份，防止通过 js code 里从类似 EditableTable get 数据再 set 给 form，会导致 EditableTable 无法再 mutable 的修改数据
function copy(value) {
  if (value && typeof value === 'object') {
    return JSON.parse(JSON.stringify(value));
  }
  return value;
}

export default function injectFormApi({
  formApiEmpty: formApi,
  store,
  dispatch,
  events,
  fieldApis,
  fieldJSComponentProps,
}) {
  // 给 ButtonElement formMethod action 调用的注册函数
  const methods = {};

  formApi.stage = FORM_API_STAGE.init;
  formApi.node = null;
  formApi.eventService = new EventService(events, JS_API_EVENT_TYPES);
  formApi.asyncComponentManager = new AsyncManager();
  formApi.on = (type, listener) => {
    formApi.eventService.on(type, listener);
  };
  formApi.off = (type, listener) => {
    formApi.eventService.off(type, listener);
  };
  formApi.getData = () => {
    return copy(selectValues(store.getState()));
  };
  formApi.getDataWithPreprocessor = () => {
    return copy(getDataWithPreprocessor({ store, formApi }));
  };
  formApi.setData = (newData) => {
    dispatch(setValues(copy(newData || {})));
  };
  formApi.getContext = () => {
    return selectContext(store.getState());
  };
  // @deprecated
  // formApi.setFieldValue = ({ keyPath, value }) => dispatch(setFieldValue({ keyPath, value }));
  formApi.getValue = (keyPath) => {
    return selectFieldValue(keyPath)(store.getState());
  };
  formApi.setValue = (keyPath, value) => {
    dispatch(setFieldValue({ keyPath, value: copy(value) }));
  };
  formApi.getInitialValues = () => {
    return copy(selectInitialValues(store.getState()));
  };
  formApi.setInitialValue = (keyPath, initialValue, notReplaceValue) => {
    dispatch(setFieldInitialValue({ keyPath, initialValue: copy(initialValue), notReplaceValue }));
  };
  formApi.getErrors = () => {
    return selectErrors(store.getState());
  };
  formApi.setErrors = (errors) => {
    dispatch(setErrors(errors));
  };
  formApi.setError = (keyPath, error) => {
    dispatch(setFieldError({ keyPath, error }));
  };
  formApi.getFieldApis = () => {
    return fieldApis;
  };
  formApi.getFieldApi = (fieldId) => {
    return fieldApis[fieldId];
  };
  formApi.validateForm = () => {
    return dispatch(validateForm()).unwrap();
  };
  formApi.isDataValid = () => {
    return !Object.keys(selectErrors(store.getState())).length;
  };
  formApi.isDirty = () => {
    return selectIsFormDataDirty(store.getState());
  };
  formApi.registerMethod = (name, func) => {
    if (typeof func === 'function') {
      methods[name] = func;
    } else {
      console.error(`registerMethod: ${func} is not a function`);
    }
  };
  formApi.getMethods = () => methods;
  // 这个函数是为了解决在 json 模式下不能写 callback，而很多组件的属性都是 callback 的形式。
  formApi.setFieldComponentProps = (id, obj) => {
    fieldJSComponentProps.current[id] = { ...fieldJSComponentProps.current[id], ...obj };
  };
  const preSubmitSymbol = Symbol('preSubmitTasks');
  formApi[preSubmitSymbol] = new Set();

  formApi.registerPreSubmitTask = (promise) => {
    formApi[preSubmitSymbol].add(promise);
    promise.finally(() => {
      formApi[preSubmitSymbol].delete(promise);
    });
  };
  formApi.waitPreSubmitTasksFinish = async () => {
    await Promise.race([
      Promise.allSettled(formApi[preSubmitSymbol]),
      new Promise((resolve) => {
        setTimeout(resolve, 5000);
      }),
    ]);
  };
  formApi.submit = () => {}; // will be reset below
  formApi.refresh = () => {}; // will be reset below
  formApi.cancel = () => {}; // will be reset below
  formApi.reset = ({ initialValues } = {}) => {
    dispatch(resetForm({ initialValues }));
  };
  formApi.getProps = () => {}; // will be reset below
  formApi.render = () => {}; // will be reset below

  return formApi;
}
