import { ACTION_COLUMN } from '@icp/form-renderer';

export function getDataSourceScope(dataSource, dataUrl) {
  if (dataUrl?.startsWith(`/flow/api/flow-rest/`)) return 'flow';
  if (dataUrl) return 'customized';
  if (dataSource?.pbcToken) return 'project';
  if (dataSource?.token) return 'pbc';
  if (dataSource?.scope) return dataSource.scope;
  return 'pbc';
}

export function toGanttColumnDefs(tableColumnDefs) {
  return tableColumnDefs
    .filter((colDef) => colDef.type !== ACTION_COLUMN)
    .map((colDef, index) => {
      return {
        ...colDef,
        hide: index !== 0, // gantt 默认值显示一列
      };
    });
}

export function toTableColumnDefs(ganttColumnDefs) {
  return ganttColumnDefs.map((colDef) => {
    const newColDef = { ...colDef };
    if (newColDef.hide) {
      delete newColDef.hide;
    }
    if (newColDef.tree) {
      delete newColDef.tree;
    }
    return newColDef;
  });
}

export function weekEqual(a, b) {
  return ['', undefined, null].includes(a) && ['', undefined, null].includes(b);
}
