import { createContext, forwardRef, useEffect, useMemo, useRef } from 'react';
import { Provider, createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';
import PropTypes from 'prop-types';
import makeStore from './makeStore';
import { API_URLS_DEFAULT } from '../consts';

const ctx = createContext();

export const useStore = createStoreHook(ctx);
export const useDispatch = createDispatchHook(ctx);
export const useSelector = createSelectorHook(ctx);

function StoreProvider({ children, apiUrls, extra }) {
  const [store, clearListeners] = useMemo(() => makeStore({ apiUrls, extra }), [apiUrls, extra]);

  useEffect(() => {
    return () => {
      clearListeners();
    };
  }, [clearListeners]);

  return (
    <Provider store={store} context={ctx}>
      {children}
    </Provider>
  );
}

StoreProvider.propTypes = {
  children: PropTypes.node,
  apiUrls: PropTypes.shape({}),
  extra: PropTypes.shape({}),
};

export function withStoreProvider(Component) {
  return forwardRef((props, ref) => {
    const propsRef = useRef();
    propsRef.current = props;

    const apiUrls = useMemo(() => {
      return new Proxy(
        {},
        {
          get: (target, key) => {
            return propsRef.current?.chatConfiguration?.apiUrls?.[key] ?? API_URLS_DEFAULT[key];
          },
        },
      );
    }, []);

    const extra = useMemo(() => {
      return {
        get onMessagesChanged() {
          return propsRef.current?.eventHandlers?.onMessagesChanged;
        },
      };
    }, []);

    return (
      <StoreProvider apiUrls={apiUrls} extra={extra}>
        <Component ref={ref} {...props} />
      </StoreProvider>
    );
  });
}
