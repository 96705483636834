import { createContext, Fragment, useEffect, useMemo, useRef } from 'react';
import { Provider, createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearAllListeners } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { makeStore, setContext, DEINIT_KEY, Heartbeat } from '@icp/form-renderer-core';

const ctx = createContext();

export const useStore = createStoreHook(ctx);
export const useDispatch = createDispatchHook(ctx);
export const useSelector = createSelectorHook(ctx);

export default function StoreProvider({
  children,
  disableDirty,
  isInDesign,
  isFetching,
  yupSchema,
  context,
  defaultValues,
  valueExpressions,
  onFieldValueChange,
  formApi,
  heartbeat,
}) {
  const params = useParams();

  const store = useMemo(() => {
    return makeStore({
      disableDirty,
      isInDesign,
      isFetching,
      yupSchema,
      context,
      defaultValues,
      valueExpressions,
      onFieldValueChange,
      formApi,
      params,
      heartbeat,
    });
    // 1. context 变化不重新 make store，在 StoreUpdater 里进行处理；
    // 2. events 只接受初次渲染的配置，不接受更新；
    // 3. params 对象本身变了，属性没变不重新 make store，原因是在 PageRenderer 里使用 useLeaveConfirm 调用
    //    react router 的 useBlocker 在页面跳转出现 confirm 确认的时候（页面没有跳转），react router 的 params 就会变成一个新对象，导致表单数据丢失。
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heartbeat, disableDirty, yupSchema, defaultValues, valueExpressions, JSON.stringify(params)]);

  useEffect(() => {
    return () => {
      store[DEINIT_KEY]();
    };
  }, [store]);

  // store 改变的时候重新渲染后面所有组件
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newKey = useMemo(() => Date.now(), [store]);

  return (
    <Provider store={store} context={ctx}>
      <Fragment key={newKey}>
        <CleanUpListeners />
        {children}
        <StoreUpdater context={context} />
      </Fragment>
    </Provider>
  );
}

StoreProvider.propTypes = {
  children: PropTypes.node,
  disableDirty: PropTypes.bool,
  isInDesign: PropTypes.bool,
  isFetching: PropTypes.bool,
  yupSchema: PropTypes.shape({}),
  context: PropTypes.shape({}),
  defaultValues: PropTypes.shape({}),
  valueExpressions: PropTypes.arrayOf(PropTypes.shape({})),
  onFieldValueChange: PropTypes.string,
  formApi: PropTypes.shape({}),
  heartbeat: PropTypes.instanceOf(Heartbeat),
};

function CleanUpListeners() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllListeners());
    };
  }, [dispatch]);

  return null;
}

function StoreUpdater({ context }) {
  const dispatch = useDispatch();
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) return;

    dispatch(setContext(context));
  }, [context, dispatch]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  return null;
}
