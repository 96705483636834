import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { shouldTranslateByDefault } from '@icp/settings';
import { Loading } from '@icp/components';
import RecursionRenderer from '../RecursionRenderer';
import { useClassName, useDataSource } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { CurrentDataProvider } from '../currentDataCtx';
import { MultiDataSourceTypes, SingleDataSourceTypes } from '../propTypes';
import useEventService from '../hooks/useEventService';

export const JS_API_EVENT_TYPES = new Set(['dataFetched', 'childrenFirstRendered']);

function Effects({ onRendered }) {
  useEffect(() => {
    onRendered();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}

const DataWrapper = forwardRef(function DataWrapper(props, ref) {
  const {
    children,
    keyPath,
    className: classNameProp,
    fields = [],
    componentProps = {},
    style,
  } = props;

  const {
    style: componentStyle,
    alias,
    setToFormData = false,
    dataSource,
    dataUrl,
    dataFilters,
    sortModel,
    dataResponseKeyPath,
    transformDataResponse,
    translateDataResponse = shouldTranslateByDefault(),
    debounceTime,
    intervalTime,
    defaultValue,
    httpMethod,
    selectColId,
    multiDataSource,
    multiDataSourceFlat = false,
    renderWhileLoading,
    LoadingProps,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const [refresh, setRefresh] = useState({});

  const nodeRef = useRef(null);
  const eventService = useEventService({ otherComponentProps, JS_API_EVENT_TYPES });

  const { loading, dataFetched } = useDataSource({
    skip: isInDesign,
    refresh,
    alias,
    setToFormData,
    // single data source config
    dataSource,
    dataUrl,
    dataResponseKeyPath,
    dataFilters,
    sortModel,
    transformDataResponse,
    translateDataResponse,
    debounceTime,
    intervalTime,
    defaultValue,
    httpMethod,
    selectColId,
    // multi config
    multiDataSource,
    multiDataSourceFlat,
  });

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      on: (type, listener) => {
        eventService.current.on(type, listener);
      },
      getData: () => dataFetched,
      refresh: () => setRefresh({}),
    }),
    [dataFetched, eventService],
  );

  useEffect(() => {
    if (!loading && dataFetched) {
      eventService.current.dispatch({ type: 'dataFetched', payload: dataFetched });
    }
  }, [loading, dataFetched, eventService]);

  const noChildren = !children && !fields?.length;

  return (
    <ElementDecorator keyPath={keyPath}>
      <div
        {...otherComponentProps}
        className={clsx(
          'data-wrapper form-wrapper',
          {
            'no-children': noChildren,
          },
          className,
          classNameComp,
        )}
        style={{ ...style, ...componentStyle }}
        ref={nodeRef}
      >
        <CurrentDataProvider value={dataFetched}>
          {loading ? <Loading overlay={true} {...LoadingProps} /> : null}
          {!loading || renderWhileLoading || dataFetched ? (
            <>
              <Effects
                onRendered={() => {
                  eventService.current.dispatch({ type: 'childrenFirstRendered' });
                }}
              />
              {children || (
                <RecursionRenderer fields={fields} keyPath={(keyPath || []).concat('fields')} />
              )}
            </>
          ) : null}
        </CurrentDataProvider>
      </div>
    </ElementDecorator>
  );
});

DataWrapper.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  // id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * Root 元素的 className
     */
    className: PropTypes.string,
    /**
     * 将数据源的返回数据作为 context.dataSource 的某属性，页面组件可以通过 :context.dataSource.xxx 来调用
     */
    alias: PropTypes.string,
    /**
     * 将数据源的返回值放进去表单数据，默认替换所有表单数据，可以通过 merge 来合并
     */
    setToFormData: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        merge: PropTypes.bool,
      }),
    ]),
    /**
     * 自上一次请求后间隔多久再次请求刷新数据，单位毫秒
     */
    intervalTime: PropTypes.number,
    /**
     * loading时也渲染子元素
     */
    renderWhileLoading: PropTypes.bool,
    LoadingProps: PropTypes.shape({}),
    ...SingleDataSourceTypes,
    ...MultiDataSourceTypes,
  }),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
DataWrapper.displayName = 'Data';

export default withFieldWrapper(DataWrapper);
