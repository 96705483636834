import { tokenToCompName } from '@icp/form-schema';
import schemaFormMap from '@site/src/forms/schema-form-map.json';
import layoutIdSchemaMap from '@site/src/forms/layout-schema-map.json';
import allFormCode from './allFormCode';
import allPbcCode from './allPbcCode';
import {
  fetchLocalSchemaFromOldSchemaDir,
  fetchRemotePbcPageSchema,
  fetchRemotePbcEntityLayoutSchema,
  fetchRemoteSchemaFromLayoutId,
  fetchLocalPbcPageSchema,
  fetchLocalPbcEntityLayoutSchema,
} from './schemaApi';

/*
{
  [pbcToken]: {
    pages: { [pageToken]: true },
    forms: { [entityToken]: { [layoutToken]: true } },
  }
}
*/
const allLocalPbcSchema = process.env.ALL_LOCAL_PBC_SCHEMA;
// { [schemaId]: urlPath }
const allLocalOldSchema = process.env.ALL_LOCAL_OLD_SCHEMA;

function hasLocalSchema({ pbcToken, formEntityToken, layoutToken, layoutId, schemaId }) {
  schemaId = schemaId || layoutIdSchemaMap[layoutId];

  if (pbcToken) {
    const pbc = allLocalPbcSchema[pbcToken];
    if (!layoutToken) {
      return Boolean(pbc?.pages?.[schemaId]) || Boolean(pbc?.noInfoPages?.[schemaId]);
    }
    return Boolean(pbc?.forms?.[formEntityToken]?.[layoutToken]);
  }

  return Boolean(allLocalOldSchema[schemaId]);
}

function fetchLocalSchema({ pbcToken, formEntityToken, layoutToken, layoutId, schemaId }) {
  schemaId = schemaId || layoutIdSchemaMap[layoutId];

  if (pbcToken && formEntityToken && layoutToken) {
    return fetchLocalPbcEntityLayoutSchema({ pbcToken, formEntityToken, layoutToken });
  }

  if (pbcToken && schemaId) {
    return fetchLocalPbcPageSchema({ pbcToken, schemaId });
  }

  if (schemaId) {
    return fetchLocalSchemaFromOldSchemaDir(schemaId);
  }

  return Promise.reject(new Error(`Invalid prop for fetch schema`));
}

function fetchRemoteSchema({ pbcToken, formEntityToken, layoutToken, layoutId, schemaId }) {
  if (pbcToken && formEntityToken && layoutToken) {
    return fetchRemotePbcEntityLayoutSchema({ pbcToken, formEntityToken, layoutToken });
  }

  if (pbcToken && schemaId) {
    return fetchRemotePbcPageSchema({ pbcToken, schemaId });
  }

  if (layoutId) {
    return fetchRemoteSchemaFromLayoutId({ layoutId });
  }

  return Promise.reject(
    new Error(
      `Invalid params to fetch schema, ${JSON.stringify({
        pbcToken,
        formEntityToken,
        layoutToken,
        layoutId,
        schemaId,
      })}`,
    ),
  );
}

export function fetchSchema(params) {
  // 如果前端打包有 schema 则优先使用，没有则使用 schemaProp
  const hasLocal = hasLocalSchema(params);
  if (hasLocal) {
    return fetchLocalSchema(params);
  }
  return fetchRemoteSchema(params);
}

function getCodeFromLocalPbc({ pbcToken, formEntityToken, layoutToken, schemaId }) {
  const pbc = allPbcCode[pbcToken];

  if (layoutToken) {
    const compName = tokenToCompName(layoutToken);
    return pbc?.forms?.[formEntityToken]?.[compName];
  }

  if (schemaId) {
    const compName = tokenToCompName(schemaId);
    return pbc?.pages?.[compName];
  }

  return null;
}

function getCodeFromOldSchemaDir(schemaId) {
  return allFormCode[schemaFormMap[schemaId]];
}

export function getLocalCode({ pbcToken, formEntityToken, layoutToken, schemaId }) {
  if (pbcToken) {
    // src/pbc 下的 json 生成的 code
    return getCodeFromLocalPbc({ pbcToken, formEntityToken, layoutToken, schemaId });
  }

  // 兼容老代码，appConfig/schema 里的 json 生成的 code
  if (schemaId) {
    return getCodeFromOldSchemaDir(schemaId);
  }

  return null;
}
