import dayjs from 'dayjs';
import {
  ActionCellRenderer,
  AutoCellRenderer,
  AutoIndexCellRenderer,
  DateCellRenderer,
  EnumCellRenderer,
  MultiValueCellRenderer,
  NumberCellRenderer,
  ProgressCellRenderer,
  StatusCellRenderer,
  TextCellRenderer,
  UploadCellRenderer,
} from './cellRenders';

import {
  ACL_COLUMN,
  ACTION_COLUMN,
  AUTO_COLUMN,
  AUTO_INDEX_COLUMN,
  CHECKBOX_COLUMN,
  DATE_COLUMN,
  ENUM_COLUMN,
  MULTI_VALUE_COLUMN, // @deprecated
  NUMBER_COLUMN,
  PROGRESS_COLUMN,
  RADIO_COLUMN,
  SELECT_COLUMN,
  STATUS_COLUMN,
  TEXTAREA_COLUMN,
  TEXT_COLUMN,
  TIME_COLUMN,
  UPLOAD_COLUMN,
} from './constant';
import { suppressEnterEventWhenEditing } from './utils';

// !important, cellEditor need use string, yupSchema use string to switch validation function
const COLUMN_TYPES = {
  [ACL_COLUMN]: {
    cellRenderer: MultiValueCellRenderer,
    cellEditor: 'ACLCellEditor',
    keyCreator: MultiValueCellRenderer.keyCreator,
    comparator: MultiValueCellRenderer.comparator,
    // cellClass: 'stringType', // export excel 要用，ag-grid props 的 excelStyles 的 id 对应的是 colDef.cellClass
    // 需要有 valueFormatter 给 clientSide 导出 excel 的时候使用。在 table props 的
    // defaultExcelExportParams.processCellCallback 里也可以处理，但是调用这个函数 ag-grid 会忽略
    // props.excelStyles，导致 '1234567890' 这种长字符串会被自动处理成 number 显示成科学技术法。
    valueFormatter: (params) => {
      const { value, colDef, context } = params;
      return MultiValueCellRenderer.toDisplayText(value, colDef.cellRendererParams, context);
    },
    suppressKeyboardEvent: suppressEnterEventWhenEditing,
    // filter 默认将会在 formatColumnDefs 里跟句 dataSource 配置自动选择
  },
  [ACTION_COLUMN]: {
    cellRenderer: ActionCellRenderer,
    flex: null,
    filter: false,
    sortable: false,
  },
  [AUTO_COLUMN]: {
    cellRenderer: AutoCellRenderer,
    cellEditor: 'TextCellEditor',
    keyCreator: AutoCellRenderer.keyCreator,
    comparator: AutoCellRenderer.comparator,
    valueFormatter: (params) => {
      const { value, colDef, context } = params;
      return AutoCellRenderer.toDisplayText(value, colDef.cellRendererParams, context);
    },
  },
  [AUTO_INDEX_COLUMN]: {
    cellRenderer: AutoIndexCellRenderer,
    width: 70,
    minWidth: 70,
    flex: null,
    filter: false,
    sortable: false,
    lockVisible: true,
  },
  // TODO, Checkbox 在 table 里显示是有问题的，没处理
  [CHECKBOX_COLUMN]: {
    cellRenderer: AutoCellRenderer,
    cellEditor: 'SelectCellEditor',
    cellEditorParams: {
      componentProps: {
        valueType: 'valueAlwaysArray',
      },
    },
  },
  [DATE_COLUMN]: {
    cellRenderer: DateCellRenderer,
    cellEditor: 'DateCellEditor',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        // 不能用 new Date(), 同样的 2024-05-22，
        // dayjs 转换会是 Wed May 22 2024 00:00:00 GMT+0800 (China Standard Time)
        // 而 new Date 是 Wed May 22 2024 08:00:00 GMT+0800 (China Standard Time)
        // filterLocalDateAtMidnight 是 aggrid 转换好的 Date 对象，是 dayjs 00:00:00 的形式
        const filterDate = dayjs(filterLocalDateAtMidnight).toDate();
        const cellDate = dayjs(cellValue).toDate();

        // 这里必须要返回值表示是 小、大、等于 之一，所以非法日期都作为比过滤日期小的
        if (cellDate < filterDate || Number.isNaN(cellDate.getDate())) {
          return -1;
        }
        if (cellDate > filterDate) {
          return 1;
        }
        return 0;
      },
    },
    valueFormatter: (params) => {
      const { value, colDef, context } = params;
      return DateCellRenderer.toDisplayText(value, colDef.cellRendererParams, context);
    },
  },
  [ENUM_COLUMN]: {
    cellRenderer: EnumCellRenderer,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: EnumCellRenderer.values,
      valueFormatter: EnumCellRenderer.valueFormatter,
    },
    comparator: EnumCellRenderer.comparator,
    valueFormatter: (params) => {
      const { value, colDef, context } = params;
      return EnumCellRenderer.toDisplayText(value, colDef.cellRendererParams, context);
    },
  },
  [NUMBER_COLUMN]: {
    cellRenderer: NumberCellRenderer,
    cellEditor: 'NumberCellEditor',
    filter: 'agNumberColumnFilter',
    comparator: (valueA, valueB) => valueA - valueB,
    valueFormatter: (params) => {
      const { value, colDef, context } = params;
      return NumberCellRenderer.toDisplayText(value, colDef.cellRendererParams, context);
    },
    useValueFormatterForExport: false,
  },
  [PROGRESS_COLUMN]: {
    cellRenderer: ProgressCellRenderer,
    filter: 'agNumberColumnFilter',
    comparator: (valueA, valueB) => valueA - valueB,
    valueFormatter: (params) => {
      const { value, colDef, context } = params;
      return ProgressCellRenderer.toDisplayText(value, colDef.cellRendererParams, context);
    },
    useValueFormatterForExport: false,
  },
  [RADIO_COLUMN]: {
    cellRenderer: AutoCellRenderer,
    cellEditor: 'SelectCellEditor',
    cellEditorParams: {
      componentProps: {
        valueType: 'value',
      },
    },
  },
  [SELECT_COLUMN]: {
    cellRenderer: MultiValueCellRenderer,
    cellEditor: 'SelectCellEditor',
    keyCreator: MultiValueCellRenderer.keyCreator,
    comparator: MultiValueCellRenderer.comparator,
    // cellClass: 'stringType',
    valueFormatter: (params) => {
      const { value, colDef, context } = params;
      return MultiValueCellRenderer.toDisplayText(value, colDef.cellRendererParams, context);
    },
    suppressKeyboardEvent: suppressEnterEventWhenEditing,
  },
  [TEXT_COLUMN]: {
    cellRenderer: TextCellRenderer,
    cellEditor: 'TextCellEditor',
    cellClass: 'stringType', // export excel 要用，ag-grid props 的 excelStyles 的 id 对应的是 colDef.cellClass
    valueFormatter: (params) => params.value,
  },
  [TEXTAREA_COLUMN]: {
    cellRenderer: AutoCellRenderer,
    cellEditor: 'TextareaCellEditor',
    keyCreator: AutoCellRenderer.keyCreator,
    cellClass: 'stringType',
    valueFormatter: (params) => params.value?.longText,
  },
  [TIME_COLUMN]: {
    cellRenderer: AutoCellRenderer,
    cellEditor: 'TimeCellEditor',
  },
  [UPLOAD_COLUMN]: {
    cellRenderer: UploadCellRenderer,
    cellEditor: 'UploadCellEditor',
    sortable: false,
    filter: false,
  },
  // @deprecated 不推荐用，使用 ENUM_COLUMN 替代功能更丰富
  [STATUS_COLUMN]: {
    cellRenderer: StatusCellRenderer,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: StatusCellRenderer.values,
      valueFormatter: StatusCellRenderer.valueFormatter,
    },
  },
  // @deprecated
  [MULTI_VALUE_COLUMN]: {
    cellRenderer: MultiValueCellRenderer,
    cellEditor: 'SelectCellEditor',
  },
};

export default COLUMN_TYPES;
