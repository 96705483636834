import PropTypes from 'prop-types';
import { forwardRef, useMemo } from 'react';
import { PAGE_TYPE, postFlowFormData } from '@icp/page-renderer-core';
import { Heartbeat } from '@icp/form-renderer-core';
import FormRendererSelector from '../FormRendererSelector';

const FormNodeFormRenderer = forwardRef(function FormNodeFormRender(props, ref) {
  const {
    pbcToken,
    flowToken,
    flowDefinitionId,
    flowInstanceId,
    threadId,
    instanceData,
    context: contextProp,
    heartbeat,
    FormRendererProps,
  } = props;

  const {
    data: defaultData,
    formEntityDataId,
    formEntityId,
    layoutId,
    pbcToken: formPbcToken,
    formEntityToken,
    layoutToken,
  } = instanceData;

  // context must memo, every context change will trigger FormRender make new store
  // EditableTableElement 里需要用到 formEntityId formEntityDataId
  const context = useMemo(() => {
    return {
      ...contextProp,
      pbcToken: formPbcToken,
      formEntityId,
      formEntityDataId,
      pageType:
        (flowInstanceId && PAGE_TYPE.flowEdit) ||
        (formEntityDataId && PAGE_TYPE.flowCreateByEditForm) ||
        PAGE_TYPE.flowCreate,
    };
  }, [contextProp, formPbcToken, formEntityId, formEntityDataId, flowInstanceId]);

  const handleSave = (data) => {
    return postFlowFormData({
      heartbeat,
      pbcToken,
      flowToken,
      formPbcToken,
      formEntityToken,
      layoutToken,
      flowDefinitionId,
      flowInstanceId,
      threadId,
      layoutId,
      saveOnly: true,
      data,
    }).then(
      (response) => {
        return { ...data, ...response };
      },
      (errors) => {
        return Promise.reject(errors);
      },
    );
  };

  const handleSubmit = (data) => {
    return postFlowFormData({
      heartbeat,
      pbcToken,
      flowToken,
      formPbcToken,
      formEntityToken,
      layoutToken,
      flowDefinitionId,
      flowInstanceId,
      threadId,
      data,
      layoutId,
    }).then(
      (response) => {
        return { ...data, ...response };
      },
      (errors) => {
        return Promise.reject(errors);
      },
    );
  };

  return (
    <div className="flow-form-renderer">
      <FormRendererSelector
        {...FormRendererProps}
        heartbeat={heartbeat}
        formEntityToken={formEntityToken}
        layoutToken={layoutToken}
        layoutId={layoutId}
        defaultData={{
          ...FormRendererProps?.defaultData,
          ...defaultData,
        }}
        context={context}
        onSubmit={handleSubmit}
        onSave={handleSave}
        ref={ref}
      />
    </div>
  );
});

FormNodeFormRenderer.propTypes = {
  heartbeat: PropTypes.instanceOf(Heartbeat),
  pbcToken: PropTypes.string,
  flowToken: PropTypes.string,
  // id from params is string
  flowDefinitionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flowInstanceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  threadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  instanceData: PropTypes.shape({
    data: PropTypes.shape({}),
    formEntityDataId: PropTypes.number,
    formEntityId: PropTypes.number,
    layoutId: PropTypes.number,
    pbcToken: PropTypes.string,
    formEntityToken: PropTypes.string,
    layoutToken: PropTypes.string,
    lock: PropTypes.shape({}),
  }),
  context: PropTypes.shape({}),
  FormRendererProps: PropTypes.shape({
    defaultData: PropTypes.shape({}),
  }),
};

export default FormNodeFormRenderer;
