import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, ConfigProvider, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useEventCallback } from '@icp/hooks';
import { readClipboardText } from '@icp/utils';
import Loading from '../../Loading';
import useSetFilterOptions from './useSetFilterOptions';

// const selectAllOp = 'internal-select-all-op';
const loadingIndicator = 'internal-lazy-loading-indicator';

function SetFilterValues(props) {
  const {
    className,
    multiple = false,
    autoFocus = false,
    context,
    colDef,
    values: valuesProp,
    onChange,
  } = props;
  const CellRenderer = colDef.cellRenderer;

  const values = useMemo(() => {
    return []
      .concat(valuesProp)
      .filter((v) => v !== undefined && v !== null)
      .map((v) => {
        // 兼容一下通过 json 配置了 set 的 values 直接就是 ['a', 'b'] 这种发给后端的情况
        if (typeof v !== 'object') {
          return { value: v, label: v };
        }
        return v;
      });
  }, [valuesProp]);

  const { t } = useTranslation(['icp-components', 'icp-vendor-aggrid']);

  const [container, setContainer] = useState(null);
  const [searchText, setSearchText] = useState('');
  const useClientSideFilter = useRef(false);
  const selectRef = useRef(null);

  const { options, loading, moreLoading, hasMore, loadMore, allDataIsFetched } =
    useSetFilterOptions({
      searchText: useClientSideFilter.current ? '' : searchText,
      context,
      colDef,
    });
  useClientSideFilter.current = allDataIsFetched;

  const optionsFiltered = useMemo(() => {
    if (loading || !Array.isArray(options)) {
      return [];
    }

    let items = options;
    if (useClientSideFilter.current) {
      // 没有 searchText 的时候还没有 hasMore，说明前端已有所有数据，此时改变 searchText 不需要再后端搜索,使用前端过滤
      // 暂时不支持使用 searchTextColIds 自定义前端搜索的搜索列，set filter 里目前也不支持自定义显示项，只会显示 label，所以只搜索可见的 label
      items = options.filter((op) => {
        return !searchText || op.label?.toLowerCase().includes(searchText.toLowerCase());
      });
    } else if (moreLoading) {
      items = [...options, { value: loadingIndicator, disabled: true }];
    }

    return items;
  }, [loading, moreLoading, options, searchText]);

  /* const isAllSelected = useMemo(() => {
    return (
      !hasMore &&
      values.length &&
      optionsFiltered.length &&
      !optionsFiltered.find((op) => !values.find((v) => op.value === v.value))
    );
  }, [hasMore, optionsFiltered, values]);

  const indeterminate = useMemo(() => {
    return (
      !isAllSelected &&
      values.length &&
      optionsFiltered.length &&
      (hasMore ||
        (optionsFiltered.find((op) => values.find((v) => op.value === v.value)) &&
          optionsFiltered.find((op) => !values.find((v) => op.value === v.value))))
    );
  }, [hasMore, isAllSelected, optionsFiltered, values]);

  const handleCheckAll = (event) => {
    event.stopPropagation();
    let newValues;
    const hasUnselect = optionsFiltered.find((op) => !values.find((v) => op.value === v.value));
    if (hasUnselect) {
      const rest = optionsFiltered.filter((op) => !values.find((v) => op.value === v.value));
      newValues = values.concat(rest);
    } else {
      newValues = values.filter((v) => !optionsFiltered.find((op) => op.value === v.value));
    }

    // 一个都没有设置为 undefined 表示没有此过滤条件
    onChange(newValues.length ? newValues : undefined);
  }; */

  const handleChange = (v) => {
    if (multiple) {
      const newValues = v.map((key) => {
        const option = optionsFiltered.find((item) => item.value === key);
        if (option) {
          return { value: option.value, label: option.label };
        }
        return values.find((item) => item.value === key);
      });
      onChange(newValues.length ? newValues : undefined);
      return;
    }

    if (!v) {
      onChange(undefined);
    } else {
      const option = optionsFiltered.find((op) => op.value === v);
      onChange({ value: option.value, label: option.label });
    }
  };

  const handleScroll = (event) => {
    if (!hasMore || moreLoading || loading) {
      return;
    }
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const isBottom = scrollHeight - scrollTop - clientHeight < 32 * 2;
    if (isBottom) {
      loadMore();
    }
  };

  // erp 项目组反馈的需要从 excel copy 多个过滤条件直接进行过滤。
  // 就不做校验了，filter 有错不会导致什么不好的数据问题。
  const handlePaste = useEventCallback(() => {
    readClipboardText().then((text) => {
      if (!text) {
        return;
      }

      text = text.replaceAll('\r', '\n').replaceAll('\r\n', '\n');
      const arr = text.split('\n').filter(Boolean);
      if (arr.length) {
        let newValues = [...values];
        Array.from(new Set(arr)).forEach((str) => {
          const ops = options.filter((op) => op.label === str);
          if (ops.length) {
            ops.forEach((op) => {
              const newItem = { value: op.value, label: op.label };
              const exists = values.find((v) => v.value === newItem.value);
              if (!exists) {
                newValues.push(newItem);
              }
            });
          } else {
            const newItem = { value: str, label: str };
            const exists = values.find((v) => v.label === newItem.label);
            if (!exists) {
              newValues.push(newItem);
            }
          }
        });
        newValues = multiple ? newValues : newValues[0];
        onChange(newValues);
        setSearchText('');
      }
    });
  });

  useEffect(() => {
    selectRef.current.nativeElement.querySelector('input').addEventListener('paste', handlePaste);
  }, [handlePaste]);

  return (
    <div className={clsx('icp-filter-set-values', className)} ref={setContainer}>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              optionHeight: 28,
              optionPadding: '2px 10px',
            },
          },
        }}
      >
        <Select
          open={!!container}
          notFoundContent={
            loading ? <Loading size={16} style={{ height: 56 }} /> : t('filter.no-options')
          }
          maxTagCount={10}
          listHeight="100%"
          listItemHeight={28}
          popupMatchSelectWidth={false}
          value={values}
          mode={multiple ? 'multiple' : undefined}
          showSearch={true}
          autoFocus={autoFocus}
          placeholder={t('filter.select-one-or-more')}
          /* TODO, select all 交互做不好 */
          /* options={
            optionsFiltered?.length
              ? [
                  {
                    value: selectAllOp,
                    label: t('selectAll', { ns: 'icp-vendor-aggrid' }),
                    disabled: true,
                  },
                ].concat(optionsFiltered)
              : optionsFiltered
          } */
          options={optionsFiltered}
          filterOption={false}
          allowClear={true}
          autoClearSearchValue={false}
          getPopupContainer={() => container}
          menuItemSelectedIcon={null}
          searchValue={searchText}
          onSearch={setSearchText}
          optionRender={(op) => {
            if (op.value === loadingIndicator) {
              return <Loading size={16} centered={false} delayed={false} />;
            }
            /* if (op.value === selectAllOp) {
              return (
                <div className="custom-label" onClick={handleCheckAll}>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={op.value === selectAllOp ? indeterminate : undefined}
                    style={{ pointerEvents: 'none' }}
                  />
                  {op.label}
                </div>
              );
            } */
            const checked = values.find((v) => op.value === v.value);
            return (
              <div className="custom-label">
                <Checkbox checked={checked} style={{ pointerEvents: 'none' }} />
                {/* TODO, 这里写死 ENUM_COLUMN， ENUM_COLUMN 这个 const 在 icp-form-renderer-react 里，无法 import */}
                {colDef.type === 'ENUM_COLUMN' ? (
                  <CellRenderer value={op.value} {...colDef.cellRendererParams} context={context} />
                ) : (
                  op.label
                )}
              </div>
            );
          }}
          ref={selectRef}
          onChange={handleChange}
          onPopupScroll={handleScroll}
        />
      </ConfigProvider>
    </div>
  );
}

SetFilterValues.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  autoFocus: PropTypes.bool,
  context: PropTypes.shape({}),
  colDef: PropTypes.shape({
    filterParams: PropTypes.shape({}),
    type: PropTypes.string,
    cellRenderer: PropTypes.elementType,
    cellRendererParams: PropTypes.shape({}),
  }),
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ]),
  onChange: PropTypes.func,
};

export default SetFilterValues;
