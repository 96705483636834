/* eslint-disable prefer-const */
import { immutableUnset } from '@icp/utils';
import {
  fieldHasContent,
  fieldHasItemsWithFields,
  forEachFieldInSchema,
  isElement,
  isLayout,
  isWrapper,
} from './schemaUtils';

function resolveFieldsDependency(schema) {
  const components = new Set();

  forEachFieldInSchema(schema, (field) => {
    if (field.hidden === true) {
      return;
    }

    if (isLayout(field)) {
      // 老的代码里手写 json 可能会有使用 "component": "CardLayout" 的形式，json 模式是兼容了的，这里也兼容一下，
      // 防止 duplicated import
      components.add(field.component.split('Layout')[0]);
    } else if (isWrapper(field)) {
      components.add(field.component.split('Wrapper')[0]);
    } else if (isElement(field)) {
      components.add(field.component);
    }
  });

  return Array.from(components).sort();
}

function addImport(codeArr, schema) {
  codeArr.push(`import { forwardRef } from 'react';`);
  codeArr.push(`import FormRenderer`);

  const namedImport = resolveFieldsDependency(schema);

  const hasNamedImport = namedImport.length;

  if (hasNamedImport) {
    codeArr.push(`, {`);
    for (const name of namedImport) {
      codeArr.push(`${name},`);
    }
    codeArr.push(`}`);
  }

  codeArr.push(` from '@icp/form-renderer';`);

  codeArr.push('\n\n');
}

function addPrefix(codeArr, compName) {
  // function component declare
  codeArr.push(`const ${compName} = forwardRef(function ${compName}(props, ref) {`);
  codeArr.push(`return (`);
}

function addSuffix(codeArr) {
  // jsx return suffix
  codeArr.push(')');
  // function component declare suffix
  codeArr.push('});');
  codeArr.push('\n\n');
}

function addExport(codeArr, compName) {
  codeArr.push(`export default ${compName};`);
}

function stringifySpecialObjectProperty(propertyValue) {
  const stringifyArray = () => {
    let str = '[';

    for (const arrayItem of propertyValue) {
      if (typeof arrayItem === 'object' && arrayItem !== null) {
        str += stringifySpecialObjectProperty(arrayItem);
      } else {
        str += arrayItem;
      }
      str += ',';
    }

    str += ']';

    return str;
  };

  const stringifyObject = () => {
    let valueString = '{';

    for (const [key, value] of Object.entries(propertyValue)) {
      if (['itemField', 'toolbarChildren', 'children', 'emptyChildren'].includes(key)) {
        // itemField toolbarChildren is ready jsx code, cannot be stringify twice
        valueString += `${key}: ${value}`;
        // 枚举一下白名单写明 columnDefs.cellRendererParams.actions 的访问路径，没必要直接遍历所有 object 判断是数组就往下。
        // columnDefs 和 cellRendererParams 是 table 独有大概率不会冲突，如果有别的组件有误判断 actions 的，则可能需要修改此逻辑。
      } else if (
        key === 'items' ||
        key === 'columnDefs' ||
        key === 'cellRendererParams' ||
        key === 'actions'
      ) {
        valueString += `${key}: ${stringifySpecialObjectProperty(value)}`;
      } else if (key.includes('-')) {
        // eg: label_i18n_en-US
        valueString += `"${key}": ${JSON.stringify(value)}`;
      } else {
        valueString += `${key}: ${JSON.stringify(value)}`;
      }
      valueString += ',';
    }

    valueString += '}';

    return valueString;
  };

  if (Array.isArray(propertyValue)) {
    return stringifyArray(propertyValue);
  }

  return stringifyObject(propertyValue);
}

function addProperty(codeArr, key, value, field) {
  if (value === undefined) {
    return;
  }

  if (value === null || typeof value === 'number') {
    codeArr.push(` ${key}={${value}}`);
    return;
  }

  if (typeof value === 'string') {
    codeArr.push(` ${key}="${value}"`);
    return;
  }

  if (key === 'componentProps' && typeof value === 'object' && Object.keys(value).length) {
    let hasSpecial = false;
    let newValue = { ...value };

    // handle for Select, etc
    if (value.itemField) {
      const itemFieldJSX = [];
      const noChildren = addFields(itemFieldJSX, [newValue.itemField]);
      if (!noChildren) {
        newValue.itemField = itemFieldJSX.join('');
      }
      hasSpecial = true;
    }

    // handle table toolbar fields
    if (Array.isArray(value.toolbarFields)) {
      const { toolbarFields } = newValue;
      delete newValue.toolbarFields;

      const fieldsJSX = [];
      const noChildren = addFields(fieldsJSX, toolbarFields);
      if (!noChildren) {
        if (toolbarFields.length > 1) {
          fieldsJSX.unshift('<>');
          fieldsJSX.push('</>');
        }
        newValue.toolbarChildren = fieldsJSX.join('');
      }

      hasSpecial = true;
    }

    // handle Page empty fields
    if (Array.isArray(value.emptyFields)) {
      const { emptyFields } = newValue;
      delete newValue.emptyFields;

      const fieldsJSX = [];
      const noChildren = addFields(fieldsJSX, emptyFields);
      if (!noChildren) {
        if (emptyFields.length > 1) {
          fieldsJSX.unshift('<>');
          fieldsJSX.push('</>');
        }
        newValue.emptyChildren = fieldsJSX.join('');
      }

      hasSpecial = true;
    }

    // handle field with items
    if (fieldHasItemsWithFields(field)) {
      newValue.items = newValue.items.map((item) => {
        const { fields, ...other } = item;

        if (!(Array.isArray(fields) && fields.length)) {
          return other;
        }

        const fieldsJSX = [];
        const noChildren = addFields(fieldsJSX, fields);
        if (!noChildren) {
          if (fields.length > 1) {
            fieldsJSX.unshift('<>');
            fieldsJSX.push('</>');
          }
          return {
            ...other,
            children: fieldsJSX.join(''),
          };
        }
        return other;
      });

      hasSpecial = true;
    }

    if (Array.isArray(value.columnDefs)) {
      newValue.columnDefs = newValue.columnDefs.map((colDef) => {
        const { actions } = colDef.cellRendererParams || {};
        const newColDef = { ...colDef };
        if (colDef.type === 'ACTION_COLUMN' && Array.isArray(actions)) {
          const newActions = actions.map((action) => {
            if (action.component === 'Button' || action.component === 'Switch') {
              hasSpecial = true;
              const fieldCodeArr = [];
              addField(fieldCodeArr, action);
              return fieldCodeArr.join('');
            }
            return action;
          });
          newColDef.cellRendererParams = {
            ...newColDef.cellRendererParams,
            actions: newActions,
          };
        }
        return newColDef;
      });
    }

    if (hasSpecial) {
      const valueString = stringifySpecialObjectProperty(newValue, 0);
      codeArr.push(` ${key}={${valueString}}`);

      return;
    }
  }

  codeArr.push(` ${key}={${JSON.stringify(value)}}`);
}

function addField(codeArr, field) {
  let { component, ...other } = field;

  codeArr.push(`<${component}`);

  let contentChildren = null;

  // handle Button, Text, etc. Display componentProps.content as children
  // componentProps.content 转成的 children，该属性不能和后续的 field.fields 同时存在否则 children 会冲突
  if (fieldHasContent(field)) {
    contentChildren = field.componentProps.content;
    other = immutableUnset(other, ['componentProps', 'content']);
  }

  for (const [key, value] of Object.entries(other)) {
    if (key !== 'fields') {
      addProperty(codeArr, key, value, field);
    }
  }

  codeArr.push(`>`);

  // when layout and wrapper has no children (no children or all children is hidden)
  const noChildren = addFields(codeArr, field.fields) && !contentChildren;

  if (noChildren) {
    codeArr.pop();
    codeArr.push(`/>`);
  } else {
    if (contentChildren) {
      codeArr.push(contentChildren);
    }
    codeArr.push(`</${component}>`);
  }
}

function addFields(codeArr, fields) {
  const dfs = (nestedFields) => {
    if (!Array.isArray(nestedFields)) {
      return true;
    }

    let allHidden = true;
    for (const field of nestedFields) {
      if (field.hidden === true) {
        continue;
      }

      allHidden = false;

      addField(codeArr, field);
    }

    return allHidden;
  };

  return dfs(fields);
}

function tokenToCompName(token) {
  // Automatically generate component name using name, for example:
  // name = 'app-my-page-schema' => compName = 'AppMyPage'
  return token
    .split('-')
    .filter(Boolean)
    .map((str) => {
      if (str === 'schema') {
        return '';
      }
      return str[0].toUpperCase() + str.substring(1, str.length);
    })
    .join('');
}

function schemaToJsx(schema = {}, token = 'untitled') {
  const codeArr = [];
  const compName = tokenToCompName(token);

  // ES6 import
  addImport(codeArr, schema);

  // component declare start
  addPrefix(codeArr, compName);

  // FormRenderer start tag
  codeArr.push('<FormRenderer {...props}');

  for (const [key, value] of Object.entries(schema.form || {})) {
    addProperty(codeArr, key, value);
  }

  codeArr.push('ref={ref} >');

  addFields(codeArr, schema.fields);

  // FormRenderer close tag
  codeArr.push('</FormRenderer>');

  // component declare close
  addSuffix(codeArr, compName);

  // export component with es6
  addExport(codeArr, compName);

  const code = codeArr.join('');

  return { code, compName };
}

export default schemaToJsx;
export { tokenToCompName };
