import {
  CROSS_LAYOUT_PROPERTIES_MAPPING,
  findSchemaFieldByToken,
  schemaFieldToDataField,
} from '@icp/form-schema';
import { get } from 'lodash-es';
// import { immutableSet } from '@icp/utils';
import {
  assignDataFieldProperties,
  extractDataFieldsFromSchema,
  filterFieldsNotInAnyLayouts,
  removeDuplicateFields,
  // getMapOfField, isCrossLayoutProperty
} from './formEntityHelper';
import { updateFieldOperation } from './formEntityOperations';
import { makeNewFieldFromBusinessField } from './makeNew';

function syncPropertiesToFields(formEntity, currentSchemaDataFields, needEnsureFields) {
  // fields in formEntity.fields and in extractedFields, sync changed properties
  let existingFields = formEntity.fields.map((dataField) => {
    const newDataField = currentSchemaDataFields.find((item) => item.token === dataField.token);

    // field not in current layout
    if (!newDataField) {
      return dataField;
    }

    return assignDataFieldProperties(dataField, newDataField);
  });

  if (needEnsureFields) {
    // fields not existing in any layout, delete it
    existingFields = filterFieldsNotInAnyLayouts(existingFields, formEntity.layouts);

    // New added fields which not existing in data.fields, insert it to data.fields manually.
    const addedFields = currentSchemaDataFields.filter((field) => {
      return !existingFields.find((dataField) => field.token === dataField.token);
    });

    return existingFields.concat(addedFields);
  }

  return existingFields;
}

function syncOneInputPropertiesToASchema(schema, token, schemaField) {
  const { keyPath: fieldKeyPath } = findSchemaFieldByToken(schema, token);
  let newSchema = schema;
  if (fieldKeyPath) {
    for (const keyPathOfLayout of CROSS_LAYOUT_PROPERTIES_MAPPING.KEY_PATHS.OF_LAYOUTS) {
      const newValue = get(schemaField, keyPathOfLayout);
      const oldValue = get(newSchema, fieldKeyPath.concat(keyPathOfLayout));
      // 不等才修改，否则会创建很多空对象导致无意义的 dirty
      if (newValue !== oldValue) {
        newSchema = updateFieldOperation(newSchema, fieldKeyPath, keyPathOfLayout, newValue);
      }
    }
  }
  return newSchema;
}

function syncPropertiesToOtherLayouts(currentLayout, formEntity, currentSchemaDataFields) {
  return formEntity.layouts.map((layout) => {
    if (layout === currentLayout) {
      return layout;
    }

    let newSchema = layout.schema;

    for (const field of currentSchemaDataFields) {
      const { field: schemaField } = findSchemaFieldByToken(currentLayout.schema, field.token);
      newSchema = syncOneInputPropertiesToASchema(newSchema, field.token, schemaField);
    }

    return { ...layout, schema: newSchema };
  });
}

/**
 * After edit in JSON mode, sync properties to data.fields and all other layouts.
 * @param currentLayout
 * @param formEntity
 * @returns {*&{fields, layouts}}
 */
export function syncSchemaAllInputFields(currentLayout, formEntity) {
  // 此操作是由 currentLayout.schema 批量改动触发的，所以需要先 extract fields from currentLayout.schema，
  // 然后同步其属性到其余的 layouts 里同样 id 的 input field。不能简单调用 ensureFields
  const currentSchemaDataFields = extractDataFieldsFromSchema(currentLayout.schema);

  const newFields = syncPropertiesToFields(formEntity, currentSchemaDataFields, true);
  const newLayouts = syncPropertiesToOtherLayouts(
    currentLayout,
    formEntity,
    currentSchemaDataFields,
  );

  return { ...formEntity, fields: newFields, layouts: newLayouts };
}

export function syncInputToken({ formEntity, schemaField, newToken, oldToken }) {
  if (!oldToken) {
    const newDataField = schemaFieldToDataField(schemaField, formEntity.pbcToken);
    newDataField.token = newToken;
    const newFields = formEntity.fields.concat(newDataField);
    return { ...formEntity, fields: removeDuplicateFields(newFields, 'token') };
  }

  const newFields = formEntity.fields.map((field) => {
    if (field.token !== oldToken) {
      return field;
    }

    const newField = { ...field, token: newToken };
    if (field.storageField) {
      // 有 prevToken 表示 a -> b -> c 依然没有保存数据，在 originalFormEntity 里它还是 a
      newField.preToken = field.preToken || oldToken;
    }
    return newField;
  });

  const newLayouts = formEntity.layouts.map((layout) => {
    const { keyPath } = findSchemaFieldByToken(layout.schema, oldToken);
    if (!keyPath) {
      return layout;
    }
    return updateFieldOperation(layout, ['schema'].concat(keyPath), 'id', newToken);
  });

  return {
    ...formEntity,
    // 这里暂时允许两个字段使用同样的 token，项目里可能会有两个字段根据条件来分别显示隐藏的情况
    fields: removeDuplicateFields(newFields, 'token'),
    layouts: newLayouts,
  };
}

export function syncBizField({ formEntity, schemaField, newToken, bizField }) {
  const newFields = formEntity.fields.map((field) => {
    if (field.token !== newToken) {
      return field;
    }
    const newSchemaField = makeNewFieldFromBusinessField(bizField, schemaField);
    const newDataField = {
      ...schemaFieldToDataField(newSchemaField, formEntity.pbcToken),
      source: bizField.uuid,
    };
    return assignDataFieldProperties(field, newDataField);
  });

  const newLayouts = formEntity.layouts.map((layout) => {
    const { field: theSchemaField, keyPath } = findSchemaFieldByToken(layout.schema, newToken);
    if (!theSchemaField) {
      return layout;
    }
    const newSchemaField = makeNewFieldFromBusinessField(bizField, theSchemaField);
    return updateFieldOperation(layout, ['schema'].concat(keyPath), [], newSchemaField);
  });

  return { ...formEntity, fields: newFields, layouts: newLayouts };
}

export function syncOneInputCrossProperties({ formEntity, currentLayout, token }) {
  const { field: schemaField } = findSchemaFieldByToken(currentLayout.schema, token);
  const extractedField = schemaFieldToDataField(schemaField, formEntity.pbcToken);

  const newFields = syncPropertiesToFields(formEntity, [extractedField], false);
  const newLayouts = syncPropertiesToOtherLayouts(currentLayout, formEntity, [extractedField]);

  return { ...formEntity, fields: newFields, layouts: newLayouts };
}

/* export function syncOneInputOneProperty({
  formEntity,
  currentLayout,
  token,
  propertyKeyPath,
  newValue,
}) {
  if (!isCrossLayoutProperty(propertyKeyPath)) {
    return formEntity;
  }

  // Update property in formEntity.fields
  const newFields = formEntity.fields.map((field) => {
    if (field.token !== token) {
      return field;
    }

    const [keyPathOfField, , , converter] = getMapOfField(propertyKeyPath);
    return immutableSet(field, [].concat(keyPathOfField), converter(newValue));
  });

  // Update to another layouts
  const newLayouts = formEntity.layouts.map((layout) => {
    if (layout === currentLayout) {
      return layout;
    }

    const { keyPath: fieldKeyPath } = findSchemaFieldByToken(layout.schema, token);

    if (!fieldKeyPath) {
      return layout;
    }

    const newSchema = updateFieldOperation(layout.schema, fieldKeyPath, propertyKeyPath, newValue);
    return { ...layout, schema: newSchema };
  });

  return { ...formEntity, fields: newFields, layouts: newLayouts };
} */

export function syncOneInputAllPropertiesToOtherLayouts({
  formEntity,
  currentLayout,
  token,
  tokens,
}) {
  const { field: fieldInCurrentLayout } = findSchemaFieldByToken(currentLayout.schema, token);

  const newLayouts = formEntity.layouts.map((layout) => {
    if (layout === currentLayout || !tokens.includes(layout.token)) {
      return layout;
    }

    const { keyPath } = findSchemaFieldByToken(layout.schema, token);

    const newSchema = updateFieldOperation(layout.schema, keyPath, [], fieldInCurrentLayout);

    return { ...layout, schema: newSchema };
  });

  return { ...formEntity, layouts: newLayouts };
}
