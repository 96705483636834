import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { shouldTranslateByDefault } from '@icp/settings';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { useElementDecorator, useIsInDesign } from '../../FormRenderCtx';
import { useClassName, useVariablePatternArray, useDataSourceSupportLazy } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType, SingleDataSourceTypes } from '../../propTypes';
import {
  toInterfaceValue,
  DEFAULT_VALUE_TYPE,
  DEFAULT_MAPPING,
  formatOptions,
  toComponentValue,
  getDisplayText,
} from './utils';
import SelectUI from './SelectUI';

const SelectElement = forwardRef(function SelectElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    value: valueProp,
    style,
    componentProps = {},
    readonly,
    helpers,
    onChange,
  } = props;

  const {
    mode: legacyMode, // @deprecated, Select 组件表单不应该支持 antd 的 tags 模式任意输入字符串。
    multiple: multipleProp = false,
    valueType = DEFAULT_VALUE_TYPE,
    mapping = DEFAULT_MAPPING,
    useOriginValue = false,
    stringEqual = false,
    childEnum, // TODO, delete childEnum, options
    options: optionsProp = childEnum,
    dataSource,
    dataUrl,
    dataFilters,
    sortModel,
    dataResponseKeyPath = 'results',
    transformDataResponse,
    translateDataResponse = shouldTranslateByDefault(),
    debounceTime,
    defaultValue,
    httpMethod,
    selectColId,
    needCount = false,
    dataExclusion: dataExclusionProp,
    showSearch: showSearchProp,
    searchTextColIds = [mapping.label],
    lazyLoading = false,
    cacheBlockSize = 30,
    itemField,
    onSearch,
    ...otherComponentProps
  } = componentProps;

  const multiple = multipleProp || legacyMode === 'multiple' || legacyMode === 'tags';
  const showSearch = showSearchProp !== undefined ? showSearchProp : multiple; // multiple 的时候默认开启搜索

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (childEnum) {
        console.warn(`'childEnum' is deprecated, use 'options' instead`);
      }
    }
  }, [childEnum]);

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const dataExclusion = useVariablePatternArray(dataExclusionProp);

  const [searchText, setSearchText] = useState('');

  const nodeRef = useRef(null);

  const { loading, dataFetched, moreLoading, hasMore, loadMore, allDataIsFetched } =
    useDataSourceSupportLazy({
      skip: isInDesign || !!optionsProp,
      lazy: lazyLoading,
      searchText,
      searchTextColIds,
      debounceLeading: !searchText,
      cacheBlockSize,
      // single data source config
      dataSource,
      dataUrl,
      dataResponseKeyPath,
      dataFilters,
      sortModel,
      transformDataResponse,
      translateDataResponse,
      debounceTime,
      defaultValue,
      httpMethod,
      selectColId,
      needCount,
    });

  const options = useMemo(() => {
    // TODO，有个无意义的性能消耗是每次懒加载都要把全部数据 format 一份
    return formatOptions({
      options: optionsProp || dataFetched,
      mapping,
      stringEqual,
      dataExclusion,
    });
  }, [dataExclusion, mapping, dataFetched, optionsProp, stringEqual]);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      getOptions: () => options,
    }),
    [options],
  );

  const innerValue = useMemo(() => {
    return toComponentValue({
      valueProp,
      options,
      multiple,
      mapping,
      useOriginValue,
      stringEqual,
    });
  }, [mapping, multiple, options, stringEqual, useOriginValue, valueProp]);

  // antd 在 showSearch 的时候会显示搜索框，在 onSearch 被调用之前它就会自己根据 searchValue filter options，
  // 这样会导致 bug：在 searchText change 触发 useDataSource 重新设置 loading true 之前 options 会闪烁一下
  // 成 antd 过滤后的 options，再变成 loading 加载状态。解决方法是需要 api search 的时候关闭 antd 自身的搜索功能
  // 下面代码解决了这个 bug 的同时，还做了一件事：当没有 searchText 的时候 options 已经有值了，说明并不需要服务器
  // 端搜索了，直接让 antd 组件自己搜索过滤就行。
  const useClientSideFilter = allDataIsFetched || !!optionsProp;

  // 为了避免当 valueType 是 value 的时候，fetching 过程中显示一串看不懂的 code
  const hideMeaningLessValue =
    (loading || (moreLoading && !options?.length)) &&
    innerValue &&
    (Array.isArray(innerValue) ? !innerValue[0]?.label : !innerValue.label);

  const readonlyText = useMemo(() => {
    if (!readonly) {
      return '';
    }

    return getDisplayText({
      valueProp,
      options,
      mapping,
      useOriginValue,
      stringEqual,
      hideMeaningLessValue,
    });
  }, [hideMeaningLessValue, mapping, options, readonly, stringEqual, useOriginValue, valueProp]);

  const handleChange = (newValue) => {
    const val = toInterfaceValue({
      oldValue: valueProp,
      newValue,
      options,
      multiple,
      valueType,
      mapping,
      useOriginValue,
      stringEqual,
      isStandardDataSource: !!dataSource,
    });
    onChange(val);
  };

  const handleSearch = (newSearchText) => {
    if (!useClientSideFilter) {
      setSearchText(newSearchText);
    }
    if (onSearch) {
      onSearch(newSearchText);
    }
  };

  const handleScroll = (event) => {
    if (!lazyLoading || !hasMore || moreLoading) {
      return;
    }
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const isBottom = scrollHeight - scrollTop - clientHeight < 32 * 2;
    if (isBottom) {
      loadMore();
    }
  };

  const handleClose = () => {
    setSearchText('');
    if (onSearch) {
      onSearch('');
    }
  };
  
  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'select-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <SelectUI
          {...props}
          componentProps={{
            ...otherComponentProps,
            className: classNameComp,
          }}
          readonlyText={readonlyText}
          options={loading ? [] : options}
          value={(() => {
            if (hideMeaningLessValue) {
              return multiple ? [] : {};
            }
            return innerValue;
          })()}
          multiple={multiple}
          legacyMode={legacyMode} // 暂时留着 mode 属性给 platform 自己写代码调用 SelectElement 的地方使用，后续 refactor 删掉
          mapping={mapping}
          showSearch={showSearch}
          useClientSideFilter={useClientSideFilter}
          searchTextColIds={searchTextColIds}
          loading={loading}
          lazyLoading={lazyLoading}
          moreLoading={moreLoading}
          itemField={itemField}
          onChange={handleChange}
          onSearch={showSearch ? handleSearch : undefined}
          onScroll={lazyLoading ? handleScroll : undefined}
          onClose={handleClose}
        />
      </div>
    </ElementDecorator>
  );
});

SelectElement.propTypes = {
  fieldApi: PropTypes.func,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      }),
    ),
  ]),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * @deprecated
     * 选择器的模式
     * @default 'single'
     */
    mode: PropTypes.oneOf(['single', 'multiple', 'tags']),
    /**
     * Describes the value is a `childEnum` item or a `childEnum` item's value,
     * describes whether the value is always an array no matter mode is single or multiple.
     *
     * example:
     *
     * Given childEnum: `[{ value: 1, label: 'AAA' }, { value: 'b', label: 'BBB' }]`
     *
     * When valueType is `value`, value would be `1` or `'b'` in `single` mode, `[1]` or `[1, 'b']` in `multiple` mode.
     *
     * When valueType is `item`, value would be `{ value: 1, label: 'AAA' }`  in `single` mode, `[{ value: 1, label: 'AAA' }, {...}]` in `multiple` mode.
     *
     * When valueType is `valueAlwaysArray`, value would be `[1]` in `single` mode, `[1, 'b']` in `multiple` mode.
     *
     * When valueType is `itemAlwaysArray`, value would be `[{ value: 1, label: 'AAA' }]` in `single` mode, `[{...}, {...}]` in `multiple` mode.
     */
    valueType: PropTypes.oneOf(['value', 'item', 'valueAlwaysArray', 'itemAlwaysArray']),
    /**
     * value 的映射关系，默认是 { value, label }
     */
    mapping: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      // Other key map to save
    }),
    /**
     * Given mapping: `{ value: 'id', label: 'name' }`,
     * When useOriginValue is `true`, value would be `[{ id: 'xxx',name: 'xxx' }]`,
     * When useOriginValue is `false`, value would be `[{ value: 'xxx',label: xxx' }]`,
     * @default false
     */
    useOriginValue: PropTypes.bool,
    stringEqual: PropTypes.bool,
    // TODO, delete childEnum, use options
    childEnum: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      }),
    ),
    /**
     * 可选项
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      }),
    ),
    ...SingleDataSourceTypes,
    /**
     * 在 api 返回的数据中排除某些数据
     */
    dataExclusion: PropTypes.arrayOf(PropTypes.string),
    /**
     * 是否打开搜索功能
     * @default false
     */
    showSearch: PropTypes.bool,
    /**
     * 是否开启懒加载
     */
    lazyLoading: PropTypes.bool,
    /**
     * 懒加载每次请求的数据量
     */
    cacheBlockSize: PropTypes.number,
    /**
     * option 的格式化功能，通过指定每个元素的 json schema 来实现，支持使用所有 form 的元素
     */
    itemField: PropTypes.shape({}),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    minLength: PropTypes.number,
    minLengthErrorMessage: PropTypes.string,
    maxLength: PropTypes.number,
    maxLengthErrorMessage: PropTypes.string,
  }),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
SelectElement.displayName = 'Select';

export default withFieldWrapper(SelectElement);
