import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { FormHelperTextMaterial } from '../../FormHelperText';

function isTypedNumber(newValue) {
  const newValueNumber = Number(newValue);
  return !Number.isNaN(newValueNumber) && String(newValueNumber).length === newValue.length;
}

const NumberPickerMaterial = forwardRef(function NumberPickerMaterial(props, ref) {
  const {
    id,
    title,
    value,
    disabled,
    readonly,
    componentProps,
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,
    // min, max, step, precision, formatOptions
  } = props;

  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setTempValue(newValue);

    if (isTypedNumber(newValue)) {
      onChange(Number(newValue));
    }
  };

  const handleBlur = () => {
    if (onTouchChanged) {
      onTouchChanged();
    }
    setTempValue(value);
  };

  return (
    <TextField
      {...componentProps}
      fullWidth={true}
      id={id}
      label={title}
      value={tempValue ?? ''}
      inputMode="numeric"
      error={status === 'error'}
      helperText={!helpersIsEmpty(helpers) ? <FormHelperTextMaterial helpers={helpers} /> : null}
      required={validation?.required}
      disabled={disabled}
      InputProps={{
        readOnly: readonly,
      }}
      type="number"
      onChange={handleChange}
      onBlur={handleBlur}
      ref={ref}
    />
  );
});

NumberPickerMaterial.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  componentProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

export default NumberPickerMaterial;
