import { useEffect } from 'react';
import { useFormApi } from '../../FormRenderCtx';

export default function useBindEditableTable({ pageApi, bindComponentId }) {
  const formApi = useFormApi();

  useEffect(() => {
    // 只绑定一次，不支持被绑定的组件会显示隐藏的情况
    formApi.on('ready', () => {
      const tableApi = formApi.getFieldApi(bindComponentId);
      const isEditableTable = tableApi && tableApi.gridApi && tableApi.updateRow;
      // TODO，支持绑绑定 Table、List 等组件的不同默认行为
      if (isEditableTable) {
        tableApi.gridApi.addEventListener('selectionChanged', () => {
          const selectRowData = tableApi.gridApi.getSelectedRows()[0];

          if (!selectRowData) {
            pageApi.renderEmpty();
          } else if (pageApi.formApi) {
            pageApi.formApi.setData(selectRowData);
          } else {
            pageApi.renderContent();
          }
        });

        tableApi.gridApi.addEventListener('cellValueChanged', (event) => {
          const newData = event.data;
          if (pageApi.formApi) {
            pageApi.formApi.setData(newData);
          }
        });

        pageApi.on('formApiReady', () => {
          pageApi.formApi.on('ready', () => {
            const selectRowData = tableApi.gridApi.getSelectedRows()[0];
            if (selectRowData) {
              pageApi.formApi.setData(selectRowData);
            }
          });

          pageApi.formApi.on('change', (newData) => {
            tableApi.updateRow(newData);
          });
        });
      }
    });
    // bindComponentId 不支持发生变化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
