import PropTypes from 'prop-types';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import { ConditionalPropertyPropType } from '../../propTypes';
import { FormHelperTextMaterial } from '../../FormHelperText';

function SwitchMaterial(props) {
  const {
    id,
    title,
    value,
    disabled,
    componentProps = {},
    validation,
    readonly,
    helpers,
    status,
    onChange,

    size,
  } = props;

  return (
    <FormControl
      required={validation?.required}
      disabled={disabled || readonly}
      error={status === 'error'}
    >
      <FormControlLabel
        control={
          <Switch
            {...componentProps}
            name={id}
            checked={value}
            required={validation?.required}
            size={size}
            onChange={(event) => onChange(event.target.checked)}
          />
        }
        label={title}
      />
      {!helpersIsEmpty(helpers) ? (
        <FormHelperText>
          <FormHelperTextMaterial helpers={helpers} />
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

SwitchMaterial.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  componentProps: PropTypes.shape({}),
  size: PropTypes.oneOf(['medium', 'small']),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  value: PropTypes.bool,
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  status: PropTypes.string,
  onChange: PropTypes.func,
};

export default SwitchMaterial;
