import { get } from 'lodash-es';
import parseJSON from './parseJSON';

// match :userProfile.useName in /prefix:userProfile.useName/suffix
// safari not support (?<!\\):, otherwise regExp can be /(?<!\\):([\w_.]+)/g
const regExp = /[\\]?:([a-zA-Z0-9_.[\]]+)/g;

// Math pattern only use one variable，such as  :id or :context.userProfile.userId
export function isVariable(pattern) {
  if (!pattern.startsWith(':')) {
    return false;
  }

  const match = pattern.match(regExp);

  if (!match) {
    return false;
  }

  return match.length === 1 && match[0].length === pattern.length;
}

function copy(value) {
  if (value && typeof value === 'object') {
    return JSON.parse(JSON.stringify(value));
  }
  return value;
}

/**
 * @param currentData, current data, normally it is form data.
 * @param formData, formData of FormRenderer
 * @param context, context data
 * @param params, react router page params
 * @param response, response data by previous request, such as ButtonElement `request` action
 * @param pattern, variable pattern expression
 * @param converter, convertor value when isVariable is false, by default undefined and null will convert to ''
 * @param failWhenNoVariableValue, If true, when variable pattern not match any data, will return null
 * @returns {string|*}
 * @example
 *
 * const currentData = { id: 3000, type: 'text' };
 * const context = { userProfile: { id: 1 } };
 * const params = { id: 3002, type: 'text' };
 * const response = { id: 3003, type: 'text' };
 * const pattern = '/page/:id/:context.userProfile.id/:params.id/:response.id/sub-page/\\:nothing';
 *
 * resolveVariablePattern({ currentData, context, params, response, pattern })
 * // => '/page/3000/1/3001/3002/sub-page/:nothing'
 */
export function resolveVariablePattern({
  pattern,
  currentData,
  formData,
  context,
  params,
  response,
  converter = (v) => v ?? '',
  failWhenNoVariableValue = false,
}) {
  if (typeof pattern !== 'string') {
    return pattern;
  }

  const getValue = (name) => {
    if (name.startsWith('context.')) {
      const key = name.slice('context.'.length);
      return context ? get(context, key) : `:${name}`;
    }

    if (name.startsWith('params.')) {
      const key = name.slice('params.'.length);
      return params ? get(params, key) : `:${name}`;
    }

    if (name.startsWith('searchParams.')) {
      const searchParams = new URLSearchParams(window.location.search);
      const key = name.slice('searchParams.'.length);
      return searchParams ? searchParams.get(key) : `:${name}`;
    }

    if (name.startsWith('response.')) {
      const key = name.slice('response.'.length);
      return response ? get(response, key) : `:${name}`;
    }

    if (name.startsWith('formData.')) {
      const key = name.slice('formData.'.length);
      return formData ? get(formData, key) : `:${name}`;
    }

    if (name.startsWith('auth.')) {
      const key = name.slice('auth.'.length);
      switch (key) {
        case 'accessToken':
          return parseJSON(window.localStorage.getItem('ACCESS_TOKEN'));
        case 'refreshToken':
          return parseJSON(window.localStorage.getItem('REFRESH_TOKEN'));
        default:
          return null;
      }
    }

    if (name.startsWith('i18n.')) {
      const key = name.slice('i18n.'.length);
      switch (key) {
        case 'language':
          // 直接引用@icp/i18n会造成循环引用，暂且直接从localStorage中取
          return window.localStorage.getItem('icp_i18n_lng');
        default:
          return null;
      }
    }

    return currentData ? get(currentData, name) : `:${name}`;
  };

  // 支持直接写 :currentData 来获取整个数据的对象，暂时只支持 currentData 和 formData 和 response，其余几个数据例如 context
  // 等内容太复杂了，不应该存在直接使用一整个 context 数据对象的情况
  if ([':currentData', ':formData', ':response'].includes(pattern)) {
    if (pattern === ':currentData') {
      // 深拷贝一份，防止引用过后再次修改提示不能修改 redux readnly object
      return copy(currentData);
    }
    if (pattern === ':formData') {
      return copy(formData);
    }
    if (pattern === ':response') {
      return copy(response);
    }
    return null;
  }

  // If pattern only use on variable, then return it's original variable type
  // For example，If typeof context.userProfile.userId === 'number'，then return a number type userId
  if (isVariable(pattern)) {
    const name = pattern.split(':')[1];
    return copy(getValue(name));
  }

  // match \. in prefix:id\.png
  const escapeExp = /\\([:_.])+/g;

  let hasNoVariableValue = false;
  // string replace, return a string always
  const resolved = pattern
    .replace(regExp, (match, name) => {
      // Support \ to escape, such as \:id will do nothing.
      if (match.startsWith('\\')) {
        return match;
      }
      // undefined and null will be treated as '' by default
      const value = getValue(name);

      if (value === undefined || value === null || value === '') {
        hasNoVariableValue = true;
      }

      return converter ? converter(value) : value;
    })
    .replace(escapeExp, (match, name) => {
      return name;
    });

  return failWhenNoVariableValue && hasNoVariableValue ? null : resolved;
}

/**
 * TODO, 应该叫 extractDataDependencyKeyPath 比较合理
 * @param str
 * @param currentData
 * @returns {*[]}
 * @example
 *
 * extractDataDependencyKeys(':id/:formData.manager.name/:context.userProfile.id/:params.id')
 * // => ['id', 'manager.name']
 *
 * * extractDataDependencyKeys(':id/:formData.manager.name/:context.userProfile.id/:params.id', { id: '12345' })
 * // => ['manager.name']
 */
export function extractDataDependencyIds(str, currentData) {
  if (typeof str !== 'string') {
    return [];
  }

  const ids = new Set();

  // If no currentData, :name means shorthand of formData.name in store
  if (!(currentData && Object.keys(currentData).length)) {
    const match = str.match(regExp) || [];
    match
      // We suppose context and params never change in a field.
      // Support \ to escape, such as \:id will do nothing.
      .filter((item) => {
        return (
          !item.startsWith('\\') && !item.startsWith(':context') && !item.startsWith(':params')
        );
      })
      .map((item) => item.split(':')[1])
      .forEach((id) => ids.add(id));
  }

  // :formData.name means data in store
  const formDataRegExp = /:formData.([\w_.]+)/g;
  const match = str.match(formDataRegExp) || [];
  match.map((item) => item.split(':formData.')[1]).forEach((id) => ids.add(id));

  return Array.from(ids);
}
