/* eslint-disable no-underscore-dangle */
import { get } from 'lodash-es';
import { excludeData } from '../ACLElement/ACL';

export function treeFindOption(treeData, value, mapping, stringEqual) {
  // treeCheckStrictly prop will make antd labelInValue always true, then value is always an object
  const getValue = (v) => {
    return typeof v === 'object' && v !== null ? v.value : v;
  };

  const dfs = (children, v) => {
    for (const item of children) {
      const result = stringEqual
        ? String(item[mapping.value]) === String(getValue(v))
        : item[mapping.value] === getValue(v);

      if (result) {
        return item;
      }

      if (item.children) {
        const found = dfs(item.children, v);
        if (found) {
          return found;
        }
      }
    }

    return null;
  };

  if (Array.isArray(value)) {
    return value.map((v) => dfs(treeData, v));
  }

  return dfs(treeData, value);
}

export function excludeTreeData(treeDataFetched, dataExclusion, key) {
  const dfs = (node) => {
    if (!Array.isArray(node.children)) {
      return;
    }
    node.children = excludeData(node.children, dataExclusion, key);
    for (const item of node.children) {
      dfs(item);
    }
  };

  const root = { children: treeDataFetched };
  dfs(root);
  return root.children;
}

// 兼容老配置 buildTreeBy 是 string
export function legacySupportBuildTreeBy(valueProp) {
  if (!valueProp) {
    return null;
  }
  if (typeof valueProp === 'string') {
    return {
      parent: valueProp,
      parentPointee: 'id',
    };
  }
  if (typeof valueProp === 'object' && valueProp) {
    return {
      parent: valueProp.parent,
      parentPointee: valueProp.parentPointee || 'id',
    };
  }
  return {
    parent: '',
    parentPointee: '',
  };
}

function getParentId(item, buildTreeBy) {
  const value = get(item, buildTreeBy.parent);

  // 自动识别一下 value，如果用表单引擎配置出来的选择父亲的一般是 select 或者 acl，值都是 { value, label } 的
  // 形式，可视化设置的时候选择字段就行了不用自己手动填 parentId[0].value 这种形式。
  if (Array.isArray(value)) {
    return value[0]?.value;
  }

  if (typeof value === 'object' && value !== null) {
    return value.value;
  }

  return value;
}

export function buildTree(data, buildTreeBy) {
  if (!buildTreeBy.parent || !buildTreeBy.parentPointee) {
    return data;
  }

  const existsParentNode = (parentId) => {
    // 用 stringEqual，acl select 等会把 number 的 value 转成 string，这里直接简单 stringEqual
    return data.find((e) => `${get(e, buildTreeBy.parentPointee)}` === `${parentId}`);
  };

  const rootNodes = data.filter((item) => {
    const parentId = getParentId(item, buildTreeBy);
    // 如果有 parentId，但是未找到，则将该节点设置为根节点
    if (parentId) {
      return !existsParentNode(parentId);
    }
    return !parentId;
  });

  let childNodes = data.filter((item) => {
    return !rootNodes.includes(item);
  });

  const forEachLeaf = (children, callback) => {
    if (!children) {
      return;
    }
    for (const node of children) {
      if (!node.children) {
        callback(node);
      }
      forEachLeaf(node.children, callback);
    }
  };

  let prevLength = childNodes.length;
  while (childNodes.length) {
    // eslint-disable-next-line no-loop-func
    forEachLeaf(rootNodes, (node) => {
      node.children = childNodes.filter((item) => {
        const parentId = getParentId(item, buildTreeBy);
        return String(get(node, buildTreeBy.parentPointee)) === String(parentId);
      });
      if (!node.children.length) {
        delete node.children;
      }
      childNodes = childNodes.filter((item) => {
        const parentId = getParentId(item, buildTreeBy);
        return String(get(node, buildTreeBy.parentPointee)) !== String(parentId);
      });
    });
    if (prevLength === childNodes.length) {
      break;
    }
    prevLength = childNodes.length;
  }

  return rootNodes;
}

export function formatTreeDataByStringEqual({ treeData, mapping }) {
  const dfs = (items) => {
    if (!items || !items.length) {
      return null;
    }
    return items.map((op) => {
      const newOp = {
        ...op,
        [mapping.value]: String(op[mapping.value]),
      };
      if (newOp[mapping.children]) {
        newOp[mapping.children] = dfs(newOp[mapping.children]);
      }
      return newOp;
    });
  };

  return dfs(treeData);
}

export function formatOptions({
  options,
  mapping,
  stringEqual,
  buildTreeBy,
  dataExclusion,
  treeLazyLoading,
  loading,
}) {
  let treeData = options;

  if (loading || !Array.isArray(treeData)) {
    return [];
  }

  // 树状结构懒加载的时候 dataFetched 已经是树状结构，不需要浪费性能再次构建
  if (buildTreeBy && !treeLazyLoading) {
    treeData = buildTree(treeData, buildTreeBy);
  }

  if (stringEqual) {
    treeData = formatTreeDataByStringEqual({ treeData, mapping });
  }

  // 树状结构移除节点的同时就会移除掉其所有子孙节点
  if (Array.isArray(dataExclusion) && dataExclusion.length) {
    return excludeTreeData(treeData, dataExclusion, mapping.value);
  }

  if (treeLazyLoading) {
    forEachTree(treeData, (item, parent) => {
      // api 返回 _hasChildren true 表示有子节点
      // antd lazy 靠 item.isLeaf 来判断是否是叶子节点
      if (!item._hasChildren) {
        item.isLeaf = true;
      }
      // 加了个父节点引用方便生成 groupKeys
      if (parent) {
        item._parent = parent;
      }
    });
  }

  return treeData;
}

function forEachTree(treeData, cb) {
  const dfs = (items, parent) => {
    if (!Array.isArray(items)) {
      return;
    }

    for (const item of items) {
      cb(item, parent);
      dfs(item.children, item);
    }
  };

  dfs(treeData, null);
}

export function getAllIds(treeData, mapping) {
  const ids = [];

  // material tree view 要求 id 必须是 string
  forEachTree(treeData, (item) => ids.push(String(item[mapping.value])));

  return ids;
}

export function getMaterialDisplayLabel(labeledValue, options, mapping) {
  if (!labeledValue) {
    return '';
  }

  const { value: id, label } = labeledValue;

  if (label !== undefined && label !== null) {
    return label;
  }

  if (id === undefined || id === null) {
    return '';
  }

  const item = treeFindOption(options, id, mapping, true);

  return item ? item[mapping.label] : id;
}

export function idToGroupKeys(options, op, buildTreeBy) {
  const groupKeys = [get(op, buildTreeBy.parentPointee)];

  while (op._parent) {
    op = op._parent;
    groupKeys.unshift(get(op, buildTreeBy.parentPointee));
  }

  return groupKeys;
}
