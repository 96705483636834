import PropTypes from 'prop-types';
import SelectElement from '../../SelectElement';
import { CurrentDataProvider } from '../../../currentDataCtx';

function SelectCellEditor(props) {
  const { value, onValueChange, cellStartedEdit, data, context, componentProps } = props;
  return (
    <CurrentDataProvider value={data}>
      <SelectElement
        className="table-select-editor"
        suppressFormControl={true}
        value={value}
        onChange={onValueChange}
        componentProps={{
          size: context.tableSize,
          defaultOpen: cellStartedEdit,
          autoFocus: cellStartedEdit,
          ...componentProps,
        }}
      />
    </CurrentDataProvider>
  );
}

SelectCellEditor.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  ]),
  onValueChange: PropTypes.func,
  cellStartedEdit: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    minLength: PropTypes.number,
    minLengthErrorMessage: PropTypes.string,
    maxLength: PropTypes.number,
    maxLengthErrorMessage: PropTypes.string,
  }),
  componentProps: PropTypes.shape({}),
};

// 验证unique列时使用
SelectCellEditor.getUniqueKey = ({ colDef, value }) => {
  if (!value) return value;
  const mapping = colDef.cellEditorParams?.componentProps?.mapping;
  const valueKey = colDef.cellEditorParams?.componentProps?.useOriginValue
    ? mapping?.value || 'value'
    : 'value';
  if (Array.isArray(value)) {
    return JSON.stringify(
      value.map((item) => (typeof item === 'object' ? String(item[valueKey]) : String(item))),
    );
  }
  return typeof value === 'object' ? String(value[valueKey]) : String(value);
};

export default SelectCellEditor;
