/* eslint-disable react/prop-types */
import './FilterPanel.css';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { immutableDelete } from '@icp/utils';
import { useEventCallback } from '@icp/hooks';
import AddFilter from './AddFilter';
import { makeNewFilterModel } from './utils';
import FilterItem from './FilterItem';

const EMPTY_ARRAY = [];

function FilterPanel(props) {
  const {
    context,
    columnDefs,
    pinnedFilter = EMPTY_ARRAY,
    filterModel = EMPTY_ARRAY,
    onChange,
  } = props;

  const [newOne, setNewOne] = useState(null);

  useEffect(() => {
    setNewOne(null);
  }, [filterModel]);

  const handleAddFilter = (colId) => {
    const colDef = columnDefs.find((item) => item.colId === colId);
    const newFilterModel = filterModel.concat(makeNewFilterModel(colDef));
    setNewOne(colId);
    onChange(newFilterModel);
  };

  const handleDeleteFilter = (colId) => {
    const index = filterModel.findIndex((model) => model.colId === colId);
    const newFilterModel = immutableDelete(filterModel, [index]);
    onChange(newFilterModel);
  };

  const handleChangeFilter = useEventCallback((newModel) => {
    const index = filterModel.findIndex((model) => model.colId === newModel.colId);

    const newFilterModel = [...filterModel];

    // -1 意味着修改的是 pinnedFilter 的内容
    if (index === -1) {
      newFilterModel.push(newModel);
    } else {
      newFilterModel[index] = newModel;
    }

    onChange(newFilterModel);
  });

  return (
    <div className="icp-table-filter-panel">
      {Array.isArray(pinnedFilter) &&
        pinnedFilter.map((colId) => {
          const model = filterModel.find((fm) => fm.colId === colId);
          const colDef = columnDefs.find((item) => item.colId === colId);
          if (!model && !colDef) {
            return null;
          }
          return (
            <FilterItem
              key={colId}
              context={context}
              model={model || makeNewFilterModel(colDef)}
              columnDefs={columnDefs}
              deletable={false}
              onChange={handleChangeFilter}
            />
          );
        })}
      {filterModel
        .filter((model) => !Array.isArray(pinnedFilter) || !pinnedFilter.includes(model.colId))
        .map((model) => (
          <FilterItem
            key={model.colId}
            context={context}
            model={model}
            columnDefs={columnDefs}
            defaultOpen={newOne === model.colId}
            onChange={handleChangeFilter}
            onDelete={() => handleDeleteFilter(model.colId)}
          />
        ))}
      <AddFilter
        columnDefs={columnDefs}
        pinnedFilter={pinnedFilter}
        filterModel={filterModel}
        onChange={handleAddFilter}
      />
    </div>
  );
}

FilterPanel.propTypes = {
  context: PropTypes.shape({}),
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  pinnedFilter: PropTypes.arrayOf(PropTypes.string),
  filterModel: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

export default FilterPanel;
