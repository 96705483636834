import './cellRenderers.css';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withCurrentData from './withCurrentData';

function computeCircle(percent, context) {
  const r = context.tableSize === 'small' ? 20 : 24;
  const circumference = 2 * 3.14159 * 10.5;
  // strokeLinecap="round" 会让圆环的两端各自突出去一半的 strokeWidth，所以要 + 1.5 实现让圆环往回缩 1.5
  let circleOffset = ((100 - percent) / 100) * circumference + 1.5;

  // 最多不能超过圆周长 circumference，否则圆环回往回缩
  if (circleOffset > circumference) {
    circleOffset -= 1.5;
  }

  // circleOffset === 1.5 的时候说明已经 percent 是 100%，减 1.5 会有一点点的空白，0 就是完整的圆环
  if (circleOffset === 1.5) {
    circleOffset = 0;
  }
  return { r, circumference, circleOffset };
}

function ProgressCellRenderer(props) {
  const {
    value,
    progressStyle = 'style1',
    colorConfig,
    inHundred = false,
    defaultZero = false,
    context,
  } = props;

  const percent = ProgressCellRenderer.toPercentValue(value, { inHundred, defaultZero });

  if (percent === null) {
    return null;
  }

  const status = typeof value === 'object' && value ? value.status : 'default';
  const statusColor = colorConfig?.[status];

  const { r, circumference, circleOffset } = computeCircle(percent, context);

  return (
    <div
      className={clsx('table-progress-cell icp-center', progressStyle, status)}
      style={statusColor ? { '--status-color': statusColor } : null}
    >
      {progressStyle !== 'style4' ? (
        <div className="progress-bar">
          <div style={{ width: `${percent}%` }} />
        </div>
      ) : (
        <svg className="progress-bar" viewBox="0 0 24 24" width={r} height={r}>
          <circle cx="12" cy="12" r="10.5" strokeWidth={3} strokeLinecap="round" fill="none" />
          <circle
            cx="12"
            cy="12"
            r="10.5"
            strokeWidth={3}
            strokeLinecap="round"
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={circleOffset}
          />
        </svg>
      )}
      <span className="progress-text">{percent}%</span>
    </div>
  );
}

ProgressCellRenderer.toPercentValue = (value, cellRendererParams) => {
  const { inHundred, defaultZero } = cellRendererParams || {};

  let percent = typeof value === 'object' && value ? value.percent : value;
  if (!defaultZero && (percent === undefined || percent === null)) {
    return null;
  }

  if (typeof percent !== 'number') {
    percent = 0;
  }

  if (!inHundred) {
    // floor 一下，否则类似 0.58 * 100 会等于 57.99999999999999
    // 并且暂时 progress cell 暂时不提供显示小数点后的功能，如果后续有需求可以增加参数做相应的保留小数点的格式化
    percent = Math.floor(percent * 100);
  }
  return percent;
};

ProgressCellRenderer.toDisplayText = (value, cellRendererParams) => {
  const percent = ProgressCellRenderer.toPercentValue(value, cellRendererParams);
  return percent !== null ? `${percent}%` : '';
};

ProgressCellRenderer.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      percent: PropTypes.number,
      status: PropTypes.oneOf(['default', 'warning', 'success', 'error']),
    }),
  ]),
  progressStyle: PropTypes.oneOf(['style1', 'style2', 'style3', 'style4']),
  inHundred: PropTypes.bool,
  defaultZero: PropTypes.bool,
  colorConfig: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.string,
  }),
};

export default withCurrentData(ProgressCellRenderer);
