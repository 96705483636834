import { useState } from 'react';

function useDeferredPromise() {
  const [deferred, setDeferred] = useState(null);

  const createPromise = () => {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    const deferredObj = { promise, resolve, reject };
    setDeferred(deferredObj);
    return deferredObj;
  };

  return { deferred, createPromise };
}

export default useDeferredPromise;
