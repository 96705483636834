import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@mui/material';
import { composeEvent } from '@icp/utils';
import { ValueLanguageSelectMaterial } from '../../../ValueLanguageSelect';

const I18nTextareaMulti = forwardRef(function I18nTextareaMulti(props, ref) {
  const { id, value: valueProp, onChange, onBlur, changeDelayToBlur, readonly, ...other } = props;

  const { i18n } = useTranslation();

  const [valueLanguage, setValueLanguage] = useState(i18n.language);

  const valueI18nKey = `longText_i18n_${valueLanguage}`;

  const valueI18n = valueProp?.[valueI18nKey];

  const [value, setValue] = useState(valueI18n);

  useEffect(() => {
    setValue(valueI18n);
  }, [valueI18n]);

  const handleChange = (event) => {
    if (changeDelayToBlur) {
      setValue(event.target.value);
    } else {
      onChange(event.target.value, valueLanguage);
    }
  };

  const handleBlur = (event) => {
    if (changeDelayToBlur) {
      onChange(event.target.value, valueLanguage);
    }
  };

  return (
    <TextField
      {...other}
      multiline={true}
      fullWidth={true}
      value={value ?? ''}
      onChange={handleChange}
      onBlur={composeEvent(handleBlur, onBlur)}
      InputProps={{
        readOnly: readonly,
        startAdornment: (
          <InputAdornment position="start">
            <ValueLanguageSelectMaterial value={valueLanguage} onChange={setValueLanguage} />
          </InputAdornment>
        ),
      }}
      ref={ref}
    />
  );
});

I18nTextareaMulti.propTypes = {
  id: PropTypes.string,
  changeDelayToBlur: PropTypes.bool,
  value: PropTypes.shape({
    longText: PropTypes.string,
  }),
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default I18nTextareaMulti;
