import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { forwardRef } from 'react';
import {
  HELPER_TEXT_TYPES,
  helpersIsEmpty,
  I18N_VALUE_COLLAPSED,
  I18N_VALUE_FLAT,
} from '@icp/form-renderer-core';
import { Icon } from '@icp/components';
import InputSupportI18n from './InputSupportI18n';
import { useIsInDesign } from '../../../FormRenderCtx';
import { FormHelperTextMaterial } from '../../../FormHelperText';

const InputMaterial = forwardRef(function InputUIMaterial(props, ref) {
  const {
    id,
    title,
    value,
    disabled,
    readonly,
    allowI18nValue,
    componentProps = {},
    validation,
    status,
    helpers,
    onChange,
    onBlur,

    i18nInputLayout,
    onToggleI18nInputLayout,
    textInputType,
    allowClear,
    onClear,
  } = props;

  const isInDesign = useIsInDesign();

  return (
    <>
      <InputSupportI18n
        {...componentProps}
        fullWidth={true}
        id={id}
        allowI18nValue={allowI18nValue}
        i18nInputLayout={i18nInputLayout}
        label={title}
        value={value}
        error={status === 'error'}
        helperText={!helpersIsEmpty(helpers) ? <FormHelperTextMaterial helpers={helpers} /> : null}
        required={validation?.required}
        disabled={disabled}
        readonly={readonly}
        onChange={onChange}
        onBlur={onBlur}
        type={textInputType}
        allowClear={allowClear}
        onClear={onClear}
        ref={ref}
      />
      {!isInDesign && allowI18nValue ? (
        <IconButton
          className="i18n-input-layout-switch-button"
          size="small"
          onClick={onToggleI18nInputLayout}
        >
          {i18nInputLayout === I18N_VALUE_COLLAPSED ? (
            <Icon name="oct:chevron-right" size={16} />
          ) : (
            <Icon name="oct:chevron-down" size={16} />
          )}
        </IconButton>
      ) : null}
    </>
  );
});

InputMaterial.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  allowI18nValue: PropTypes.bool,
  componentProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.shape({}),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    maxLength: PropTypes.number,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  i18nInputLayout: PropTypes.oneOf([I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT]),
  onKeyDown: PropTypes.func,
  onToggleI18nInputLayout: PropTypes.func,
  textInputType: PropTypes.string,
  allowClear: PropTypes.bool,
  onClear: PropTypes.func,
};

export default InputMaterial;
