/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { Icon } from '@icp/components';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import SelectElement from '../../SelectElement';
import ACLInputMaterial from './ACLInputMaterial';

function toSelectComponentProps(componentProps) {
  const {
    idListUrl,
    columnDefs,
    columns,
    supportImport,
    supportExport,
    exportColumns,
    transformKey,
    transformUrl,
    maxSizePerReq,
    AgTableProps,
    clickUseCapture,
    ...other
  } = componentProps;

  if (idListUrl) {
    delete other.dataUrl;
    other.notFoundContent = null;
  }

  return other;
}

const ACLInputUI = forwardRef(function ACLInputUI(props, ref) {
  const {
    componentLibrary,
    narrowMode,
    size,
    unit,
    ACLElementOriginalProps,
    openButtonProps,
    onSearch,
    ...other
  } = props;

  const {
    keyPath,
    id,
    title,
    value,
    componentProps,
    fieldTitleProps,
    validation,
    disabled,
    readonly,
    status,
    // helpers,
    onChange,
    onTouchChanged,
  } = ACLElementOriginalProps;

  const { t } = useTranslation(['icp-components']);

  // TODO，着急上线，先不管 material
  if (componentLibrary === 'material-ui') {
    return (
      <ACLInputMaterial
        title={title}
        readonly={readonly}
        openButtonProps={openButtonProps}
        {...other}
      />
    );
  }

  // TODO，着急上线，临时直接调用一下 SelectElement，有空完善一下统一 ACLElement 和 SelectElement 的 props，两者应该基本一样
  return (
    <>
      <SelectElement
        keyPath={keyPath}
        suppressFormControl={true}
        id={id}
        title={componentLibrary === 'material-ui' ? title : null}
        value={value}
        fieldTitleProps={fieldTitleProps}
        validation={validation}
        disabled={disabled}
        readonly={readonly}
        status={status}
        // 直接用 ACLElement props 的 onChange，不走任何 ACLElement 本身的逻辑
        onChange={onChange}
        onTouchChanged={onTouchChanged}
        componentProps={{
          lazyLoading: true,
          showSearch: true,
          allowClear: true,
          ...toSelectComponentProps(componentProps),
          valueType: 'itemAlwaysArray',
          suffixIcon: (
            <>
              {value?.length && componentProps.multiple ? (
                <span style={{ marginRight: 4, fontSize: 'var(--font-size)' }}>
                  {t('acl.selected', {
                    length: value.length,
                    unit,
                  })}
                </span>
              ) : null}
              <Icon name="ant:down-outlined" size={12} />
            </>
          ),
          onSearch,
        }}
        ref={ref}
      />
      {componentLibrary !== 'material-ui' ? (
        <Button
          {...openButtonProps}
          size={size}
          className="acl-ant-open-button"
          icon={<Icon name="ACL-select" size={16} />}
          disabled={disabled}
        >
          {!narrowMode ? t('acl.selection') : null}
        </Button>
      ) : null}
    </>
  );

  /*

  return <ACLInputAnt {...other} ref={ref} />; */
});

ACLInputUI.propTypes = {
  componentLibrary: PropTypes.string,
  narrowMode: PropTypes.bool,
  title: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

export default ACLInputUI;
