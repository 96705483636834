import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { AG_SORT_TYPES, AgTable } from '@icp/components';
import { useTranslation } from 'react-i18next';
import { ArrowRightIcon } from '@primer/octicons-react';
import { shouldTranslateByDefault } from '@icp/settings';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { useClassName, useDataSource, useDisplayValue } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';

// eslint-disable-next-line react/prop-types
function ContentCellRenderer({ value }) {
  return (value || []).map((item) => {
    const { fieldName, beforeValue, afterValue } = item;
    return (
      <span key={fieldName} className="icp-audit-item">
        <span className="icp-audit-field-name">
          {fieldName}
          {': '}
        </span>
        {beforeValue}
        <ArrowRightIcon size={14} />
        {afterValue}
      </span>
    );
  });
}

function ContentTooltipComponent(props) {
  // eslint-disable-next-line react/prop-types
  const { value, location } = props;
  if (location !== 'cell') return <div className="icp-audit-content-tooltip">{value}</div>;
  if ((value || []).length === 0) return null;

  return (
    <div className="icp-audit-content-tooltip">
      {(value || []).map((item) => {
        const { fieldName, beforeValue, afterValue } = item;
        return (
          <div key={fieldName} className="icp-audit-item">
            <span className="icp-audit-field-name">
              {fieldName}
              {': '}
            </span>
            {beforeValue}
            <ArrowRightIcon size={14} />
            {afterValue}
          </div>
        );
      })}
    </div>
  );
}

const AuditElement = forwardRef(function AuditElement(props, ref) {
  const { keyPath, id, valueField, className: classNameProp, style, componentProps = {} } = props;

  const {
    formEntityDataId,
    dataUrl = `/form/api/form-entity-data-audit/list-group/${formEntityDataId}`,
    transformDataResponse,
    translateDataResponse = shouldTranslateByDefault(),
    sortModel,
    rowData: rowDataProp,
    style: componentStyle,
    defaultColDef: defaultColDefProp,
    ...otherComponentProps
  } = componentProps;

  const { t } = useTranslation(['icp-form-renderer']);
  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const gridRef = useRef();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const value = useDisplayValue(id, valueField) || rowDataProp;
  const { dataFetched } = useDataSource({
    skip: isInDesign || !!value,
    dataUrl,
    sortModel,
    transformDataResponse,
    translateDataResponse,
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        node: gridRef.current.node,
        get gridApi() {
          return gridRef.current.api;
        },
      };
    },
    [],
  );

  const columnDefs = useMemo(() => {
    return [
      {
        colId: 'username',
        field: 'username',
        headerName: t('audit.username'),
        flex: 1,
      },
      {
        colId: 'auditTime',
        field: 'auditTime',
        headerName: t('audit.auditTime'),
        flex: 1,
        valueFormatter: (params) => {
          return new Date(params.value).toLocaleString('zh');
        },
      },
      {
        colId: 'content',
        field: 'content',
        headerName: t('audit.content'),
        flex: 2,
        tooltipField: 'content',
        tooltipComponent: ContentTooltipComponent,
        cellRenderer: ContentCellRenderer,
      },
    ];
  }, [t]);

  const rowData = useMemo(() => {
    if (value || !dataFetched) {
      return null;
    }

    return dataFetched.map((group) => {
      return {
        username: group[0].username,
        auditTime: group[0].auditTime,
        content: group.map((item) => {
          const { fieldName, beforeValue, afterValue } = item;
          return { fieldName, beforeValue, afterValue };
        }),
      };
    });
  }, [value, dataFetched]);

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100,
      resizable: true,
      sortable: true,
      ...defaultColDefProp,
    };
  }, [defaultColDefProp]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <AgTable
        {...otherComponentProps}
        isInDesign={isInDesign}
        id={id}
        className={clsx('audit-element form-element', className, classNameComp, {
          'in-design': isInDesign,
        })}
        style={{ height: 300, ...style, ...componentStyle }}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={rowData}
        rowModelType="clientSide"
        pagination={false}
        suppressExcelExport={true}
        suppressToolbar={true}
        tooltipShowDelay={0}
        enableBrowserTooltips={false}
        ref={gridRef}
      />
    </ElementDecorator>
  );
});

AuditElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 当不提供 `rowData` 属性的时候，表格将会从此 formEntityDataId 发请求获取数据
     */
    formEntityDataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * 当不提供 `rowData` 属性的时候，表格将会从此 dataUrl 发请求获取数据
     * @default `/form/api/form-entity-data-audit/list-group/${formEntityDataId}`
     */
    dataUrl: PropTypes.string,
    /**
     * 转换从 `dataUrl` 获取到的数据，使用 eval 表达式，this 指向获取到的数据
     */
    transformDataResponse: PropTypes.string,
    /**
     * 是否需要翻译请求结果
     * @default false
     */
    translateDataResponse: PropTypes.bool,
    /**
     * 排序方式，前端排序。
     */
    sortModel: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string,
        sort: PropTypes.oneOf(AG_SORT_TYPES),
        sortType: PropTypes.oneOf(['text', 'number', 'date']),
      }),
    ),
    /**
     * 表格的数据
     */
    rowData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
AuditElement.displayName = 'Audit';

export default withFieldWrapper(AuditElement);
