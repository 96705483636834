import loadable from '@loadable/component';

export default loadable(() =>
  import(
    // eslint-disable-next-line import/no-unresolved
    '@icp/page-renderer'
  ).catch(() => ({
    default: function ErrBoundary() {
      console.error(`"@icp/page-renderer" not installed`);
      return null;
    },
  })),
);
