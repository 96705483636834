import { v4 as uuidv4 } from 'uuid';
import { differenceBy } from 'lodash-es';
import { restApi } from '@icp/settings';

export default class Heartbeat {
  // key: pbcToken#@#formEntityToken#@#formEntityDataId
  // value: { pbcToken, formEntityToken, formEntityDataId, lockRefCountMap }
  // 有 pbcToken, formEntityToken, formEntityDataId 的是主表 entry
  //   - 解锁：按这3个参数解锁，接口内部处理关联的锁。
  //   - 心跳：按这3个参数续锁，接口内部处理关联的锁。
  //   - lockRefCountMap: 不维护，新建时才维护。
  // 没 formEntityDataId 的是新建表单的 entry
  //   - 解锁：按 page-uuid 解锁，接口内部处理关联的锁。
  //   - 心跳：前端维护上锁解锁的引用计数表 lockRefCountMap，按 lockId 批量心跳续锁。
  //   - lockRefCountMap:
  //       key: pbcToken#@#formEntityToken#@#formEntityDataId
  //       value: { lockId, count }
  #entries = new Map();

  #timer;

  // 全局pageUUID无法区分新建表单时弹窗新建表单，因此改为每个form一个pageUUID
  #pageUUID = uuidv4();

  get pageUUID() {
    return this.#pageUUID;
  }

  get pageUUIDHeader() {
    return { 'x-page-uuid': this.pageUUID };
  }

  get pageUUIDRequestConfig() {
    return { headers: this.pageUUIDHeader };
  }

  // 表单提交过程中跳过心跳。提交时会解锁，立即心跳会报错。
  skip = false;

  constructor() {
    console.log(`[AutoLock] init heartbeat, page-uuid: ${this.#pageUUID}`);
    this.#timer = setInterval(() => this.#execute(), 10000);
    window.addEventListener('unload', this.#onPageUnload);
  }

  #onPageUnload = () => {
    for (const [, entry] of this.#entries) {
      const { pbcToken, formEntityToken, formEntityDataId } = entry;
      const url =
        formEntityDataId == null
          ? `/form/api/secure/form-entity-data-lock/unlock/form-entity-data/x-page-uuid/${this.#pageUUID}`
          : `/form/api/secure/v2/form-entity-data-lock/unlock/form-entity-data/${pbcToken}/${formEntityToken}/${formEntityDataId}`;
      navigator.sendBeacon(
        url,
        new Blob(
          [
            JSON.stringify({
              accessToken: restApi.auth.getAccessToken(),
              pageUuid: this.#pageUUID,
            }),
          ],
          { type: 'application/json;charset=UTF-8' },
        ),
      );
    }
  };

  #destroyOneTimeFlag = false;

  async destroy() {
    if (this.#destroyOneTimeFlag) return;
    this.#destroyOneTimeFlag = true;
    console.log(`[AutoLock] destroy heartbeat, entry size: ${this.#entries.size}`);
    clearInterval(this.#timer);
    const tasks = this.#entries.values().map((entry) => this.#unlockFormEntityData(entry));
    await Promise.allSettled(tasks);
    this.#entries.clear();
    window.removeEventListener('unload', this.#onPageUnload);
  }

  register({ pbcToken, formEntityToken, formEntityDataId }) {
    if (pbcToken == null || formEntityToken == null) {
      return;
    }
    const key = `${pbcToken}#@#${formEntityToken}#@#${formEntityDataId}`;
    if (this.#entries.has(key)) return;
    console.log(`[AutoLock] Heartbeat register: `, { pbcToken, formEntityToken, formEntityDataId });
    this.#entries.set(key, {
      pbcToken,
      formEntityToken,
      formEntityDataId,
      lockRefCountMap: new Map(),
    });
  }

  unregister({ pbcToken, formEntityToken, formEntityDataId }) {
    if (pbcToken == null || formEntityToken == null) {
      return;
    }
    const key = `${pbcToken}#@#${formEntityToken}#@#${formEntityDataId}`;
    if (!this.#entries.has(key)) return;
    console.log(`[AutoLock] Heartbeat unregister: `, {
      pbcToken,
      formEntityToken,
      formEntityDataId,
    });
    this.#entries.delete(key);
  }

  unregisterAll() {
    this.#entries.clear();
  }

  async #execute() {
    if (this.skip) return;
    const tasks = [];
    for (const [key, entry] of this.#entries) {
      const task = this.#heartbeat(key, entry);
      tasks.push(task);
    }
    await Promise.allSettled(tasks);
  }

  #countUp(params) {
    const {
      primaryDataPbcToken,
      primaryDataFormEntityToken,
      pbcToken,
      formEntityToken,
      formEntityDataId,
      lockId,
    } = params;
    const primaryKey = `${primaryDataPbcToken}#@#${primaryDataFormEntityToken}#@#${null}`;
    if (!this.#entries.has(primaryKey)) return;
    const lockRefCountMap = this.#entries.get(primaryKey).lockRefCountMap;
    const key = `${pbcToken}#@#${formEntityToken}#@#${formEntityDataId}`;
    if (!lockRefCountMap.has(key)) {
      lockRefCountMap.set(key, { lockId, count: 0 });
    }
    const o = lockRefCountMap.get(key);
    o.count += 1;
    console.log(`[AutoLock] Heartbeat countUp, LockId: ${lockId}, count: ${o.count}`, params);
    if (lockId !== o.lockId) {
      console.log(`[AutoLock] Heartbeat lockId changed: ${o.lockId} -> ${lockId}`, params);
      o.lockId = lockId;
    }
  }

  #countDown(params) {
    const {
      primaryDataPbcToken,
      primaryDataFormEntityToken,
      pbcToken,
      formEntityToken,
      formEntityDataId,
    } = params;
    const primaryKey = `${primaryDataPbcToken}#@#${primaryDataFormEntityToken}#@#${null}`;
    if (!this.#entries.has(primaryKey)) return;
    const lockRefCountMap = this.#entries.get(primaryKey).lockRefCountMap;
    const key = `${pbcToken}#@#${formEntityToken}#@#${formEntityDataId}`;
    if (!lockRefCountMap.has(key)) return;
    const o = lockRefCountMap.get(key);
    o.count -= 1;
    console.log(`[AutoLock] Heartbeat countDown, LockId: ${o.lockId}, count: ${o.count}`, params);
    if (o.count < 0) {
      // should not happen
      console.error(`[AutoLock] Heartbeat count < 0: ${o.count}`);
    }
  }

  // 如果心跳接口报错则不再继续发送
  #heartbeat(key, entry) {
    const { pbcToken, formEntityToken, formEntityDataId, lockRefCountMap } = entry;
    if (formEntityDataId == null) {
      const url = `/form/api/form-entity-data-lock/lock/form-entity-data/ids/heartbeat`;
      const ids = [...lockRefCountMap.values()].filter((x) => x.count > 0).map((x) => x.lockId);
      restApi.post(url, { ids }, this.pageUUIDRequestConfig).catch(() => {
        this.#entries.delete(key);
      });
    } else {
      const url = `/form/api/v2/form-entity-data-lock/lock/form-entity-data/${pbcToken}/${formEntityToken}/${formEntityDataId}/heartbeat`;
      restApi.post(url, {}, this.pageUUIDRequestConfig).catch(() => {
        this.#entries.delete(key);
      });
    }
  }

  async #unlockFormEntityData({ pbcToken, formEntityToken, formEntityDataId }) {
    console.log(`[AutoLock] Unlock: `, { pbcToken, formEntityToken, formEntityDataId });
    const url =
      formEntityDataId == null
        ? `/form/api/form-entity-data-lock/unlock/form-entity-data/x-page-uuid/${this.#pageUUID}`
        : `/form/api/v2/form-entity-data-lock/unlock/form-entity-data/${pbcToken}/${formEntityToken}/${formEntityDataId}`;
    // 静默解锁不报错
    await restApi
      .post(url, {}, { skipResponseInterceptors: true, ...this.pageUUIDRequestConfig })
      .catch(() => {});
  }

  async handleLockOnFieldValueChange({
    oldValue,
    newValue,
    primaryDataId,
    primaryDataPbcToken,
    primaryDataFormEntityToken,
    field,
  }) {
    if (!field.referencePbc || !field.referenceEntity || !field.referenceField) {
      return newValue;
    }

    // 没有primaryDataId是新建表单，产生字段锁时开始发心跳
    if (primaryDataId == null) {
      this.register({
        pbcToken: primaryDataPbcToken,
        formEntityToken: primaryDataFormEntityToken,
        formEntityDataId: null,
      });
    }

    if (
      ['ACL', 'SELECT'].includes(field.type) &&
      field.referencePbc &&
      field.referenceEntity &&
      field.referenceField
    ) {
      if ([...(oldValue || []), ...(newValue || [])].some((x) => x.id == null)) {
        console.log(`[Auto Lock] id is null`, oldValue, newValue);
        return newValue;
      }

      const deletedValues = differenceBy(oldValue || [], newValue || [], 'id');
      const addedValues = differenceBy(newValue || [], oldValue || [], 'id');

      const [unlockFails, lockFails] = await Promise.all([
        Promise.allSettled(
          deletedValues.map((x) =>
            restApi
              .post(
                `/form/api/v2/form-entity-data-lock/unlock/form-entity-data-for-field/${field.referencePbc}/${field.referenceEntity}/${x.id}`,
                {},
                this.pageUUIDRequestConfig,
              )
              .then(() => {
                this.#countDown({
                  primaryDataPbcToken,
                  primaryDataFormEntityToken,
                  pbcToken: field.referencePbc,
                  formEntityToken: field.referenceEntity,
                  formEntityDataId: x.id,
                });
              })
              .catch(() => Promise.reject(x)),
          ),
        ),
        Promise.allSettled(
          addedValues.map((x) =>
            restApi
              .post(
                `/form/api/v2/form-entity-data-lock/lock/form-entity-data/${field.referencePbc}/${field.referenceEntity}/${x.id}`,
                {},
                {
                  params: {
                    primaryDataId,
                    primaryDataPbcToken,
                    primaryDataFormEntityToken,
                  },
                  ...this.pageUUIDRequestConfig,
                },
              )
              .then((lockObj) => {
                this.#countUp({
                  primaryDataPbcToken,
                  primaryDataFormEntityToken,
                  pbcToken: field.referencePbc,
                  formEntityToken: field.referenceEntity,
                  formEntityDataId: x.id,
                  lockId: lockObj.id,
                });
              })
              .catch(() => Promise.reject(x)),
          ),
        ),
      ]).then(([task1Results, task2Results]) => {
        return [
          task1Results.filter((x) => x.status === 'rejected').map((x) => x.reason),
          task2Results.filter((x) => x.status === 'rejected').map((x) => x.reason),
        ];
      });

      const lockFailsIds = new Set(lockFails.map((x) => x.id));

      // 反选的解锁失败则仍然选中
      // 选中的上锁失败则改为未选中
      const finalValue = [
        ...(newValue || []).filter((x) => !lockFailsIds.has(x.id)),
        ...unlockFails,
      ];

      return finalValue;
    }

    if (field.type === 'TEXT_BOX') {
      // 先上锁新值再解锁旧值, 空值不上锁不解锁

      if (newValue) {
        let formEntityDataId = newValue;
        if (field.referenceField !== 'id') {
          formEntityDataId = await this.#convertUniqueFieldToId({
            pbcToken: field.referencePbc,
            formEntityToken: field.referenceEntity,
            fieldToken: field.referenceField,
            value: newValue,
          });
        }
        // 转换失败或不存在则跳过
        if (formEntityDataId == null) {
          console.log(`[Auto Lock] Failed to convert unique field to id`, field, newValue);
          return newValue;
        }
        try {
          const lockObj = await restApi.post(
            `/form/api/v2/form-entity-data-lock/lock/form-entity-data/${field.referencePbc}/${field.referenceEntity}/${formEntityDataId}`,
            {},
            {
              params: {
                primaryDataId,
                primaryDataPbcToken,
                primaryDataFormEntityToken,
              },
              ...this.pageUUIDRequestConfig,
            },
          );
          this.#countUp({
            primaryDataPbcToken,
            primaryDataFormEntityToken,
            pbcToken: field.referencePbc,
            formEntityToken: field.referenceEntity,
            formEntityDataId,
            lockId: lockObj.id,
          });
        } catch {
          console.log(`[Auto Lock] Failed to lock formEntityDataId: ${formEntityDataId}`);
          return oldValue;
        }
      }

      if (oldValue) {
        let formEntityDataId = oldValue;
        if (field.referenceField !== 'id') {
          formEntityDataId = await this.#convertUniqueFieldToId({
            pbcToken: field.referencePbc,
            formEntityToken: field.referenceEntity,
            fieldToken: field.referenceField,
            value: oldValue,
          });
        }
        // 转换失败或不存在则跳过
        if (formEntityDataId == null) {
          console.log(`[Auto Lock] Failed to convert unique field to id`, field, oldValue);
          return newValue;
        }
        try {
          await restApi.post(
            `/form/api/v2/form-entity-data-lock/unlock/form-entity-data-for-field/${field.referencePbc}/${field.referenceEntity}/${formEntityDataId}`,
            {},
            this.pageUUIDRequestConfig,
          );
          this.#countDown({
            primaryDataPbcToken,
            primaryDataFormEntityToken,
            pbcToken: field.referencePbc,
            formEntityToken: field.referenceEntity,
            formEntityDataId,
          });
        } catch {
          console.log(`[Auto Lock] Failed to unlock formEntityDataId: ${formEntityDataId}`);
        }
      }
      return newValue;
    }

    console.log(`[Auto Lock] Unknown value type:`, field.type);
    return newValue;
  }

  #convertUniqueFieldToId({ pbcToken, formEntityToken, fieldToken, value }) {
    if (!value) return null;
    return restApi
      .get(
        `/form/api/form-entity-data/${pbcToken}/${formEntityToken}/${fieldToken}/find-data-id-by-unique-field`,
        { params: { value }, ...this.pageUUIDRequestConfig },
      )
      .then((x) => {
        if (x === '') return null;
        return x;
      })
      .catch(() => null);
  }
}
