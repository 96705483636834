import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { AG_FILTER_TYPES_UNARY } from '@icp/components-core';
import Icon from '../../Icon';
import Popover from '../../Popover';
import FilterItemCondition from './FilterItemCondition';
import FilterItemValue from './FilterItemValue';
import useFilterItemDisplayText from './useFilterItemDisplayText';
import { filterHasValue } from './utils';

function FilterItem(props) {
  const {
    context,
    defaultOpen = false,
    columnDefs,
    model: modelProp,
    deletable = true,
    onChange,
    onDelete,
  } = props;

  const [model, setModel] = useState(modelProp);
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  useEffect(() => {
    // effect 过后 anchorEl.current 才有值
    if (defaultOpen) {
      setOpen(true);
    }
    // 不响应 defaultOpen 的变化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModel(modelProp);
  }, [modelProp]);

  const colDef = columnDefs.find((col) => col.colId === model.colId);

  // set filter 的 values 可能是异步的，hooks 一下
  const displayText = useFilterItemDisplayText(context, model, colDef);

  if (!colDef) {
    return null;
  }

  const handleChange = (k, v) => {
    const newModel = { ...model, [k]: v };
    // TODO，根据 filterType 和 type 返回合法的 model，移除不必要的属性
    if (k === 'type' && model.filterType === 'set') {
      delete newModel.values;
      delete newModel.filter;
      delete newModel.filterTo;
    }
    setModel(newModel);
    if (filterHasValue(newModel)) {
      onChange(newModel);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (!filterHasValue(model)) {
      const newModel = { colId: model.colId, filterType: model.filterType, type: model.type };
      setModel(newModel);
      onChange(newModel);
    }
  };

  return (
    <>
      <button
        className={clsx('icp-table-filter-item icp-clickable icp-center', {
          'has-value': filterHasValue(model),
        })}
        ref={anchorEl}
        onClick={() => setOpen(true)}
      >
        {displayText}
        <Icon name="oct:chevron-down" size={12} />
      </button>
      <Popover
        className="icp-table-filter-item-detail"
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleClose}
      >
        <FilterItemCondition
          model={model}
          deletable={deletable}
          onChange={(v) => handleChange('type', v)}
          onDelete={onDelete}
        />
        {!AG_FILTER_TYPES_UNARY.includes(model.type) ? (
          <FilterItemValue
            key={model.type}
            context={context}
            colDef={colDef}
            model={model}
            onChange={handleChange}
          />
        ) : null}
      </Popover>
    </>
  );
}

FilterItem.propTypes = {
  context: PropTypes.shape({}),
  defaultOpen: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  model: PropTypes.shape({
    colId: PropTypes.string,
    filterType: PropTypes.string,
    type: PropTypes.string,
  }),
  deletable: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default FilterItem;
