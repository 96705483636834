const allPbcCode = {};

// code after code generator
const tokenRegexp = /^[a-zA-Z][a-zA-Z0-9-]+$/;
if (!process.env.SAAS_MODE) {
  (function importS() {
    const r = require.context('@site/src/pbcCode', true, /\.js$/);
    r.keys()
      // filter outer duplicate key caused by webpack.config.js resolve.context
      .filter((key) => key.startsWith('./'))
      .forEach((key) => {
        const content = r(key).default;
        const paths = key.split('/');
        const [, pbcToken] = paths;
        const filename = paths[paths.length - 1];

        if (!allPbcCode[pbcToken]) {
          allPbcCode[pbcToken] = {};
        }
        if (!allPbcCode[pbcToken].forms) {
          allPbcCode[pbcToken].forms = {};
        }
        if (!allPbcCode[pbcToken].pages) {
          allPbcCode[pbcToken].pages = {};
        }

        // pbc form entities layouts
        if (
          // 在 3.0.21 及之后的版本 cg 过后的表单 js 路径是 pbcToken/forms/formEntityToken/layoutToken.js
          (paths[2] === 'forms' && paths.length === 5) ||
          // 兼容可能的老项目（不兼容可能也没啥影响，cg 的代码本身就是自动生成的，有问题重跑一边 cg 就好了）
          // 兼容，在 3.0.20 及之前版本 cg 过后的表单 js 路径是 pbcToken/forms/formEntityToken/layouts/layoutToken.js
          (paths[4] === 'layouts' && paths[2] === 'forms' && paths.length === 6)
        ) {
          const formEntityToken = paths[3];
          const layoutToken = filename.substring(0, filename.length - '.js'.length);
          if (!tokenRegexp.test(layoutToken)) {
            throw new Error(`form layout 名称 ${key} 不匹配正则：${tokenRegexp}`);
          }
          if (!allPbcCode[pbcToken].forms[formEntityToken]) {
            allPbcCode[pbcToken].forms[formEntityToken] = {};
          }
          allPbcCode[pbcToken].forms[formEntityToken][layoutToken] = content;
        }

        // pbc pages
        if (paths[2] === 'pages' && paths.length === 4) {
          const schemaId = filename.substring(0, filename.length - '.js'.length);
          if (!tokenRegexp.test(schemaId)) {
            throw new Error(`page 名称 ${key} 不匹配正则：${tokenRegexp}`);
          }
          allPbcCode[pbcToken].pages[schemaId] = content;
        }
      });
  })();
}

export default allPbcCode;
