import { immutableSet as iSet } from '@icp/utils';

/* export function removePrevContext(contextPrev) {
  // 移除被 PageRenderer 下各个组件放进去的决定 schema 的上下文
  const {
    isMainFormRenderer,
    formEntityToken,
    layoutToken,
    layoutId,
    schemaId,
    formEntityId,
    formEntityDataId,
    approvalResult,
    ...other
  } = contextPrev;
  return other;
} */

export function getDialogWidth(size, width) {
  if (width) {
    return width;
  }

  switch (size) {
    case 'xs':
      return 416; // and design confirm dialog width
    case 'sm':
      return 520; // and design modal default width
    case 'md':
      return 900;
    case 'lg':
      return 1200;
    case 'fullscreen':
      return '100%';
    default:
      return undefined;
  }
}

export function legacySupportForOpenAction(action) {
  let newAction = { ...action };

  // open rename 成了 dialog，并且 deprecated 了 suppressCurrentDataAsDefaultData
  newAction.type = 'dialog';

  if (newAction.suppressCurrentDataAsDefaultData) {
    // 默认 dialog 已经不传递 currentData 到打开的 dialog
    delete newAction.suppressCurrentDataAsDefaultData;
  } else if (!newAction.openFormProps?.FormRendererProps?.defaultData) {
    // 如果没有配置 suppressCurrentDataAsDefaultData，则需要兼容老代码，使用 currentData 作为 defaultData 传给 打开的 dialog
    // 而在代码实现里面，以前 openFormProps?.FormRendererProps?.defaultData 也是优先使用的会覆盖
    // 没有设置 suppressCurrentDataAsDefaultData 这个参数继承来的 defaultData，所以如果已经配置了
    // openFormProps?.FormRendererProps?.defaultData 的话则不需要做处理。
    newAction = iSet(
      newAction,
      ['openFormProps', 'FormRendererProps', 'defaultData'],
      ':currentData',
    );
    // 老的 button 的 open action 打开的 PageRenderer 会默认使用 defaultData.id 作为 formEntityDataId
    newAction.legacyOpenUseDefaultDataId = true;
  }

  return newAction;
}
