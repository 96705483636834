import { immutableSet, removeUndefined, resolveVariablePattern } from '@icp/utils';
import { resolveInitialValues, resolveUrl, selectValues } from '@icp/form-renderer-core';

export const emptyOpenPageRendererProps = {
  // PageRenderer 会默认从路由 params 去取如下参数，打开 Dialog 的时候应该是另一个上下文不应该从路由里取，这里设置 null 实现禁止从路由取
  schemaId: null,
  formEntityToken: null,
  formEntityLayoutToken: null,
  formEntityId: null,
  formEntityLayoutId: null,
  flowToken: null,
  flowDefinitionId: null,
  flowInstanceId: null,
  threadId: null,
  formEntityDataId: null,
};

export function resolveOpenFormProps({
  openFormProps,
  currentData,
  params,
  store,
  response,
  context,
}) {
  const resolveConfigUrl = (url) => {
    return resolveUrl({ url, store, context, currentData, params, response })[0];
  };

  const resolveConfigVar = (pattern, res = response, failWhenNoVariableValue = false) => {
    const formData = selectValues(store.getState());
    return resolveVariablePattern({
      pattern,
      context,
      currentData: currentData || formData,
      formData,
      params,
      response: res,
      failWhenNoVariableValue,
    });
  };

  let openFormPropsResolved = {
    ...emptyOpenPageRendererProps,
    ...removeUndefined({ ...openFormProps }),
  };

  openFormPropsResolved = {
    ...openFormPropsResolved,
    retrieveUrl: resolveConfigUrl(openFormPropsResolved.retrieveUrl),
    createUrl: resolveConfigUrl(openFormPropsResolved.createUrl),
    updateUrl: resolveConfigUrl(openFormPropsResolved.updateUrl),
    formEntityDataId: resolveConfigVar(openFormPropsResolved.formEntityDataId),
    flowInstanceId: resolveConfigVar(openFormPropsResolved.flowInstanceId),
    threadId: resolveConfigVar(openFormPropsResolved.threadId),
  };

  if (openFormPropsResolved.FormRendererProps?.defaultData) {
    const resolvedDefaultData = resolveInitialValues({
      defaultValues: openFormPropsResolved.FormRendererProps.defaultData,
      currentData,
      formData: selectValues(store.getState()),
      context,
      params,
    });
    openFormPropsResolved = immutableSet(
      openFormPropsResolved,
      ['FormRendererProps', 'defaultData'],
      resolvedDefaultData,
    );
  }

  return openFormPropsResolved;
}
