import PropTypes from 'prop-types';
import { useRef } from 'react';
import { CurrentDataProvider } from '../../../currentDataCtx';
import TextareaElement from '../../TextareaElement';

function TextareaCellEditor(props) {
  const {
    value,
    onValueChange,
    cellStartedEdit,
    data,
    context,
    rows = 4,
    cols = 50,
    validation,
    componentProps = {},
  } = props;

  const { simpleMode, ...otherComponentProps } = componentProps;

  const refInput = useRef(null);

  return (
    <CurrentDataProvider value={data}>
      <TextareaElement
        className="table-textarea-editor"
        suppressFormControl={true}
        value={value}
        onChange={onValueChange}
        componentProps={{
          ...otherComponentProps,
          simpleMode,
          rows,
          cols,
          size: context.tableSize,
          maxLength: validation?.maxLength,
          autoFocus: cellStartedEdit,
          changeDelayToBlur: false,
        }}
        ref={refInput}
      />
    </CurrentDataProvider>
  );
}

TextareaCellEditor.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      longText: PropTypes.string,
    }),
  ]),
  onValueChange: PropTypes.func,
  cellStartedEdit: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  rows: PropTypes.number,
  cols: PropTypes.number,
  validation: PropTypes.shape({
    required: PropTypes.bool,
    maxLength: PropTypes.number,
  }),
  componentProps: PropTypes.shape({
    simpleMode: PropTypes.bool,
  }),
};

// 验证unique列时使用
TextareaCellEditor.getUniqueKey = ({ value }) => {
  return JSON.stringify(value);
};

export default TextareaCellEditor;
