import { get } from 'lodash-es';
import {
  immutableDelete,
  immutableInsert,
  immutableMove,
  immutableSet,
  immutableUnset,
  isIndex,
} from '@icp/utils';
import {
  findFieldBy,
  forEachFieldInSchema,
  isField,
  isInputField,
  isLayout,
  schemaFieldToDataField,
} from '@icp/form-schema';
import { extractAllIds, extractDataFieldsFromSchema, getUniqueId } from './formEntityHelper';
import { FIELD_TYPE_COLLAPSE_ITEM, FIELD_TYPE_TAB } from '../../constant';
import { makeNewField } from './makeNew';

/**
 * Append a new field to last of schema.
 * @param schema
 * @param keyPath
 * @param newField
 * @return {{newKeyPath: *, newSchema: *}}
 */
export function appendFieldOperation(schema, keyPath, newField) {
  // PageHeader 特殊些，append 的时候 keyPath 已经是以 fields 结尾的了
  const fieldKeyPath = keyPath[keyPath.length - 1] === 'fields' ? keyPath.slice(0, -1) : keyPath;

  const field = !fieldKeyPath.length ? schema : get(schema, fieldKeyPath);
  const newKeyPath = fieldKeyPath.slice();

  if (field?.component === 'Page') {
    newKeyPath.push('componentProps', 'emptyFields');
    newKeyPath.push(field.componentProps?.emptyFields?.length || 0);
  } else {
    // 往 TableElement 的 toolbarFields 里 append 字段结构不会嵌套一层 fields
    if (newKeyPath[newKeyPath.length - 1] !== 'toolbarFields') {
      newKeyPath.push('fields');
    }
    newKeyPath.push(field?.fields?.length || 0);
  }

  const newSchema = immutableInsert(schema, newKeyPath, newField);

  return { newSchema, newKeyPath };
}

/**
 * Insert a new field to before or after an existing field.
 * @param schema
 * @param keyPath
 * @param newField
 * @param isBefore
 * @return {{newKeyPath, newSchema: *}}
 */
export function insertFieldOperation(schema, keyPath, newField, isBefore) {
  if (!isBefore) {
    keyPath[keyPath.length - 1] = +keyPath[keyPath.length - 1] + 1;
  }

  const newSchema = immutableInsert(schema, keyPath, newField);

  return { newSchema, newKeyPath: keyPath };
}

/**
 * Move an existing field to before or after of another existing field.
 * @param schema
 * @param source
 * @param target
 * @returns {*}
 */
export function moveFieldOperation(schema, source, target) {
  const sourceField = get(schema, source.keyPath);
  const targetField = get(schema, target.keyPath);

  if (!isField(sourceField)) {
    return { newSchema: schema, newKeyPath: source.keyPath };
  }

  // no targetKeyPath means move to top of schema
  if (!target.keyPath.length) {
    const newSchema = deleteFieldOperation(schema, source.keyPath);
    return appendFieldOperation(newSchema, [], sourceField);
  }

  // 后续会删掉这个 id，所以 mutable 的修改也没事。
  const uuid = getUniqueId();
  const uuidKey = Symbol('uuid');
  targetField[uuidKey] = uuid;

  // 首先从 schema 中删除 sourceField
  const newSchema = deleteFieldOperation(schema, source.keyPath);

  // 删除过后 targetKeyPath 就发生了变化，所以需要根据 id 去查找新的 keyPath。
  // eslint-disable-next-line prefer-const
  let { field: newTargetField, keyPath: newTargetKeyPath } = findFieldBy(newSchema, (field) => {
    return field[uuidKey] === uuid;
  });
  delete newTargetField[uuidKey];

  // handle drag to Collapse / Tabs items
  if ([FIELD_TYPE_COLLAPSE_ITEM, FIELD_TYPE_TAB].includes(target.type)) {
    newTargetKeyPath = newTargetKeyPath.concat('componentProps', 'items', target.itemIndex);
  }

  if (target.operation === 'append') {
    return appendFieldOperation(newSchema, newTargetKeyPath, sourceField);
  }

  if (target.operation === 'insert') {
    return insertFieldOperation(newSchema, newTargetKeyPath, sourceField, target.isBefore);
  }

  // param invalid, do nothing.
  return { newSchema: schema, newKeyPath: source.keyPath };
}

/**
 * Delete an existing field from schema.
 * @param schema
 * @param keyPath
 * @returns {*}
 */
export function deleteFieldOperation(schema, keyPath) {
  if (!keyPath) {
    return schema;
  }

  if (!isIndex(keyPath[keyPath.length - 1])) {
    return immutableUnset(schema, keyPath);
  }
  return immutableDelete(schema, keyPath);
}

export function removeSelfOperation(schema, keyPath) {
  if (!keyPath) {
    return schema;
  }

  const field = get(schema, keyPath);

  let newSchema = immutableDelete(schema, keyPath);
  newSchema = immutableInsert(newSchema, keyPath, ...field.fields);

  return newSchema;
}

function regenerateNewIds(entityTokens, pasteType, schema, keyPath, sourceField, pbcToken) {
  const addedDataFields = [];

  // 如果是 replace，表示 keyPath 下的所有字段会被删除
  // 如果 keyPath.length === 0 表示 form 本身
  const excluded = keyPath.length ? immutableDelete(schema, keyPath) : {};
  const existedFields = extractDataFieldsFromSchema(pasteType !== 'replace' ? schema : excluded);
  const existedTokens = new Set(existedFields.map((field) => field.token));
  // 这里 allIds 是包含了 existedTokens 的，不用排除简单处理就好，严格来说例如一个 Table 和一个 Input 的 id 也不应该相同。
  const allIds = extractAllIds(schema);

  forEachFieldInSchema({ fields: [sourceField] }, (fieldInfo) => {
    if (isInputField(fieldInfo)) {
      // 我们原则上不会存在同一个 schema 多个组件相同 id 的情况，
      // 如果 id 已经在当前 schema，则重新生成一个随机 id，否则保持不变就行（可能是从其他 layout 或者其他浏览器 Tab copy 过来）
      // 更新需求：目前 field id 不再使用随机生成id，因此若发生重复，则跟新建时一样使用空字符串。
      if (fieldInfo.id && existedTokens.has(fieldInfo.id)) {
        fieldInfo.id = '';
      }
      // 不管上一步有没有生成新的 id，fieldInfo.id 都有可能不存在与当前 formEntity.fields，因为 paste 可能
      // 跨浏览器 Tab
      if (!entityTokens.has(fieldInfo.id)) {
        addedDataFields.push(schemaFieldToDataField(fieldInfo, pbcToken));
      }
    } else if (allIds.has(fieldInfo.id)) {
      fieldInfo.id = getUniqueId();
    }
  });

  return addedDataFields;
}

/**
 * Paste copied field to before, after, replace or as a child of an existing field.
 * @param formEntity
 * @param schema
 * @param keyPath
 * @param copiedField
 * @param pasteType
 * @return {{newKeyPath: *[], addedDataFields: *[], newSchema}}
 */
export function pasteOperation(formEntity, schema, keyPath, copiedField, pasteType) {
  const isPastToForm = keyPath.length === 0;
  const isPasteFormToForm = isPastToForm && copiedField.form;
  const newKeyPath = [...keyPath];
  const targetField = get(schema, keyPath);
  const entityTokens = new Set(formEntity.fields.map((field) => field.token));

  // 后面会修改 id，copy 一下先
  const sourceField = JSON.parse(JSON.stringify(copiedField));
  let addedDataFields = [];

  const regenerateIDs = () => {
    addedDataFields = regenerateNewIds(
      entityTokens,
      pasteType,
      schema,
      keyPath,
      sourceField,
      formEntity.pbcToken,
    );
  };

  let newSchema = schema;
  if (pasteType === 'before') {
    if (isField(sourceField)) {
      regenerateIDs();
      newSchema = immutableInsert(schema, newKeyPath, sourceField);
    }
  } else if (pasteType === 'after') {
    if (isField(sourceField)) {
      newKeyPath[keyPath.length - 1] += 1;
      regenerateIDs();
      newSchema = immutableInsert(schema, newKeyPath, sourceField);
    }
  } else if (pasteType === 'replace') {
    if (isPasteFormToForm) {
      regenerateIDs();
      newSchema = sourceField;
    } else {
      regenerateIDs();
      newSchema = immutableSet(schema, newKeyPath, sourceField);
    }
  } else if (pasteType === 'child') {
    // 只有 source isField 的组件才能被粘贴到 child
    // 只有 target 是 form 本身和 layout 组件才能被粘贴。
    if (isField(sourceField) && (isLayout(targetField) || isPastToForm)) {
      if (isLayout(targetField)) {
        newKeyPath.push('fields', targetField.fields?.length || 0);
      } else if (isPastToForm) {
        newKeyPath.push('fields', schema.fields?.length || 0);
      }
      regenerateIDs();
      newSchema = immutableInsert(schema, newKeyPath, sourceField);
    }
  } else if (pasteType === 'properties') {
    // 只有 source 和 target 是一样的组件才能互相粘贴 properties
    if (isPasteFormToForm) {
      // form 粘贴属性
      newSchema = immutableSet(schema, ['form'], sourceField.form);
    } else if (sourceField.component === targetField.component) {
      // 组件粘贴属性
      const { id, title, component, fields, items, componentProps, ...other } = sourceField;
      // Button Text 的 content, img 的 src 等应该是特殊属性，不应该被粘贴
      const { content, src, ...otherComponentProps } = componentProps || {};

      const mergedField = { ...targetField, ...other };

      if (componentProps || targetField.componentProps) {
        mergedField.componentProps = { ...targetField.componentProps, ...otherComponentProps };
      }

      newSchema = immutableSet(schema, newKeyPath, mergedField);
    }
  }

  return { newSchema, newKeyPath, addedDataFields };
}

/**
 * Embed field into another field's children.
 * @param schema
 * @param keyPath
 * @param name
 * @param style
 * @return {*}
 */
export function embedFieldInOperation(schema, keyPath, name, style) {
  const field = get(schema, keyPath);

  const newField = makeNewField(name /* 一定是个容器组件因此不需要输入型组件的配置 */);
  if (style) {
    newField.style = style;
  }
  newField.fields = [field];

  const schemaDeleted = deleteFieldOperation(schema, keyPath);
  const { newSchema } = insertFieldOperation(schemaDeleted, keyPath, newField, true);

  return newSchema;
}

export function moveBeforeOrAfterOperation(schema, keyPath, index, isBefore) {
  const sourceKeyPath = keyPath.concat(index);
  const targetKeyPath = keyPath.concat(isBefore ? index - 1 : index + 1);
  const obj = get(schema, sourceKeyPath);

  let newSchema = immutableDelete(schema, sourceKeyPath);
  newSchema = immutableInsert(newSchema, targetKeyPath, obj);

  return { newSchema };
}

export function moveOrderOperation(schema, itemsKeyPath, fromIndex, toIndex) {
  const items = get(schema, itemsKeyPath);
  const newItems = immutableMove(items, fromIndex, toIndex);
  return { newSchema: immutableSet(schema, itemsKeyPath, newItems) };
}

/**
 * Update a property of an existing field.
 * @param schema
 * @param fieldKeyPath
 * @param propertyPath
 * @param newValue
 * @returns {Object|*}
 */
export function updateFieldOperation(schema, fieldKeyPath, propertyPath, newValue) {
  if (!Array.isArray(fieldKeyPath)) {
    return schema;
  }

  // 设置 undefined 会让 obj 多出一个属性，判断 dirty 用了 lodash 在 isEqual 会判断两个 obj 不等。
  // 其实 json stringify 过后 undefined 会消失，应该判断相等。
  if (newValue === undefined) {
    return immutableUnset(schema, fieldKeyPath.concat(propertyPath));
  }
  return immutableSet(schema, fieldKeyPath.concat(propertyPath), newValue);
}

// Insert to top data fields
export function insertNewFieldToFields(formEntity, newLayoutField, dbFieldSource) {
  const dataField = schemaFieldToDataField(newLayoutField, formEntity.pbcToken);
  dataField.source = dbFieldSource;
  const newFields = formEntity.fields.concat(dataField);
  return { ...formEntity, fields: newFields };
}
