function getArgs(event) {
  const { type, payload } = event;

  if (type === 'fieldValueChange') {
    return [payload.keyPath, payload.value];
  }

  return [payload];
}

export function extractPropsEvents(propsEvents, props, eventTypes) {
  for (const key of Object.keys(props)) {
    if (key.startsWith('on')) {
      const eventType = key
        .slice(3)
        .padStart(key.length - 2, String.fromCharCode(key[2].charCodeAt(0) + 32));
      if (eventTypes.has(eventType)) {
        propsEvents[eventType] = props[key];
        delete props[key];
      }
    }
  }
}

export default class EventService {
  constructor(propsEvents, eventTypes) {
    this.eventTypes = eventTypes;
    this.propsEvents = propsEvents;
    this.listeners = new Map();
  }

  on(eventType, listener) {
    if (!this.eventTypes.has(eventType)) {
      console.warn('Unknown event type: ', eventType);
      return;
    }

    if (!this.listeners.has(eventType)) {
      this.listeners.set(eventType, new Set());
    }

    this.listeners.get(eventType).add(listener);
  }

  off(eventType, listener) {
    this.listeners.get(eventType)?.delete(listener);
  }

  dispatch(event) {
    const { type } = event;

    const propsEvent = this.propsEvents[type];

    if (typeof propsEvent === 'function') {
      propsEvent(...getArgs(event));
    }

    for (const callback of this.listeners.get(type) || new Set()) {
      callback(...getArgs(event));
    }
  }
}
