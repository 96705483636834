import PropTypes from 'prop-types';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { FormHelperTextMaterial } from '../../FormHelperText';
import ACL from './ACL';

function ACLMaterial(props) {
  const {
    id,
    title,
    value,
    disabled,
    readonly,
    validation,
    status,
    helpers,
    componentProps = {},
    onChange,

    componentLibrary,
    idListUrl,
    dataUrl,
    isStandardDataSource,
    dataResponseKeyPath,
    translateDataResponse,
    outerFilterModel,
    dataExclusion,
    selectColId,
    unit,
    mapping,
    columnDefs,
    multiple,
    placeholder,
    supportImport,
    supportExport,
    stringEqual,
    exportColumns,
    transformKey,
    transformUrl,
    maxSizePerReq,
    AgTableProps,
    ACLElementOriginalProps,
  } = props;

  return (
    <ACL
      {...componentProps}
      componentLibrary={componentLibrary}
      id={id}
      value={value}
      mapping={mapping}
      status={status}
      idListUrl={idListUrl}
      dataUrl={dataUrl}
      isStandardDataSource={isStandardDataSource}
      dataResponseKeyPath={dataResponseKeyPath}
      translateDataResponse={translateDataResponse}
      title={title}
      unit={unit}
      placeholder={placeholder}
      multiple={multiple}
      stringEqual={stringEqual}
      columnDefs={columnDefs}
      outerFilterModel={outerFilterModel}
      dataExclusion={dataExclusion}
      selectColId={selectColId}
      supportImport={supportImport}
      supportExport={supportExport}
      exportColumns={exportColumns}
      transformKey={transformKey}
      transformUrl={transformUrl}
      maxSizePerReq={maxSizePerReq}
      onChange={onChange}
      style={readonly ? { display: 'none' } : componentProps.style}
      disabled={disabled}
      AgTableProps={AgTableProps}
      ACLElementOriginalProps={ACLElementOriginalProps}
      readonly={readonly}
      required={validation?.required}
      helperText={!helpersIsEmpty(helpers) ? <FormHelperTextMaterial helpers={helpers} /> : null}
    />
  );
}

ACLMaterial.propTypes = {
  componentLibrary: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  componentProps: PropTypes.shape({
    style: PropTypes.shape({}),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  unit: PropTypes.string,
  mapping: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // Other key map to save
  }),
  idListUrl: PropTypes.string,
  dataUrl: PropTypes.string,
  isStandardDataSource: PropTypes.bool,
  dataResponseKeyPath: PropTypes.string,
  translateDataResponse: PropTypes.bool,
  outerFilterModel: PropTypes.arrayOf(PropTypes.shape({})),
  dataExclusion: PropTypes.arrayOf(PropTypes.string),
  selectColId: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  supportImport: PropTypes.bool,
  maxSizePerReq: PropTypes.number,
  supportExport: PropTypes.bool,
  exportColumns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  transformKey: PropTypes.string,
  transformUrl: PropTypes.string,
  stringEqual: PropTypes.bool,
  AgTableProps: PropTypes.shape({}),
  ACLElementOriginalProps: PropTypes.shape({}),
  onChange: PropTypes.func,
};

export default ACLMaterial;
