import { useEffect, useState } from 'react';
import { useFormApi } from '../../FormRenderCtx';

// 监听 fieldConfig.parentFieldToken 表格的选中行 id
export default function useParentTableSelectedId(props) {
  const { fieldConfig } = props;

  const formApi = useFormApi();

  const [parentSelectedId, setParentSelectedId] = useState(null);

  useEffect(() => {
    formApi.on('ready', () => {
      // 只绑定一次，暂时不支持两个表格互相有显示隐藏这种情况
      if (fieldConfig?.parentFieldToken) {
        const parentTableApi = formApi.getFieldApi(fieldConfig.parentFieldToken);
        if (parentTableApi?.gridApi) {
          parentTableApi.gridApi.addEventListener('selectionChanged', (event) => {
            const selectedId = event.api.getSelectedRows()[0]?.id;
            setParentSelectedId(selectedId ?? null);
          });
        }
      }
    })
    // fieldConfig 不应该发生变化，不响应
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return parentSelectedId;
}
