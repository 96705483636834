import { restApi, shouldTranslateByDefault } from '@icp/settings';
import { get } from 'lodash-es';
import { EvalWorker } from '@icp/utils';
import { translateEntireObj } from '@icp/i18n';
import { mergeSearchTextToFilterModel } from '@icp/components-core';
import { isMatchableConfigLike, isPredicateConfigLike } from './conditionalUtils';

export function dataSourceToDataUrl(dataSource, context, isAggr = false, isInDesign = false) {
  if (!dataSource) {
    return null;
  }

  if (dataSource.listUrl) {
    return dataSource.listUrl;
  }

  const pbcToken = dataSource.pbcToken || context.pbcToken;
  const formEntityToken = dataSource.token;

  if (!pbcToken || !formEntityToken) {
    console.error('Cannot transfer datasource to dataUrl:', dataSource);
    return null;
  }

  if (isAggr) {
    if (isInDesign) {
      return `/form/api/v2/form-entity-data/${context.projectToken}/${pbcToken}/${formEntityToken}/queryPipeline`;
    }
    return `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/queryPipeline`;
  }
  return `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/list`;
}

export function dataSourceToUrlGroup(dataSource, context) {
  if (!dataSource) {
    return {};
  }

  const pbcToken = dataSource.pbcToken || context.pbcToken;
  const formEntityToken = dataSource.token;

  let listIdsUrl = dataSource.listIdsUrl;
  let listByIdsUrl = dataSource.listByIdsUrl;
  let listUrl = dataSource.listUrl;

  if (!listIdsUrl) {
    listIdsUrl = `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/ids`;
  }

  if (!listByIdsUrl) {
    listByIdsUrl = `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/list-by-ids`;
  }

  if (!listUrl) {
    listUrl = `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/list`;
  }

  return { listIdsUrl, listByIdsUrl, listUrl };
}

export function legacyMultiDataUrlConfigSupport(config) {
  const {
    multiDataSource,
    multiDataSourceFlat,
    dataSource,
    dataUrl,
    dataFilters,
    dataResponseKeyPath,
    defaultValues,
    sortModel,
    transformDataResponse,
    translateDataResponse,
  } = config;

  // 当 dataUrl 配置是个数组，并且不是 ConditionalProperty 配置的时候，表示是老数据配置，在 dataUrl 里指定多个
  // url 来实现多数据源的配置。针对此做 legacy support，转换成新的配置 multiDataSource。
  if (
    !multiDataSource &&
    Array.isArray(dataUrl) &&
    (!isMatchableConfigLike(dataUrl[0]) || !isPredicateConfigLike(dataUrl[0]))
  ) {
    return {
      multiDataSource: dataUrl.map((url, index) => {
        return {
          dataSource,
          dataFilters,
          sortModel,
          transformDataResponse,
          translateDataResponse,
          dataUrl: url,
          dataResponseKeyPath: Array.isArray(dataResponseKeyPath)
            ? dataResponseKeyPath[index]
            : dataResponseKeyPath,
          defaultValue: Array.isArray(defaultValues) ? defaultValues[index] : defaultValues,
        };
      }),
      multiDataSourceFlat: true,
    };
  }

  return { multiDataSource, multiDataSourceFlat };
}

function composeDefaultValue(data, defaultValue) {
  if (Array.isArray(data)) {
    return data.map((item) => ({ ...defaultValue, ...item }));
  }
  if (typeof data === 'object' && data !== null) {
    return { ...defaultValue, ...data };
  }
  return data;
}

function sortData(data, sortModelProp) {
  const sortModel = toApiSortModel(sortModelProp);

  if (!sortModel) {
    return data;
  }

  const { id, sort, type } = sortModel[0];

  return data.sort((a, b) => {
    let lhs = a[id];
    let rhs = b[id];
    if (sort === 'desc') {
      [rhs, lhs] = [lhs, rhs];
    }

    if (type === 'number') {
      return lhs - rhs;
    }
    if (type === 'date') {
      return new Date(lhs) - new Date(rhs);
    }
    return `${lhs}`.localeCompare(`${rhs}`);
  });
}

function toApiSortModel(sortModel) {
  // 兼容旧的 sortModel 配置 { id, type, sort }
  if (typeof sortModel !== 'object' && sortModel && sortModel.id) {
    return [
      {
        colId: sortModel.id,
        sortType: sortModel.type,
        sort: sortModel.sort,
      },
    ];
  }

  // 数组是标准 ag-query sortMode 格式，不做多余验证
  if (Array.isArray(sortModel)) {
    return sortModel;
  }

  return undefined;
}

function removeEmpty(obj) {
  const newObj = { ...obj };

  for (const [k, v] of Object.entries(newObj)) {
    if (v === null || v === undefined || v === '' || (Array.isArray(v) && v.length === 0)) {
      delete newObj[k];
    }
  }

  return newObj;
}

function request({
  signal,
  skipResponseInterceptors,
  dataUrl,

  startRow,
  endRow,
  groupKeys,
  rowGroupCols,
  valueCols,
  searchText,
  searchTextColIds,
  filterModel,
  sortModel,

  httpMethod,
  httpBody, // httpBody 默认行为是覆盖自动生成 body
  selectColId,
  needCount,

  aggregation,
  filterModelAfterAggregation,

  queryParams, // queryParams 是和已有的 request params 做合并
}) {
  httpMethod = httpMethod?.toLowerCase();

  if (searchText && searchTextColIds?.length) {
    // 使用 filterModel 来实现指定 searchText 搜索哪些列的功能
    filterModel = mergeSearchTextToFilterModel(searchText, searchTextColIds, filterModel);
    searchText = undefined;
  }

  const payload = !aggregation
    ? removeEmpty({
        startRow,
        endRow,
        groupKeys,
        rowGroupCols,
        valueCols,
        searchText,
        filterModel,
        sortModel,
        selectColId,
        needCount,
      })
    : {
        phases: [
          {
            filterModel,
          },
          aggregation,
          {
            filterModel: filterModelAfterAggregation || [],
          },
        ],
      };

  if (httpMethod === 'get') {
    return restApi[httpMethod](dataUrl, {
      signal,
      skipResponseInterceptors,
      params: { ...queryParams, payload: JSON.stringify(payload) },
    });
  }

  return restApi[httpMethod](dataUrl, httpBody || payload, {
    signal,
    skipResponseInterceptors,
    params: queryParams,
  });
}

export async function fetchSingleDataSource({
  signal,
  skipResponseInterceptors,
  context,
  formData,

  // search
  searchText,
  searchTextColIds,

  // lazy control
  startRow,
  endRow,

  // tree structure
  groupKeys,

  // group structure
  rowGroupCols,
  valueCols,

  // single data source config
  dataUrl,
  dataResponseKeyPath,
  filterModel,
  sortModel,
  transformDataResponse,
  translateDataResponse = shouldTranslateByDefault(),
  defaultValue,
  httpMethod = 'get',
  httpBody,
  selectColId,
  needCount,

  // aggregation config
  aggregation,
  filterModelAfterAggregation,

  // 传额外的 request query params
  queryParams,
}) {
  let data = await request({
    signal,
    skipResponseInterceptors,
    dataUrl,

    startRow,
    endRow,
    groupKeys,
    rowGroupCols,
    valueCols,
    searchText,
    searchTextColIds,
    filterModel,
    sortModel,

    httpMethod,
    httpBody,
    selectColId,
    needCount,

    aggregation,
    filterModelAfterAggregation,

    queryParams,
  });

  if (dataResponseKeyPath) {
    // 给 Table 做动态识别是否是数组使用
    if (typeof dataResponseKeyPath === 'function') {
      data = dataResponseKeyPath(data);
    } else {
      data = get(data, dataResponseKeyPath);
    }
  }

  if (defaultValue) {
    data = composeDefaultValue(data, defaultValue);
  }

  // 没有 startRow 和 endRow 不 lazy loading 的情况使用前端排序
  // 2025-3-10 号之前是 sortModel request 函数没有通过请求发出去，纯前端排序的。
  // 2025-3-10 号之后改成了判断有没有 startRow 和 endRow 来决定是否是 Select 组件的懒加载，懒加载必须发给后端排序。
  //           所以判断 needClientSort 在 request 函数讲 sortModel 发了出去。懒加载是后做的，所以不对老功能造成影响。
  // 2025-3-21 号改 Table 和 ACL 也改成调用这个方法了，ACL fetchAllIds 需要永远把 sortModel 发送给后端。
  //           为了不造成 regression，同时不清楚为什么 3-10 号之前是全前端排序的了。所以这里保留一份前端排序的逻辑。
  // TODO，后续应该增加参数来决定是否需要前端排序
  const needClientSort = sortModel && !startRow && !endRow;
  if (needClientSort) {
    data = sortData(data, sortModel);
  }

  if (transformDataResponse) {
    data = await EvalWorker.shared().execEval(data, transformDataResponse, {
      params: {
        context,
        formData,
      },
    });
  }

  if (translateDataResponse) {
    translateEntireObj(data);
  }

  return data;
}

export async function fetchMultiDataSource({
  signal,
  skipResponseInterceptors,
  context,
  formData,
  multiDataSource,
  multiDataSourceFlat,
  dataResponseKeyPath,
  sortModel,
  transformDataResponse,
}) {
  let dataArray = await Promise.all(
    multiDataSource.map((config) =>
      fetchSingleDataSource({
        skipResponseInterceptors,
        // 如果 multiDataDataSource 里没有配置 dataResponseKeyPath 的话，使用外层单数据源配置的 dataResponseKeyPath。
        // 所以一般在组件的 componentProps 里写明的默认 dataResponseKeyPath 表示所有单数据源和多数据源的默认 dataResponseKeyPath。
        dataResponseKeyPath,
        ...config,
        formData,
        signal,
      }),
    ),
  );

  if (multiDataSourceFlat) {
    dataArray = dataArray.flat();
  }

  // multi data source 只能前端排序
  if (sortModel) {
    dataArray = sortData(dataArray, sortModel);
  }

  if (transformDataResponse) {
    dataArray = await EvalWorker.shared().execEval(dataArray, transformDataResponse, {
      params: {
        context,
        formData,
      },
    });
  }

  return dataArray;
}
