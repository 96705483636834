// 按 permission 的 pbc 和 target分组

const SEPARATOR = '_##::##_';

export function buildPermissionCategoryKey(permission) {
  return `${permission.pbcToken}${SEPARATOR}${permission.target}`;
}

export function categorizePermissions(permissions) {
  const categoryMap = new Map();

  for (const perm of permissions) {
    const key = buildPermissionCategoryKey(perm);
    if (!categoryMap.has(key)) categoryMap.set(key, []);
    categoryMap.get(key).push(perm);
  }

  return Array.from(categoryMap, ([key, permList]) => {
    const [pbcToken, target] = key.split(SEPARATOR);
    return {
      key,
      pbcToken,
      categoryName: target,
      sortNumber: permList[0].targetSortNumber,
      permissions: permList.sort(
        (l, r) => l.sortNumber - r.sortNumber || l.name.localeCompare(r.name, navigator.language),
      ),
    };
  }).sort(
    (l, r) =>
      l.sortNumber - r.sortNumber ||
      l.categoryName.localeCompare(r.categoryName, navigator.language),
  );
}
