import { CROSS_LAYOUT_PROPERTIES_MAPPING } from '@icp/form-schema';
import { set } from 'lodash-es';
import { getColumnType, getFilterProps } from '../TableSettings/utils';
import { findSchemaFieldInEntityByToken } from '../../../store';

function getComponentProps(field, schemaField) {
  const { componentProps } = schemaField;

  switch (field.type) {
    // radio 和 checkbox 有 direction 这类对 SelectCellEditor 无用的属性
    case 'RADIO':
    case 'CHECKBOX':
      return {
        options: componentProps?.options,
      };
    case 'TEXT_BOX':
    case 'TEXTAREA':
    case 'NUMBER':
    case 'DATE':
    case 'TIME':
    case 'SELECT':
    case 'ACL':
    case 'UPLOAD':
      return componentProps;
    default:
      return undefined;
  }
}

function getValidation(field) {
  const validation = {};

  const validationMapping = CROSS_LAYOUT_PROPERTIES_MAPPING.FIELDS_TO_LAYOUTS.filter((item) => {
    return Array.isArray(item[1]) && item[1][0] === 'validation';
  });

  for (const [keyPathInField, keyPathInLayout] of validationMapping) {
    if (field[keyPathInField]) {
      set(validation, keyPathInLayout.slice(1), field[keyPathInField]);
    }
  }

  return validation;
}

export function getColDef({ field, dataSourceFormEntity }) {
  if (!dataSourceFormEntity) {
    return {
      colId: field.token,
      field: field.token,
      headerName: field.name,
      headerName_i18n_key: field.nameI18nKey ?? undefined,
      headerName_i18n_ns: field.nameI18nNs ?? undefined,
      editable: true,
      type: getColumnType(field),
      cellEditorParams: {
        validation: getValidation(field),
      },
    };
  }

  // 从第一个 layout 里取 field，虽然多个 layout 里的 componentProps 可能会不同，但是也只能这么处理
  const schemaField = findSchemaFieldInEntityByToken(dataSourceFormEntity, field.token);
  const filterProps = getFilterProps(field, schemaField);

  return {
    type: getColumnType(field),
    colId: field.token,
    field: field.token,
    headerName: field.name,
    headerName_i18n_key: field.nameI18nKey ?? undefined,
    headerName_i18n_ns: field.nameI18nNs ?? undefined,
    editable: true,
    cellEditorParams: {
      validation: schemaField.validation,
      valueExpression: schemaField.valueExpression ?? undefined,
      componentProps: getComponentProps(field, schemaField),
    },
    ...filterProps,
  };
}

export function dataSourceToColumnDefs({ fields, dataSourceFormEntity }) {
  return fields
    .filter((field) => field.token !== 'parentDataId')
    .map((field) => getColDef({ field, dataSourceFormEntity }));
}
