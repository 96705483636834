import { saveAs } from 'file-saver';
import imageExtensions from './staticized/image-extensions.json';

const imageExtensionSet = new Set(imageExtensions);

export function saveAsJson(data, fileName) {
  const string = JSON.stringify(data, null, 2);
  const blob = new Blob([string], { type: 'application/json;charset=utf-8' });
  saveAs(blob, `${fileName || 'untitled'}.json`);
}

export function readFileToJson(file) {
  return readFile(file).then((fileContent) => {
    try {
      const data = JSON.parse(fileContent);
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  });
}

export function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
      resolve(fileContent);
    };
    reader.readAsText(file);
  });
}

export function readFileAsDataURL(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
      resolve(fileContent);
    };
    reader.readAsDataURL(file);
  });
}

export function chooseFile({ accept, multiple } = {}) {
  return new Promise((resolve, reject) => {
    const inputEl = document.createElement('input');
    inputEl.setAttribute('type', 'file');
    if (accept) {
      inputEl.setAttribute('accept', accept);
    }
    if (multiple) {
      inputEl.setAttribute('multiple', true);
    }
    inputEl.oncancel = () => {
      document.body.removeChild(inputEl);
      reject(Error('cancel'));
    };
    inputEl.onchange = (event) => {
      document.body.removeChild(inputEl);
      if (!event.target.files?.length) {
        reject(Error('cancel'));
        return;
      }
      if (multiple) {
        resolve(Array.from(event.target.files));
      } else {
        resolve(event.target.files[0]);
      }
    };
    inputEl.style.display = 'none';
    document.body.appendChild(inputEl);
    inputEl.click();
  });
}

export function chooseJsonFile() {
  return chooseFile({ accept: 'application/json' }).then(readFileToJson);
}

export function fileIsImage(filename) {
  if (!filename) return false;
  return imageExtensionSet.has(filename.split('.').pop().toLowerCase());
}

export function browserDownloadFile(url, filename) {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || (url || '').split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
