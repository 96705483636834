import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { composeEvent } from '@icp/utils';
import { ValueLanguageSelectMaterial } from '../../../ValueLanguageSelect';

function I18nTextareaSingle(props) {
  const {
    id,
    value: valueProp,
    languageOp,
    onChange,
    onBlur,
    changeDelayToBlur,
    readonly,
    ...other
  } = props;

  const valueI18nKey = `longText_i18n_${languageOp.value}`;

  const valueI18n = valueProp?.[valueI18nKey];

  const [value, setValue] = useState(valueI18n);

  useEffect(() => {
    setValue(valueI18n);
  }, [valueI18n]);

  const handleChange = (event) => {
    if (changeDelayToBlur) {
      setValue(event.target.value);
    } else {
      onChange(event.target.value, languageOp.value);
    }
  };

  const handleBlur = (event) => {
    if (changeDelayToBlur) {
      onChange(event.target.value, languageOp.value);
    }
  };

  return (
    <TextField
      {...other}
      multiline={true}
      fullWidth={true}
      value={value ?? ''}
      onChange={handleChange}
      onBlur={composeEvent(handleBlur, onBlur)}
      InputProps={{
        readOnly: readonly,
        startAdornment: (
          <InputAdornment position="start">
            <ValueLanguageSelectMaterial value={languageOp.value} readonly={true} />
          </InputAdornment>
        ),
      }}
    />
  );
}

I18nTextareaSingle.propTypes = {
  id: PropTypes.string,
  changeDelayToBlur: PropTypes.bool,
  value: PropTypes.shape({
    longText: PropTypes.string,
  }),
  languageOp: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default I18nTextareaSingle;
