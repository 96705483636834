import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useAppContext } from '@icp/app';
import { selectContext } from '@icp/form-renderer-core';
import { useParams } from 'react-router-dom';
import { randomNumber } from '@icp/utils';
import { Resizable } from '@icp/components';
import { Empty } from 'antd';
import { useElementDecorator, useFormApi, useIsInDesign } from '../../FormRenderCtx';
import { useClassName } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import PageRenderer from './asyncPageRenderer';
import { CurrentDataProvider, useCurrentData } from '../../currentDataCtx';
import { useSelector, useStore } from '../../store';
import { resolveOpenFormProps } from './utils';
import RecursionRenderer from '../../RecursionRenderer/RecursionRenderer';
import useBindEditableTable from './useBindEditableTable';
import { makeSettingKey } from '../TableElement/utils';
import useEventService from '../../hooks/useEventService';

export const JS_API_EVENT_TYPES = new Set(['formApiReady']);

const PageElement = forwardRef(function PageElement(props, ref) {
  const { keyPath, id, className: classNameProp, style, componentProps = {} } = props;
  const {
    openFormProps: openFormPropsProp,
    style: compStyle,
    ResizableProps,
    empty = false,
    emptyFields,
    emptyChildren,
    bindComponentId,
    defaultState = 'empty',
    disableSaveResizeSetting = false,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();
  const params = useParams();
  const store = useStore();
  const context = useSelector(selectContext);
  const { contextFromAppProp } = useAppContext();
  const currentData = useCurrentData();
  const formApi = useFormApi();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const [state, setState] = useState(defaultState); // hide, empty, content

  const stateRef = useRef(state);
  stateRef.current = state;
  const nodeRef = useRef(null);
  const openFormRef = useRef(null);
  const eventService = useEventService({ otherComponentProps, JS_API_EVENT_TYPES });

  const pageApi = useMemo(() => {
    return {
      get node() {
        return nodeRef.current;
      },
      get formApi() {
        return openFormRef.current;
      },
      get state() {
        return stateRef.current;
      },
      on: (type, listener) => {
        eventService.current.on(type, listener);
      },
      hide: () => {
        setState('hide');
      },
      renderEmpty: () => {
        setState('empty');
      },
      renderContent: () => {
        setState('content');
      },
    };
  }, [eventService]);

  useImperativeHandle(ref, () => pageApi, [pageApi]);
  useBindEditableTable({ pageApi, bindComponentId });

  // 不响应 id 的变化
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idToRegister = useMemo(() => id || `random-table-id-${randomNumber(100000)}`, []);
  useEffect(() => {
    formApi.asyncComponentManager.register(idToRegister);
    if (state !== 'content') {
      formApi.asyncComponentManager.setReady(idToRegister);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openFormProps = useMemo(() => {
    return resolveOpenFormProps({
      openFormProps: openFormPropsProp,
      currentData,
      params,
      store,
      context,
    });
  }, [context, currentData, openFormPropsProp, params, store]);

  const contextToDialog = useMemo(() => {
    return { ...contextFromAppProp, ...openFormProps.context, isInPageRendererElement: true };
  }, [contextFromAppProp, openFormProps.context]);

  const settingKey = disableSaveResizeSetting ? null : makeSettingKey(id, context, 'page-size');

  const handleSubFormApiReady = () => {
    formApi.asyncComponentManager.setReady(idToRegister);
    eventService.current.dispatch({ type: 'formApiReady' });
  };

  if (state === 'hide') {
    return null;
  }

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <Resizable
        min={240}
        placement="left"
        isPercent={false}
        showControlIcon={true}
        showHoverEffect={true}
        settingKey={settingKey}
        {...ResizableProps}
      >
        <div
          className={clsx('page-renderer-element form-element', className, classNameComp)}
          style={{ ...style, ...compStyle }}
          ref={nodeRef}
        >
          {state === 'empty' && (empty || emptyChildren || emptyFields)
            ? emptyChildren ||
              (emptyFields?.length && (
                <RecursionRenderer
                  keyPath={(keyPath || []).concat(['componentProps', 'emptyFields'])}
                  fields={emptyFields}
                />
              )) || <Empty {...empty} />
            : null}
          {state === 'content' && !isInDesign ? (
            <CurrentDataProvider value={undefined}>
              <PageRenderer
                // openFormProps 里如果没有特殊制定默认打开页面是当前 pbc
                pbcToken={context.pbcToken}
                {...openFormProps}
                FormRendererProps={{
                  disableDirty: true,
                  ...openFormProps?.FormRendererProps,
                }}
                context={contextToDialog}
                ref={(r) => {
                  if (r && r.on) {
                    r.on('firstRendered', handleSubFormApiReady);
                    openFormRef.current = r;
                  } else {
                    openFormRef.current = null;
                  }
                }}
              />
            </CurrentDataProvider>
          ) : null}
        </div>
      </Resizable>
    </ElementDecorator>
  );
});

PageElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 拖拽改变尺寸组件 Resizable 的属性
     */
    ResizableProps: PropTypes.shape({}),
    /**
     * 当无数据的时候显示的占位符，antd 的 Empty 的属性
     * @default false
     */
    empty: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        // `Empty` props, see https://ant.design/components/empty-cn/#API
      }),
    ]),
    /**
     * 自定义 empty 状态显示的内容（json）
     */
    emptyFields: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * 自定义 empty 状态显示的内容（jsx）
     */
    emptyChildren: PropTypes.node,
    /**
     * 绑定操作到相应组件
     */
    bindComponentId: PropTypes.string,
    /**
     * 组件初次加载的默认状态，'hide' 表示不渲染任何内容；'empty' 表示渲染空白状态占位符; 'content' 表示渲染内部表单内容
     * @default empty
     */
    defaultState: PropTypes.oneOf(['hide', 'empty', 'content']),
    /**
     * 禁止保存拖拽改变过后的尺寸
     * @default false
     */
    disableSaveResizeSetting: PropTypes.bool,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
PageElement.displayName = 'PageElement';

export default withFieldWrapper(PageElement);
