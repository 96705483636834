import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, get } from 'lodash-es';
import { fetchSingleDataSource, selectValues } from '@icp/form-renderer-core';
import { isCancel } from '@icp/settings';
import useDataUrl from './useDataUrl';
import useDataFilters from './useDataFilters';
import { useStore } from '../store';

export default function useDataSourceLazy(props) {
  const {
    skip: skipProp,
    buildTreeBy,
    searchText,
    searchTextColIds,
    debounceLeading = true,
    cacheBlockSize = 30,
    // single data source config
    dataSource,
    dataUrl: dataUrlProp,
    dataFilters,
    sortModel,
    dataResponseKeyPath,
    transformDataResponse,
    translateDataResponse,
    debounceTime = 200,
    defaultValue,
    httpMethod,
    httpBody,
    selectColId,
    needCount,
    // aggregation config，目前只有 echart 单数据源支持
    aggregation,
    dataFilterAfterAggregation,
  } = props;

  const store = useStore();

  const dataUrl = useDataUrl({ dataUrlProp, dataSource });
  const filterModel = useDataFilters(dataFilters);
  const filterModelAfterAggregation = useDataFilters(dataFilterAfterAggregation);

  const skip = skipProp || !dataUrl;

  const [dataFetched, setDataFetched] = useState(null);
  const [freshLoading, setFreshLoading] = useState(!skip);
  const [moreLoading, setMoreLoading] = useState(false);
  const [error, setError] = useState(null);

  const allDataIsFetched = useRef(false);
  const hasMore = useRef(!skip);
  const dataLength = useRef(0);

  // dependency 太多了，cg 过后很多属性都是直接写的对象表达式在 jsx components 里，很容易造成死循环，stringify 过后的才是真的变化
  // transformDataResponse 这种属性就不响应变化了没什么意义
  const deps = JSON.stringify({
    buildTreeBy,
    searchText,
    searchTextColIds,
    cacheBlockSize,
    dataUrl,
    dataResponseKeyPath,
    filterModel,
    sortModel,
    defaultValue,
    httpMethod,
    httpBody,
    selectColId,
    needCount,
    aggregation,
    filterModelAfterAggregation,
  });

  const fetchDataSource = useCallback(
    (signal, flag, groupKeys) => {
      if (flag === 'more') {
        setMoreLoading(true);
      } else if (flag === 'fresh') {
        setError(null);
        setFreshLoading(true);
      }

      return fetchSingleDataSource({
        signal,
        // 不能在外面 selectContext，alias 会 dispatch 去修改 context
        context: store.getState().context,
        formData: selectValues(store.getState()),
        searchText,
        searchTextColIds,

        // lazy control
        startRow:
          cacheBlockSize !== Infinity ? (flag === 'more' ? dataLength.current : 0) + 0 : undefined,
        endRow:
          cacheBlockSize !== Infinity
            ? (flag === 'more' ? dataLength.current : 0) + cacheBlockSize
            : undefined,
        groupKeys,

        // single data source config
        dataUrl,
        dataResponseKeyPath,
        filterModel,
        sortModel,
        transformDataResponse,
        translateDataResponse,
        defaultValue,
        httpMethod,
        httpBody,
        selectColId,
        needCount,

        // aggregation config
        aggregation,
        filterModelAfterAggregation,
      })
        .then((data) => {
          setDataFetched((exists) => {
            let newData;
            if (flag === 'fresh') {
              newData = data;
            } else if (flag === 'more') {
              newData = (exists || []).concat(data);
            } else if (flag === 'children') {
              newData = [...exists];
              let parent;
              let parents = exists;
              groupKeys.forEach((key) => {
                parent = (parents || []).find((item) => {
                  // TreeSelect 的 stringEqual 可能会把 parentPointee 转成 string，这里简单 string 比较了再判断 stringEqual 就 corner case
                  return String(get(item, buildTreeBy.parentPointee)) === String(key);
                });
                parents = parent?.children;
              });
              if (parent) {
                parent.children = data;
              }
            }
            dataLength.current = newData.length;
            return newData;
          });

          // 有个小问题是当总数刚好能整除的时候会多发一个无意义的请求
          hasMore.current = data.length && dataLength.current % cacheBlockSize === 0;
          allDataIsFetched.current = !searchText && !hasMore.current;

          setError(null);
          setMoreLoading(false);
          setFreshLoading(false);
          return data;
        })
        .catch((err) => {
          if (!isCancel(err)) {
            setDataFetched(null);
            setError(err);
            setMoreLoading(false);
            setFreshLoading(false);
          }
          return Promise.reject(err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deps],
  );

  // 要支持 leading，单独提一个 memo 的 debounce 函数
  const prevDebounceRequest = useRef(null);
  const debounceRequest = useMemo(() => {
    prevDebounceRequest.current?.cancel();
    const fn = debounce((cb) => cb(), debounceTime, { leading: debounceLeading });
    prevDebounceRequest.current = fn;
    return fn;
  }, [debounceTime, debounceLeading]);

  // 全新请求
  useEffect(() => {
    if (skip) {
      setDataFetched(null);
      setError(null);
      setFreshLoading(false);
      setMoreLoading(false);
      return () => {};
    }

    const controller = new AbortController();
    const { signal } = controller;

    debounceRequest(() => {
      fetchDataSource(signal, 'fresh');
    });

    return () => {
      controller.abort();
    };
  }, [skip, fetchDataSource, debounceRequest, setDataFetched]);

  // 滚动向下加载
  const loadMore = (params) => {
    if (!hasMore || freshLoading || moreLoading) {
      return;
    }

    debounceRequest(() => {
      fetchDataSource(params?.signal, 'more');
    });
  };

  // 展开树状节点
  const loadChildren = ({ signal, groupKeys }) => {
    return fetchDataSource(signal, 'children', groupKeys);
  };

  return {
    freshLoading,
    moreLoading,
    error,
    dataFetched,
    hasMore: hasMore.current,
    allDataIsFetched: allDataIsFetched.current,
    loadMore,
    loadChildren,
  };
}
