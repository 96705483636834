import {
  getFormEntityByToken,
  handleLockOnFieldValueChange,
  selectInitialValues,
  selectEnableAutoLock,
  selectFormEntity,
} from '@icp/form-renderer-core';

const AUTO_LOCK_EVENT_SOURCE = 'autoLock';

export async function processAutoLock(event, fieldConfig, store) {
  if (event.source === AUTO_LOCK_EVENT_SOURCE) {
    return;
  }

  const oldValue = event.oldValue;
  const newValue = event.value;
  if (oldValue === newValue) return;

  /*

    判断可编辑表格里某一列是否需要上锁
    Layout启用自动锁 + 列对应的子表字段启用自动锁

  */

  // layout未启用自动锁定，跳过
  if (!selectEnableAutoLock(store.getState())) {
    return;
  }

  const subFormEntity = await store
    .dispatch(
      getFormEntityByToken({
        pbcToken: fieldConfig.pbcToken,
        formEntityToken: fieldConfig.formEntityToken,
      }),
    )
    .unwrap();

  const thisColumnFieldToken = event.column.colId;
  const thisColumnField = subFormEntity.fields?.find((f) => f.token === thisColumnFieldToken);

  // 列对应的子表字段未启用自动锁，跳过
  if (!thisColumnField?.isAutoLock) {
    return;
  }

  const formEntityDataId = selectInitialValues(store.getState())?.id;
  const formEntity = selectFormEntity(store.getState());

  const finalValue = await store
    .dispatch(
      handleLockOnFieldValueChange({
        oldValue,
        newValue,
        primaryDataId: formEntityDataId,
        primaryDataPbcToken: formEntity.pbcToken,
        primaryDataFormEntityToken: formEntity.token,
        field: thisColumnField,
      }),
    )
    .unwrap();

  if (finalValue !== newValue) {
    event.node.setDataValue(thisColumnFieldToken, finalValue, AUTO_LOCK_EVENT_SOURCE);
  }
}

export async function processAutoLockByDeleteRows(rows, fieldConfig, store) {
  // layout未启用自动锁定，跳过
  if (!selectEnableAutoLock(store.getState())) {
    return;
  }

  const subFormEntity = await store
    .dispatch(
      getFormEntityByToken({
        pbcToken: fieldConfig.pbcToken,
        formEntityToken: fieldConfig.formEntityToken,
      }),
    )
    .unwrap();

  // 启用了自动锁定的字段
  const autoLockableFields = subFormEntity.fields?.filter((f) => f.isAutoLock) ?? [];

  if (autoLockableFields.length === 0) {
    return;
  }

  const formEntityDataId = selectInitialValues(store.getState())?.id;
  const formEntity = selectFormEntity(store.getState());

  const tasks = [];

  for (const row of rows) {
    for (const field of autoLockableFields) {
      const cellValue = row[field.token];
      if (cellValue == null) continue;
      const task = store.dispatch(
        handleLockOnFieldValueChange({
          oldValue: cellValue,
          newValue: null,
          primaryDataId: formEntityDataId,
          primaryDataPbcToken: formEntity.pbcToken,
          primaryDataFormEntityToken: formEntity.token,
          field,
        }),
      );
      tasks.push(task);
    }
  }

  await Promise.allSettled(tasks);
}
