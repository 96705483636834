import PropTypes from 'prop-types';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Button } from 'antd';
import { PlusIcon } from '@primer/octicons-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  HELPER_TEXT_TYPES,
  resolveInitialValues,
  selectContext,
  selectFieldInitialValue,
  selectValues,
} from '@icp/form-renderer-core';
import { composeEvent, debounce, delay, randomNumber, removeUndefined } from '@icp/utils';
import { isEqual } from 'lodash-es';
import { useEventCallback } from '@icp/hooks';
import { useStore } from '../../store';
import TableElement from '../TableElement';
import FieldTitle from '../../FieldTitle';
import {
  extractValueExpressions,
  findFirstEditableColKey,
  formatEditorColumnDefs,
  getChangedData,
  getRowData,
} from './utils';
import { useClassName } from '../../hooks';
import FormHelperText from '../../FormHelperText';
import { isDataValid, makeTableYupSchema } from './validation';
import { processCellForClipboard, processCellFromClipboard } from './processClipboard';
import { useElementDecorator, useFormApi, useIsInDesign } from '../../FormRenderCtx';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import { processValueExpression } from './processValueExpression';
import { useCurrentData } from '../../currentDataCtx';
import { processAutoLock, processAutoLockByDeleteRows } from './processAutoLock';
import useParentTableSelectedId from './useParentTableSelectedId';
import useDisplayedRowData from './useDisplayedRowData';
import { getSelectedIds } from '../TableElement/utils';
import useEventService from '../../hooks/useEventService';

export const JS_API_EVENT_TYPES = new Set(['dataFetched', 'addRow', 'deleteRow']);
const TRIGGER_CHANGE_TIME = 16;

const EditableTableElement = forwardRef(function EditableTableElement(props, ref) {
  const [updatedComponentProps, setComponentUpdatedProps] = useState({});

  const {
    keyPath,
    id,
    className: classNameProp,
    style,
    title,
    fieldConfig,
    componentProps = {},
    fieldTitleProps,
    value,
    onChange,
    setValidatorDelegate,
    disabled,
    readonly,
    validation,
    helpers,
  } = props;

  const componentPropsRef = useRef(null);
  componentPropsRef.current = { ...componentProps, ...removeUndefined(updatedComponentProps) };

  const {
    simpleMode = false,
    defaultColDef,
    columnDefs: columnDefsProp,
    dataSource, // 非法配置，从 componentProps 里摘取出来不传给 TableElement
    dataUrl: dataUrlProp,
    defaultValueDataUrl: defaultValueDataUrlProp,
    transformDataResponse,
    transformDefaultValueResponse,
    dataFilters,
    canAddRow = true,
    newRowDefaultValues,
    maxRowCount = Infinity,
    canDeleteRow = true,
    orderField,
    onGridReady,
    triggerDataFetchedOnGridReady = true,
    ...otherComponentProps
  } = componentPropsRef.current;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();
  const formApi = useFormApi();

  const routerParams = useParams();
  const store = useStore();
  const { t } = useTranslation(['icp-components']);
  const currentData = useCurrentData();
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const [refresh, setRefresh] = useState({});

  const nodeRef = useRef(null);
  const gridRef = useRef(null);
  const currentRowDataRef = useRef(null);
  const dataFetchedTrigger = useRef(!triggerDataFetchedOnGridReady);
  const eventService = useEventService({ otherComponentProps, JS_API_EVENT_TYPES });

  const columnDefs = useMemo(() => {
    return formatEditorColumnDefs(columnDefsProp, defaultColDef, disabled, readonly);
  }, [columnDefsProp, defaultColDef, disabled, readonly]);

  const yupSchema = useMemo(() => {
    return makeTableYupSchema(columnDefs, disabled, readonly);
  }, [columnDefs, disabled, readonly]);

  const valueExpressions = useMemo(() => {
    return extractValueExpressions(columnDefs);
  }, [columnDefs]);

  // 此表格作为另一个可编辑表格的子表，当前数据表示父亲可编辑表格某一条数据的子数据
  const hasParentTable = !!fieldConfig?.parentFieldToken;
  const relationKey = fieldConfig?.relations?.[0].colId;

  const parentSelectedId = useParentTableSelectedId({ fieldConfig });

  const { rowData, noNeedTriggerChange } = useDisplayedRowData({
    refresh,
    id,
    dataUrlProp,
    defaultValueDataUrlProp,
    dataFilters,
    transformDataResponse,
    transformDefaultValueResponse,
    orderField,

    hasParentTable,
    parentSelectedId,
    value,
    relationKey,
    currentRowDataRef,

    eventService,
    dataFetchedTrigger,
  });

  // 组件卸载时清空 redux 里的数据。因为 EditableTable 往后端提交的数据格式 { inserted, updated, deleted } 是
  // 通过 getChangedData 转换的到的，非此格式 api 会报错，此函数必须要在组件被渲染的时候才能调用到。
  // 解决方法是决定组件不可见的时候不应该往后端提交去修改 EditableTable 的数据。
  useEffect(() => {
    return () => onChange?.(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 代理表单验证方法，在提交表单的时候再次验证
  useMemo(() => {
    setValidatorDelegate?.((base) => ({
      ...base,
      // 注册运行时表单验证代理要求组件自身有validation属性
      // EditableTable存在自身无validation属性但columns中有的情况
      // 因此需确保组件自身有validation对象让columns验证器生效
      [Symbol.for('validation')]: {
        ...base?.[Symbol.for('validation')],
        validation: {
          ...base?.[Symbol.for('validation')]?.validation,
        },
      },
      isEmpty: () => {
        return !currentRowDataRef.current?.length;
      },
      isDataValid: () => {
        return isDataValid({
          yupSchema,
          columnDefs,
          rowData: currentRowDataRef.current ?? [],
        });
      },
    }));
  }, [columnDefs, setValidatorDelegate, yupSchema]);

  const handleGridReady = () => {
    if (triggerDataFetchedOnGridReady) {
      if (typeof dataFetchedTrigger.current === 'function') {
        dataFetchedTrigger.current();
      } else {
        dataFetchedTrigger.current = true;
      }
    }
  };

  const triggerChange = () => {
    if (noNeedTriggerChange.current) {
      return;
    }
    if (!gridRef.current?.gridApi) {
      return;
    }
    currentRowDataRef.current = getRowData(gridRef.current.gridApi);

    if (!isEqual(value, currentRowDataRef.current)) {
      if (hasParentTable) {
        const newValue = (value || [])
          .filter((item) => {
            return String(item[relationKey]) !== String(parentSelectedId);
          })
          .concat(currentRowDataRef.current);
        onChange(newValue);
      } else {
        onChange(currentRowDataRef.current);
      }
      // 不能在这里触发 onTouchChanged，不然新 add 一个空行立即就会触发验证报错
    }
  };

  const handleAddRow = useEventCallback((rows) => {
    if (hasParentTable && !parentSelectedId) {
      return;
    }

    rows = [].concat(rows).filter(Boolean);
    const newRowsData = [];

    for (const row of rows) {
      const newRow = { ...row };
      // 为了支持外部通过 gridApi 直接调用 applyTransaction({ update: })，需要保证行数据有 id
      if (!newRow.id && !simpleMode) {
        newRow.id = `inserted-${Date.now()}-${randomNumber()}`;
        if (hasParentTable) {
          newRow[relationKey] = parentSelectedId;
        }
      }
      newRowsData.push(newRow);
    }

    // insert new row to ag-grid
    gridRef.current.gridApi.applyTransaction({ add: newRowsData });
    eventService.current.dispatch({ type: 'addRow', payload: newRowsData });

    // ensure visible
    const rowIndex = gridRef.current.gridApi.getDisplayedRowCount() - 1;
    gridRef.current.gridApi.ensureIndexVisible(rowIndex, 'bottom');
  });

  const handleAddRowClick = () => {
    const newRow = resolveInitialValues({
      defaultValues: newRowDefaultValues,
      currentData,
      formData: selectValues(store.getState()),
      context: selectContext(store.getState()),
      params: routerParams,
    });

    // 不加多层 setTimeout ag-grid 会 startEditing 然后立马退出 editing，暂时没找到更好的解决办法。
    setTimeout(() => {
      handleAddRow([newRow]);

      // start editing cell on last row
      const rowIndex = gridRef.current.gridApi.getDisplayedRowCount() - 1;
      const colKey = findFirstEditableColKey(gridRef);

      setTimeout(() => {
        if (colKey) {
          gridRef.current.gridApi.startEditingCell({ rowIndex, colKey });
          gridRef.current.gridApi.setNodesSelected({
            nodes: [gridRef.current.gridApi.getDisplayedRowAtIndex(rowIndex)],
            newValue: true,
            source: 'api',
          });
        }
      }, 500);
    }, 16);
  };

  const handleUpdateRow = useEventCallback((rows) => {
    if (hasParentTable && !parentSelectedId) {
      return;
    }
    rows = [].concat(rows).filter(Boolean);
    gridRef.current.gridApi.applyTransaction({ update: rows });
  });

  const handleDeleteRow = useEventCallback((rows) => {
    if (hasParentTable && !parentSelectedId) {
      return;
    }
    rows = [].concat(rows).filter(Boolean);

    processAutoLockByDeleteRows(rows, fieldConfig, store).finally(() => {
      gridRef.current.gridApi.applyTransaction({ remove: rows });
      eventService.current.dispatch({ type: 'deleteRow', payload: rows });
    });
  });

  // 16ms 只是为了阻止复制粘贴多个 cell 触发多次
  const debouncedTriggerChange = debounce(() => {
    triggerChange();
  }, TRIGGER_CHANGE_TIME);

  const handleCellValueChanged = async (event) => {
    try {
      const autoLockTask = processAutoLock(event, fieldConfig, store);
      // delay 为了保证 debouncedTriggerChange 已执行
      formApi.registerPreSubmitTask(autoLockTask.finally(() => delay(TRIGGER_CHANGE_TIME + 10)));
      await autoLockTask;
    } finally {
      processValueExpression(event, valueExpressions);
      debouncedTriggerChange();
    }
  };

  // 编辑 cell 的时候不会触发，
  // 初次加载、直接调用 api applyTransaction 会触发。
  const handleRowDataUpdated = () => {
    triggerChange();
  };

  const isCopyToPasteRef = useRef(false);

  const getContextMenuItems = (params) => {
    if (!params.node) {
      return params.defaultItems;
    }

    const copyIndex = (params.defaultItems || []).indexOf('copy');

    const newMenus = [...params.defaultItems];

    newMenus.splice(copyIndex + 1, 0, {
      name: t('table.copy-to-paste'),
      icon: `<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>`,
      action: () => {
        isCopyToPasteRef.current = true;
        setTimeout(() => {
          // 异步里立即清空，防止后续操作发生错误导致 flag 无法重制
          isCopyToPasteRef.current = false;
        }, 16);
        params.api.copyToClipboard();
      },
    });

    if (!disabled && !readonly && canDeleteRow) {
      newMenus.splice(-2, 0, 'separator', {
        name: t('table.delete-row'),
        icon: `<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>`,
        action: () => handleDeleteRow([params.node.data]),
      });
    }

    return newMenus;
  };

  const processCellForClipboardCallback = useCallback(
    (params) => {
      return processCellForClipboard(params, store, routerParams, isCopyToPasteRef.current);
    },
    [routerParams, store],
  );

  const processCellFromClipboardCallback = useCallback(
    (params) => {
      return processCellFromClipboard(params, store, routerParams);
    },
    [routerParams, store],
  );

  const handleDragEnd = (params) => {
    if (!orderField) return;

    params.api.forEachNode((rowNode, i) => {
      const newOrder = i + 1;
      const oldOrder = rowNode.data[orderField];
      if (String(newOrder) !== String(oldOrder)) {
        if (params.api.getColumn(orderField)) {
          rowNode.setDataValue(orderField, newOrder);
        }
      }
    });
  };

  const tableApi = useMemo(() => {
    return {
      get node() {
        return nodeRef.current;
      },
      get gridApi() {
        // tableApi 会作为属性往 TableElement 传，withFieldWrapper 会遍历所有属性进行翻译，所以在组件渲染之前就会访问到 gridApi
        return gridRef.current?.gridApi;
      },
      on: (type, listener) => {
        eventService.current.on(type, listener);
      },
      getComponentProps: () => componentPropsRef.current,
      updateComponentProps: (obj) => {
        setComponentUpdatedProps((old) => {
          return { ...old, ...obj };
        });
      },
      refresh: () => setRefresh({}),
      isSimpleMode: () => simpleMode,
      getRowData: () => getRowData(gridRef.current.gridApi),
      getOldRowData: () => selectFieldInitialValue(id)(store.getState()), // simple mode 不会备份 oldRowData，rowData 永远和 store 里的相同
      getChangedData: () => getChangedData(gridRef.current.gridApi, store, id),
      addRow: handleAddRow,
      updateRow: handleUpdateRow,
      removeRow: handleDeleteRow,
      getSelectedIds: () => getSelectedIds(gridRef.current.gridApi),
    };
  }, [handleAddRow, handleUpdateRow, handleDeleteRow, eventService, simpleMode, id, store]);

  useImperativeHandle(ref, () => tableApi, [tableApi]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx('editable-table-element', 'input-element', 'form-element', className)}
        style={style}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        <div>
          <TableElement
            noElementDecorator={true}
            keyPath={keyPath}
            className={classNameComp}
            componentProps={{
              suppressSaveSetting: true,
              pagination: false,
              // TODO，下次把 fuzzy search 移到左边去就可以直接用 suppressToolbarActions 一个参数了
              suppressAddButton: true,
              suppressDeleteButton: true,
              suppressColumnSelect: true,
              suppressToolbarRefresh: true,
              suppressTableSetting: true,
              suppressFullscreen: true,
              suppressFavoriteView: true,
              suppressExcelExport: true,
              suppressCsvExport: true,
              suppressContextMenu: isInDesign,
              fuzzySearchOpen: true,
              enableCellTextSelection: false,
              cellSelection: true,
              rowDragManaged: true,
              // undefined 会覆盖 TableElement 自带的 getRowId
              getRowId: simpleMode ? undefined : (params) => String(params.data.id),
              // TODO, add / remove row cannot undo
              // undoRedoCellEditing: true,
              // undoRedoCellEditingLimit: 20,
              processCellFromClipboard: processCellFromClipboardCallback,
              processCellForClipboard: processCellForClipboardCallback,
              ...otherComponentProps,
              // 以下配置不允许从 componentProps 覆盖
              tableContext: tableApi, // 覆盖 TableElement 自身暴露给子元素的 tableContext
              defaultColDef,
              columnDefs,
              rowData,
              rowModelType: 'clientSide',
              rowSelection: {
                mode: 'singleRow',
                checkboxes: false,
                ...otherComponentProps.rowSelection,
              },
              getContextMenuItems,
              onCellValueChanged: handleCellValueChanged,
              onRowDataUpdated: handleRowDataUpdated,
              onRowDragEnd: handleDragEnd,
              onGridReady: composeEvent(handleGridReady, onGridReady),
              context: { yupSchema },
            }}
            ref={gridRef}
          />
          {!disabled &&
          !readonly &&
          canAddRow &&
          (value?.length || 0) < maxRowCount &&
          !(hasParentTable && !parentSelectedId) ? (
            <Button
              className="add-new-row-button"
              type="primary"
              onClick={handleAddRowClick}
              // rowData 为 null 时 ag-grid 会出 loading，表示正在获取数据
              disabled={rowData === null}
            >
              <PlusIcon size={16} />
              {t('table.add-new-row')}
            </Button>
          ) : null}
          <FormHelperText helpers={helpers} />
        </div>
      </div>
    </ElementDecorator>
  );
});

EditableTableElement.propTypes = {
  fieldApi: PropTypes.func,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  fieldConfig: PropTypes.shape({
    pbcToken: PropTypes.string,
    formEntityToken: PropTypes.string,
    parentFieldToken: PropTypes.string,
    relations: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string,
      }),
    ),
  }),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 如果设置 `true`, 在提交的时候全部发送给请求；
     * 如果设置 `false`，在提交的时候会自动识别成 { inserted, updated, deleted } 的增量修改模式。
     * @default false
     */
    simpleMode: PropTypes.bool,
    /**
     * 表格的列的定义，支持 ag-grid 所有的 columnDefs
     */
    columnDefs: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        // all other ag-grid column def properties
      }),
    ),
    /**
     * ag-grid 默认列的属性
     */
    defaultColDef: PropTypes.shape({}),
    /**
     * 转换从 `dataUrl` 获取到的数据，使用 eval 表达式，this 指向获取到的数据
     */
    transformDataResponse: PropTypes.string,
    /**
     * 通过 `dataUrl` 一次性获取所有表格数据
     */
    dataUrl: PropTypes.string,
    /**
     * 转换从 `defaultValueDataUrl` 获取到的数据，使用 eval 表达式，this 指向获取到的数据
     */
    transformDefaultValueResponse: PropTypes.string,
    /**
     * 表格默认值, 加载后放在inserted内
     */
    defaultValueDataUrl: PropTypes.string,
    /**
     * 请求数据源隐藏的固定 filter 条件，无法通过界面进行改变
     */
    dataFilters: PropTypes.shape({}),
    /**
     * 是否支持增加行
     * @default true
     * // TODO，先支持 bool 型，如果需要支持 dataPredicate，需要跟后端沟通。
     */
    canAddRow: PropTypes.bool,
    /**
     * 新增行的默认值
     */
    newRowDefaultValues: PropTypes.shape({}),
    /**
     * 可增加的最大行数
     */
    maxRowCount: PropTypes.number,
    /**
     * 是否支持删除行
     */
    canDeleteRow: PropTypes.bool,
    /**
     * 当表格通过数据源配置获取到数据后触发
     */
    onDataFetched: PropTypes.func,
    /**
     * 当表格新增行时出触发
     */
    onAddRow: PropTypes.func,
    /**
     * 当表格删除行时出发
     */
    onDeleteRow: PropTypes.func,
    /**
     * onDataFetched 事件是否需要等到 grid ready 之后保证 gridApi 有值过后才触发
     * @default
     */
    triggerDataFetchedOnGridReady: PropTypes.bool,
    // all other TableElement componentProps
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  value: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  setValidatorDelegate: PropTypes.func,
  // For EditableTable, disabled look the same as readonly
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
EditableTableElement.displayName = 'EditableTable';

export default withFieldWrapper(EditableTableElement);
