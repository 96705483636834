import { useMemo } from 'react';
import useScanCode from './useScanCode';
import ScanCodeScreen from './ScanCodeScreen';
import { CancelError } from './utils';

const ScanCode = {
  useScanner: () => {
    const scanCodeApi = useScanCode();
    const { startScan, stopScan } = scanCodeApi;
    return useMemo(
      () => [{ startScan, stopScan }, <ScanCodeScreen scanCodeApi={scanCodeApi} />],
      [scanCodeApi, startScan, stopScan],
    );
  },
  CancelError,
};

export default ScanCode;
