export const disableScrollAndMove = () => {
  const touchMoveListener = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  document.addEventListener('touchmove', touchMoveListener, { passive: false });
  const bodyOverflow = document.body.style.overflow;
  document.body.style.overflow = 'hidden';
  return () => {
    // undo
    document.removeEventListener('touchmove', touchMoveListener);
    document.body.style.overflow = bodyOverflow;
  };
};

export function CancelError(message) {
  this.name = 'CancelError';
  this.message = message || 'Scan Code operation canceled';
  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, CancelError);
  } else {
    this.stack = new Error().stack;
  }
}

CancelError.prototype = Object.create(Error.prototype);
CancelError.prototype.constructor = CancelError;
