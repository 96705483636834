import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  DEFAULT_I18N_VALUE,
  HELPER_TEXT_TYPES,
  helpersIsEmpty,
  I18N_VALUE_COLLAPSED,
  I18N_VALUE_FLAT,
  selectValues,
} from '@icp/form-renderer-core';
import { useTranslation } from 'react-i18next';
import { findI18nValues } from '@icp/i18n';
import { useElementDecorator } from '../../FormRenderCtx';
import { useClassName } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import { useStore } from '../../store';
import TextareaUI from './TextareaUI';

const TextareaElement = forwardRef(function TextareaElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    style,
    value,
    allowI18nValue,
    componentProps = {},
    helpers,
    onChange,
  } = props;

  const {
    simpleMode,
    i18nInputLayout: i18nInputLayoutProp = DEFAULT_I18N_VALUE,
    changeDelayToBlur = true,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const { i18n } = useTranslation();
  const store = useStore();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const [i18nInputLayout, setI18nInputLayout] = useState(i18nInputLayoutProp);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  // 响应 designer 里的改动
  useEffect(() => {
    setI18nInputLayout(i18nInputLayoutProp);
  }, [i18nInputLayoutProp]);

  // 处理脏数据，case 是 Input 最开始没有打开 allowI18nValue，保存的数据就是普通数据，例如 { longText: 'Name' }。
  // 后续打开 allowI18nValue，这时候组件会去读相应语言的 i18n value 会找不到不显示。
  // 所以这里发现这种情况过后，将老的普通不带多语言数据的 value 设置给当前登陆语言的 i18n value，例如 { longText_i18n_zh-CN: 'Name' }。
  useEffect(() => {
    if (simpleMode || !allowI18nValue || !value?.longText) {
      return;
    }
    const values = selectValues(store.getState());
    const i18nValues = findI18nValues(values, `${id}.longText`);

    if (!Object.keys(i18nValues).length) {
      console.warn(
        `${id} has the allowI18nValue turned on, the field has the value "${value.longText}" but there is no` +
          ` any i18n value. For the component to work properly, set "${value.longText}" to the i18n ` +
          `value longText_i18n_${i18n.language} of the current login language`,
      );
      onChange(
        {
          ...value,
          [`longText_i18n_${i18n.language}`]: value.longText,
        },
        undefined,
        false, // false 表示是代码自动触发的 change，不是用户通过 ui 组件修改的
      );
    }
  }, [allowI18nValue, id, value, store, onChange, i18n.language, simpleMode]);

  const handleChange = (newText, language) => {
    const valueI18nKey = `longText_i18n_${language}`;

    if (simpleMode) {
      // 产品 formEntityData 不支持 simpleMode，先不管 i18n
      onChange(newText);
    } else {
      // 不管有没有 language 都赋值给不带 _i18n_ 后缀的 value，否则在字段 required 验证的时候会出错
      // 这样的结果是在切换语言的时候没有当前语言的值会显示上一次修改的值
      const newValue = { ...value, longText: newText };
      if (allowI18nValue) {
        newValue[valueI18nKey] = newText;
      }

      onChange(newValue);
    }
  };

  const handleToggleI18nInputLayout = () => {
    if (i18nInputLayout === I18N_VALUE_COLLAPSED) {
      setI18nInputLayout(I18N_VALUE_FLAT);
    } else {
      setI18nInputLayout(I18N_VALUE_COLLAPSED);
    }
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'textarea-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
            'i18n-value-allowed': allowI18nValue,
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <TextareaUI
          {...props}
          componentProps={{
            rows: 4,
            ...otherComponentProps,
            className: classNameComp,
            changeDelayToBlur,
          }}
          simpleMode={simpleMode}
          i18nInputLayout={i18nInputLayout}
          onToggleI18nInputLayout={handleToggleI18nInputLayout}
          onChange={handleChange}
        />
      </div>
    </ElementDecorator>
  );
});

TextareaElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      longText: PropTypes.string,
    }),
  ]),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  allowI18nValue: PropTypes.bool,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * simple mode 不使用 { longText, value } 格式
     */
    simpleMode: PropTypes.bool,
    /**
     * 多语言输入的布局方式
     */
    i18nInputLayout: PropTypes.oneOf([I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT]),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    maxLength: PropTypes.number,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
TextareaElement.displayName = 'Textarea';

export default withFieldWrapper(TextareaElement);
