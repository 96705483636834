import PropTypes from 'prop-types';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { useMemo, useRef, useState } from 'react';
import { Box, Chip, CircularProgress, IconButton, Popover, TextField } from '@mui/material';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { Icon } from '@icp/components';
import { toSimpleValue } from '../SelectElement/utils';
import { getAllIds, getMaterialDisplayLabel, treeFindOption } from './utils';
import { FormHelperTextMaterial } from '../../FormHelperText';

function TreeSelectMaterial(props) {
  const {
    title,
    value,
    disabled,
    // componentProps,
    validation,
    readonly,
    status,
    helpers,
    onChange,
    onTouchChanged,

    multiple,
    mapping,
    loading,
    options,

    // treeLazyLoading, // TODO
    // loadData,
  } = props;

  const [open, setOpen] = useState(false);

  const anchorEl = useRef(null);

  const simpleValue = useMemo(() => {
    const v = toSimpleValue(value);
    // material tree view 要求 id 必须是 string
    if (Array.isArray(v)) {
      return v.map((item) => String(item));
    }
    return v !== undefined && v !== null ? String(v) : v;
  }, [value]);

  const hasValue = multiple ? Array.isArray(value) && !!value.length : !!value;

  const allIds = useMemo(() => getAllIds(options, mapping), [options, mapping]);

  const handleClose = () => {
    setOpen(false);
    if (onTouchChanged) {
      onTouchChanged();
    }
  };

  const handleClick = () => {
    if (loading || readonly || disabled) {
      return;
    }
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const getValue = (id) => {
    const item = treeFindOption(options, id, mapping, true);
    return item ? item[mapping.value] : id;
  };

  // material 的 tree view 不提供 multiple 的时候直接单击 item 多选的功能，必须要按住 command 键，所以这里自己实现。
  const handleItemClick = (event, id) => {
    if (multiple) {
      const ids = Array.isArray(simpleValue) ? [...simpleValue] : [];
      const index = ids.indexOf(id);
      if (index > -1) {
        ids.splice(index, 1);
      } else {
        ids.push(id);
      }
      onChange(ids.map(getValue));
    } else {
      onChange(getValue(id));
      handleClose();
    }
  };

  const handleDelete = (index) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue.map((item) => item.value));
  };

  const handleClear = (event) => {
    event.stopPropagation();
    setOpen(false);
    onChange();
  };

  return (
    <>
      <TextField
        className="tree-select-material"
        label={title}
        value={!multiple ? getMaterialDisplayLabel(value, options, mapping) : []}
        InputProps={{
          readOnly: true,
          sx: {
            paddingRight: value ? '65px' : '39px',
            paddingTop: '9px',
            paddingBottom: '9px',
            paddingLeft: '9px',
            flexWrap: 'wrap',
          },
          inputProps: {
            sx: {
              flex: 1,
              padding: '7.5px 4px 7.5px 5px',
              minWidth: '30px',
            },
          },
          startAdornment:
            multiple && Array.isArray(value)
              ? value.map((v, index) => {
                  return (
                    <Chip
                      key={v.value}
                      size="small"
                      label={getMaterialDisplayLabel(v, options, mapping)}
                      sx={{ margin: '3px', maxWidth: 'calc(100% - 6px)' }}
                      onDelete={!disabled && !readonly ? () => handleDelete(index) : null}
                    />
                  );
                })
              : null,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={18} /> : null}
              <Box sx={{ position: 'absolute', right: '9px', padding: '2px' }}>
                {hasValue && !disabled && !readonly ? (
                  <IconButton
                    className="clear-btn"
                    size="medium"
                    sx={{ padding: '4px', opacity: 0 }}
                    onClick={handleClear}
                  >
                    <Icon name="oct:x" size={18} />
                  </IconButton>
                ) : null}
                <IconButton size="medium" sx={{ padding: '2px' }}>
                  <Icon name="oct:chevron-down" size={18} />
                </IconButton>
              </Box>
            </>
          ),
          onClick: handleClick,
          ref: anchorEl,
        }}
        error={status === 'error'}
        helperText={!helpersIsEmpty(helpers) ? <FormHelperTextMaterial helpers={helpers} /> : null}
        InputLabelProps={{ shrink: (open || hasValue) && !loading }}
        focused={open}
        required={validation?.required}
        disabled={disabled}
      />
      <Popover
        slotProps={{
          paper: {
            style: {
              width: anchorEl.current?.offsetWidth,
            },
          },
        }}
        open={open}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableScrollLock={true}
        onClose={handleClose}
      >
        <RichTreeView
          multiSelect={multiple}
          expansionTrigger="iconContainer"
          items={options}
          selectedItems={simpleValue ?? null}
          defaultExpandedItems={allIds}
          getItemId={(item) => String(item[mapping.value])}
          getItemLabel={(item) => String(item[mapping.label])}
          onItemClick={handleItemClick}
          onItemExpansionToggle={(event) => event.stopPropagation()}
        />
      </Popover>
    </>
  );
}

TreeSelectMaterial.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      }),
    ),
  ]),
  componentProps: PropTypes.shape({}),
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    // Other key map to save
  }),
  multiple: PropTypes.bool,
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TreeSelectMaterial;
