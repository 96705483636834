import {
  dataSourceToDataUrl,
  fetchSingleDataSource,
  getFormEntityByToken,
  resolveConditionalProperty,
  resolveDataFilters,
  selectContext,
  selectValues,
} from '@icp/form-renderer-core';
import { get, toPath } from 'lodash-es';
import { getTFunc } from '@icp/i18n';
import { resolveVariablePattern } from '@icp/utils';
import { ACL_COLUMN, ACTION_COLUMN, AUTO_INDEX_COLUMN, SELECT_COLUMN } from './constant';
import { AutoCellRenderer, cellRenderMapping, NumberCellRenderer } from './cellRenders';

export function forEachColumnDefs(columnDefs, cb) {
  const dfs = (colDefArray) => {
    if (!Array.isArray(colDefArray)) {
      return;
    }

    for (const colDef of colDefArray) {
      cb(colDef);
      dfs(colDef.children);
    }
  };

  dfs(columnDefs);
}

export function flattenColumnDefs(columnDefs) {
  return columnDefs.flatMap((x) => (x.children ? flattenColumnDefs(x.children) : x));
}

async function getSetColumnFilterOptions({
  request,
  signal,
  colDef,
  tableDataSource,
  store,
  params,
  currentDataRef,
}) {
  const context = selectContext(store.getState());
  const formData = selectValues(store.getState());
  const { token: formEntityToken, pbcToken = context.pbcToken } = tableDataSource || {};

  const {
    stringEqual = true,
    mapping: mappingProp,
    dataSource,
    dataUrl: dataUrlProp,
    dataFilters,
    sortModel: sortModelProp,
    dataResponseKeyPath = 'results',
    transformDataResponse,
    translateDataResponse,
    defaultValue,
    httpMethod,
    httpBody,
    selectColId: selectColIdProp,
    // searchTextColIds,
  } = colDef.filterParams || {};

  let dataUrl;
  let mapping;
  let selectColId;

  if (dataSource || dataUrlProp) {
    const dataUrlConfig = dataUrlProp || dataSourceToDataUrl(dataSource, context);
    dataUrl = resolveVariablePattern({
      pattern: dataUrlConfig,
      currentData: currentDataRef.current || formData,
      formData,
      context,
      params,
      failWhenNoVariableValue: true,
    });
    mapping = mappingProp; // 要求如果 filterParams 配了 dataSource，必须指定 mapping
    selectColId = selectColIdProp;
  } else {
    await store
      .dispatch(getFormEntityByToken({ pbcToken, formEntityToken }))
      .unwrap()
      .then((entity) => {
        const field = entity.fields.find((f) => f.token === colDef.colId);
        const { referenceEntity, referencePbc, referenceField, referenceDisplayField } = field;
        dataUrl = `/form/api/v2/form-entity-data/${referencePbc}/${referenceEntity}/list`;
        mapping = { value: referenceField, label: referenceDisplayField };
        selectColId = [
          mapping.value,
          // mapping.label 可能是 abc[0].label 的形式，直接发给后端会报错，默认使用 selectColId 的目的只是为了
          // 减少数据加载，参数是字段 token，所以 abc[0].label 的配置 abc 才是 token
          toPath(referenceDisplayField)[0],
        ];
      });
  }

  const [filterModel] = resolveDataFilters({
    dataFilters,
    store,
    currentData: currentDataRef.current,
    params,
  });
  const sortModel = sortModelProp || [{ sort: 'asc', colId: mapping.label }];

  const { startRow, endRow, searchText } = request;

  return fetchSingleDataSource({
    signal,
    context,
    formData,
    searchText,
    // set filter 里目前也不支持自定义显示项，只会显示 label，所以只搜索可见的 label
    // 如果需要支持 api 搜索自定义 searchTextColIds，那需要同时修改 SetFilterValues.js 支持拿到所有数据后的前端搜索
    searchTextColIds: /* searchTextColIds?.length ? searchTextColIds :  */ [mapping.label],

    // lazy control
    startRow,
    endRow,

    // single data source config
    dataUrl,
    dataResponseKeyPath,
    filterModel,
    sortModel,
    transformDataResponse,
    translateDataResponse,
    defaultValue,
    httpMethod,
    httpBody,
    selectColId,
    needCount: false,
  }).then((res) => {
    return res.map((item) => {
      return {
        value: stringEqual ? String(item[mapping.value]) : item[mapping.value],
        label: get(item, mapping.label),
      };
    });
  });
}

function shouldUseCustomSetFilter(colDef, dataSource) {
  return (
    !colDef.filter &&
    (colDef.type === ACL_COLUMN || colDef.type === SELECT_COLUMN) &&
    (dataSource?.token || colDef.filterParams?.dataSource || colDef.filterParams?.dataUrl)
  );
}

export function handleCustomSetFilters({ columnDefs, dataSource, store, params, currentDataRef }) {
  return columnDefs.map((item) => {
    const colDef = { ...item };
    // TODO, clientSide 下使用 getOptions 操作 filter 有一些怪还有点异常情况，现在没时间了回头看统一全部用后端
    // filter 还是做成和 ag-grid 一样的使用表格已有的数据作为 set filter 的 values
    if (shouldUseCustomSetFilter(colDef, dataSource)) {
      colDef.filter = 'agSetColumnFilter';
      colDef.filterParams = {
        // 这个函数只是为了防止 ag-grid 在 clientSide 下报错当有 keyCreator 时必须要求有 valueFormatter
        valueFormatter: (p) => p.value?.label,
        getOptions: (request, signal) =>
          getSetColumnFilterOptions({
            request,
            signal,
            colDef,
            tableDataSource: dataSource,
            store,
            params,
            currentDataRef,
          }),
        ...colDef.filterParams,
      };
    }
    return colDef;
  });
}

// TODO, 处理有 column group children 的情况
export function formatColumnDefs(
  columnDefs,
  rowModelType,
  dataSource,
  store,
  routerParams,
  currentDataRef,
) {
  if (!Array.isArray(columnDefs)) {
    return [];
  }
  const formatted = columnDefs
    .filter((item) => !item.hidden)
    .map((item) => {
      const colDef = { ...item };
      // 针对特定 type 固定 colId 和 field。不能在 columnTypes.js 里写，ag-grid 的 columnTypes 里是缺省值的意思，会被 props 里 columnDefs 的属性覆盖掉。
      if (colDef.type === AUTO_INDEX_COLUMN) {
        colDef.colId = AUTO_INDEX_COLUMN;
        colDef.field = AUTO_INDEX_COLUMN; // field 在 export 的时候用
      }

      if (colDef.type === ACTION_COLUMN) {
        const t = getTFunc('icp-form-renderer');
        colDef.colId = item.colId ?? ACTION_COLUMN;
        colDef.field = null;
        colDef.headerName = colDef.headerName ?? t('action.header-name'); // 允许传 null 和空字符串不显示
      }

      /* if (rowModelType !== 'clientSide') {
        // 目前后端 serverSide 模式不支持 group
        delete colDef.enableRowGroup;
      } */
      return colDef;
    });
  return handleCustomSetFilters({
    columnDefs: formatted,
    dataSource,
    store,
    params: routerParams,
    currentDataRef,
  });
}

export function resolveDefaultColumnDefs(
  defaultColDefProp = {},
  currentData = {},
  store = {},
  params = {},
) {
  const newDefaultColDef = { ...defaultColDefProp };
  Object.entries(newDefaultColDef).forEach(([key, value]) => {
    const [resolvedValue] = resolveConditionalProperty({
      value,
      currentData,
      store,
      params,
    });

    newDefaultColDef[key] = resolvedValue;
  });
  return newDefaultColDef;
}

export function getDisplayTextFunc(colDef, gridContext) {
  const cellRenderer =
    typeof colDef.cellRenderer === 'string'
      ? cellRenderMapping[colDef.cellRenderer]
      : colDef.cellRenderer;
  if (cellRenderer === NumberCellRenderer) {
    return (v) => NumberCellRenderer.parseValue(v, colDef.cellRendererParams);
  }
  if (typeof cellRenderer?.toDisplayText === 'function') {
    return (v) => colDef.cellRenderer.toDisplayText(v, colDef.cellRendererParams, gridContext);
  }
  if (!colDef.type) {
    return (v) => AutoCellRenderer.toDisplayText(v);
  }
  return (v, o) => {
    if (o) {
      return get(o, colDef.field);
    }
    return v;
  };
}

export function makeSettingKey(id, context, prefix) {
  const { pbcToken, formEntityToken, layoutToken, schemaId, userProfile } = context;

  if (!id) {
    return null;
  }

  let key = `${prefix}.${id}`;

  if (pbcToken) {
    key += `.${pbcToken}`;
  }

  if (schemaId) {
    key += `.${schemaId}`;
  } else if (formEntityToken && layoutToken) {
    key += `.${formEntityToken}.${layoutToken}`;
  }

  if (userProfile?.username) {
    key += `.${userProfile.username}`;
  } else {
    key += '.all';
  }

  return key;
}

export function extractSortModel(columnState) {
  return columnState
    .filter((cs) => cs.sort)
    .sort((cs) => cs.sortIndex)
    .map((cs) => {
      return {
        colId: cs.colId,
        sort: cs.sort,
      };
    });
}

export function getExportableColumns(gridRef) {
  return gridRef.current.api
    .getAllGridColumns()
    .filter((column) => {
      const { colDef } = column;
      return (
        column.visible &&
        !(colDef.colId === undefined || colDef.colId === null) &&
        colDef.colId !== 'ag-Grid-ControlsColumn' &&
        colDef.colId !== 'ag-Grid-SelectionColumn' &&
        colDef.colId !== 'ag-Grid-RowNumbersColumn' &&
        colDef.type !== ACTION_COLUMN
      );
    })
    .map((column) => column.colDef);
}

export function getSelectedIds(gridApi) {
  const selectedRows = gridApi.getSelectedRows();
  return selectedRows.map((row) => row.id);
}

export function suppressEnterEventWhenEditing(params) {
  const isEnter = params.event.key === 'Enter';
  const showDelayStop = isEnter && params.editing;
  if (showDelayStop) {
    setTimeout(() => {
      params.api.stopEditing();
    }, 40);
  }
  return showDelayStop;
}

export function getDisplayedRowData(gridRef) {
  const rowData = [];
  gridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
    rowData.push(node.data);
  });
  return rowData;
}
