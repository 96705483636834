import { useDataSource } from './index';
import useDataSourceLazy from './useDataSourceLazy';

export default function useDataSourceSupportLazy(props) {
  const { skip, lazy, ...config } = props;

  const res1 = useDataSource({ ...config, skip: skip || lazy });
  const res2 = useDataSourceLazy({ ...config, skip: skip || !lazy });

  return {
    loading: lazy ? res2.freshLoading : res1.loading,
    error: lazy ? res2.error : res1.error,
    dataFetched: lazy ? res2.dataFetched : res1.dataFetched,
    allDataIsFetched: lazy ? res2.allDataIsFetched : res1.allDataIsFetched,
    moreLoading: res2.moreLoading,
    hasMore: res2.hasMore,
    loadMore: res2.loadMore,
    loadChildren: res2.loadChildren,
  };
}
