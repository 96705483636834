import { getSearchParams, isMobile, removeUndefined } from '@icp/utils';
import { restApi } from '@icp/settings';
import { entityDataUrl, flowDataUrl } from './dataUrlFactory';

export function fetchFlowForm({
  heartbeat,
  pbcToken,
  flowToken,
  flowDefinitionId,
  flowInstanceId,
  threadId,
  formEntityDataId,
  forceLayoutEnv,
}) {
  const url = flowDataUrl.get({ pbcToken, flowToken, flowDefinitionId, flowInstanceId });
  const searchParams = getSearchParams();
  // 移除 undefined 防止外部属性误展开了一个 undefined 的属性覆盖了有值的 searchParams 属性
  // searchParams 放前面，可以让其被 null 覆盖，例如 Button open 的 PageRenderer 会传 null 清空 formEntityDataId
  const params = { ...searchParams, ...removeUndefined({ threadId, formEntityDataId }) };

  return restApi.get(url, { params, headers: heartbeat.pageUUIDHeader }).then((res) => {
    const {
      pbcToken: formPbcToken,
      formEntityToken,
      formEntityLayoutToken,
      formEntityMobileLayoutToken,
      formLayoutId,
      formMobileLayoutId,
      ...otherRes
    } = res || {};

    let layoutId = isMobile() ? formMobileLayoutId : formLayoutId;
    let layoutToken = isMobile() ? formEntityMobileLayoutToken : formEntityLayoutToken;

    if (forceLayoutEnv === 'MOBILE') {
      layoutId = formMobileLayoutId;
      layoutToken = formEntityMobileLayoutToken;
    }
    if (forceLayoutEnv === 'PC') {
      layoutId = formLayoutId;
      layoutToken = formEntityLayoutToken;
    }

    return {
      // 当接口没有返回pbcToken时，约定该form与当前flow同属一个pbc
      ...otherRes,
      pbcToken: formPbcToken || pbcToken,
      formEntityToken,
      layoutToken,
      layoutId,
    };
  });
}

export function postFlowFormData({
  heartbeat,
  pbcToken,
  flowToken,
  formPbcToken,
  formEntityToken,
  layoutToken,
  flowDefinitionId,
  flowInstanceId,
  layoutId,
  threadId,
  saveOnly,
  data,
}) {
  const url = flowDataUrl.update({
    pbcToken,
    flowToken,
    formEntityToken,
    layoutToken,
    flowDefinitionId,
    flowInstanceId,
    layoutId,
  });
  const searchParams = getSearchParams();
  const params = { ...searchParams, ...removeUndefined({ threadId, saveOnly, formPbcToken }) };

  heartbeat.skip = true;
  return restApi.put(url, data, { params, headers: heartbeat.pageUUIDHeader }).then((res) => {
    // 正常流程表单节点提交后不会停留在当前页。
    // 死循环编辑表单的流程若配成了停留在当前页又配了锁，请推荐配置员提交后刷新页面来维持锁。
    heartbeat.unregisterAll();
    heartbeat.skip = false;
    return res;
  });
}

export function postApproveData({
  heartbeat,
  pbcToken,
  flowToken,
  formPbcToken,
  formEntityToken,
  layoutToken,
  flowInstanceId,
  threadId,
  data,
  //
}) {
  const url = flowDataUrl.approve({
    pbcToken,
    flowToken,
    formEntityToken,
    layoutToken,
    flowInstanceId,
  });
  const searchParams = getSearchParams();
  const params = { ...searchParams, ...removeUndefined({ formPbcToken, threadId }) };

  heartbeat.skip = true;
  return restApi.put(url, data, { params, headers: heartbeat.pageUUIDHeader }).then((res) => {
    // 正常流程审批节点提交后不会停留在当前页。
    // 死循环审批的流程若配成了停留在当前页又配了锁，请推荐配置员提交后刷新页面来维持锁。
    heartbeat.unregisterAll();
    heartbeat.skip = false;
    return res;
  });
}

export function fetchViewForm({ formEntityDataId, pbcToken, formEntityToken, forceLayoutEnv }) {
  const searchParams = getSearchParams();
  const url = entityDataUrl.view({ formEntityDataId, pbcToken, formEntityToken });

  return restApi.get(url, { params: searchParams }).then((res) => {
    const {
      formLayoutId,
      formMobileLayoutId,
      formEntityLayoutToken,
      formEntityMobileLayoutToken,
      notAllowedFields,
      data,
    } = res || {};

    let layoutId = isMobile() ? formMobileLayoutId : formLayoutId;
    let layoutToken = isMobile() ? formEntityMobileLayoutToken : formEntityLayoutToken;

    if (forceLayoutEnv === 'MOBILE') {
      layoutId = formMobileLayoutId;
      layoutToken = formEntityMobileLayoutToken;
    }
    if (forceLayoutEnv === 'PC') {
      layoutId = formLayoutId;
      layoutToken = formEntityLayoutToken;
    }

    return {
      layoutId,
      formEntityToken: res.formEntityToken, // 不能从参数拿，可能会不存在
      layoutToken,
      data,
      notAllowedFields,
    };
  });
}

export function createFormEntityData({
  heartbeat,
  enableAutoLock,
  pbcToken,
  formEntityToken,
  formEntityLayoutToken,
  formEntityId,
  formEntityLayoutId,
  data,
}) {
  const url = entityDataUrl.create({
    pbcToken,
    formEntityToken,
    formEntityLayoutToken,
    formEntityId,
    formEntityLayoutId,
  });
  heartbeat.skip = true;
  return restApi.post(url, data, { headers: heartbeat.pageUUIDHeader }).then((res) => {
    if (enableAutoLock) {
      heartbeat.unregisterAll();
      const retrieveUrl = entityDataUrl.retrieve({
        formEntityDataId: res.id,
        pbcToken,
        formEntityToken,
      });
      return restApi
        .get(retrieveUrl, {
          params: { enable_auto_lock: true },
          headers: heartbeat.pageUUIDHeader,
        })
        .then((getRes) => {
          // eslint-disable-next-line no-underscore-dangle
          if (getRes._lock) {
            heartbeat.skip = false;
            heartbeat.register({
              pbcToken: getRes.pbcToken,
              formEntityToken: getRes.formEntityToken,
              formEntityDataId: getRes.id,
            });
          }
          return getRes;
        });
    }
    return res;
  });
}

export function updateFormEntityData({
  heartbeat,
  enableAutoLock,
  pbcToken,
  formEntityToken,
  formEntityLayoutToken,
  formEntityLayoutId,
  formEntityDataId,
  data,
}) {
  const url = entityDataUrl.edit({
    pbcToken,
    formEntityToken,
    formEntityLayoutToken,
    formEntityLayoutId,
    formEntityDataId,
  });
  heartbeat.skip = true;
  return restApi.put(url, data, { headers: heartbeat.pageUUIDHeader }).then((res) => {
    if (enableAutoLock) {
      const retrieveUrl = entityDataUrl.retrieve({ formEntityDataId, pbcToken, formEntityToken });
      return restApi
        .get(retrieveUrl, {
          params: { enable_auto_lock: true },
          headers: heartbeat.pageUUIDHeader,
        })
        .then((getRes) => {
          heartbeat.skip = false;
          return getRes;
        });
    }
    return res;
  });
}
