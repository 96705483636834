import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Result } from 'antd';
import { useParams } from 'react-router-dom';
import { isMobile, setRef } from '@icp/utils';
import { Loading } from '@icp/components';
import { useTranslation } from 'react-i18next';
import { fetchFlowForm } from '@icp/page-renderer-core';
import { Heartbeat, FORM_API_STAGE } from '@icp/form-renderer-core';
import ApproveNodeFormRenderer from './ApproveNodeFormRenderer';
import FormNodeFormRenderer from './FormNodeFormRenderer';

const FlowFormRenderer = forwardRef(function FlowFormRenderer(props, ref) {
  const params = useParams();
  const { t } = useTranslation(['icp-common']);

  const {
    context,
    formType = 'FLOW-FORM',
    // Button dialog props 属性会传 null 来禁止从 params 读取这些属性
    flowToken = params.flowToken,
    flowDefinitionId = params.flowDefinitionId,
    flowInstanceId = params.flowInstanceId,
    threadId = params.threadId,
    formEntityDataId = params.formEntityDataId,
    forceLayoutEnv,
    dialogProps,
    FormRendererProps,
    onError,
    ...other
  } = props;

  const propsRef = useRef();
  propsRef.current = props;

  const { pbcToken } = context;

  const [instanceData, setInstanceData] = useState(null);
  const [error, setError] = useState(null);
  const heartbeat = useMemo(() => new Heartbeat(), []);

  useEffect(() => {
    if (!instanceData || error) {
      setRef(ref, { stage: FORM_API_STAGE.getForm, error });
    }
  }, [error, instanceData, ref]);

  useEffect(() => {
    setInstanceData(null);
    setError(null);
    fetchFlowForm({
      heartbeat,
      pbcToken,
      flowToken,
      flowDefinitionId,
      flowInstanceId,
      threadId,
      formEntityDataId,
      forceLayoutEnv,
    })
      .then(setInstanceData)
      .catch((e) => {
        console.error(e);
        setError(e);
        propsRef.current.onError?.(e);
      });
  }, [
    flowDefinitionId,
    flowInstanceId,
    threadId,
    formEntityDataId,
    forceLayoutEnv,
    flowToken,
    pbcToken,
    heartbeat,
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (instanceData?.data?._lock) {
      const heartBeatEntry = {
        pbcToken: instanceData.pbcToken,
        formEntityToken: instanceData.formEntityToken,
        formEntityDataId: instanceData.formEntityDataId,
      };
      heartbeat.register(heartBeatEntry);
      return () => {
        heartbeat.destroy();
      };
    }
    return () => {};
  }, [heartbeat, instanceData]);

  if (error) {
    return (
      <Result
        title={t('error-occurred')}
        subTitle={error.message}
        status={
          (error.errorCode === 'E90003' && 'warning') ||
          ([404, 403, 500].includes(error.status) && error.status) ||
          'error'
        }
      />
    );
  }

  if (!instanceData) {
    return <Loading className="form-renderer-block-loading" />;
  }

  const cmpProps = {
    heartbeat,
    context,
    pbcToken,
    flowToken,
    flowDefinitionId,
    flowInstanceId,
    threadId,
    instanceData,
    FormRendererProps,
    ref,
  };

  if (formType === 'FLOW-APPROVAL') {
    return (
      <ApproveNodeFormRenderer
        {...cmpProps}
        {...other}
        dialogProps={{ ...dialogProps, centered: !isMobile() }}
      />
    );
  }

  if (formType === 'FLOW-FORM') {
    return <FormNodeFormRenderer {...cmpProps} {...other} />;
  }
});

FlowFormRenderer.propTypes = {
  context: PropTypes.shape({
    pbcToken: PropTypes.string,
  }),
  formType: PropTypes.oneOf(['FLOW-FORM', 'FLOW-APPROVAL']),
  flowToken: PropTypes.string,
  flowDefinitionId: PropTypes.number,
  flowInstanceId: PropTypes.number,
  threadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // api 返回的 threadId 是 string，其他 id 都是 number
  formEntityDataId: PropTypes.number,
  forceLayoutEnv: PropTypes.oneOf(['MOBILE', 'PC']),
  dialogProps: PropTypes.shape({ title: PropTypes.string }),
  FormRendererProps: PropTypes.shape({
    defaultData: PropTypes.shape({
      flowInstanceId: PropTypes.number,
    }),
  }),
  onError: PropTypes.func,
};

export default FlowFormRenderer;
