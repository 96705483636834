import { useMemo, useRef } from 'react';
import {
  extractPropsEvents,
  JS_API_EVENT_TYPES,
  mergeDefaultValues,
  resolveInitialValues,
} from '@icp/form-renderer-core';
import { useParams } from 'react-router-dom';
import { shouldTranslateByDefault } from '@icp/settings';
import { translateEntireObj } from '@icp/i18n';
import { extractDefaultData, extractValueExpressions } from '../utils';
import useRemoteContext from './useRemoteContext';
import useDataTransformed from './useDataTransformed';

export default function useInitFormRenderer(props) {
  const {
    ElementDecorator,
    children,
    schema,
    context,
    yupSchema,
    defaultData,
    loadRemoteContext,
    retrieveUrl,
    transformRetrievedData,
    translateRetrievedData = shouldTranslateByDefault(),
    enableAutoLock,
    ...other
  } = props;

  const isInDesign = !!ElementDecorator;

  const params = useParams();

  const { loadingRemoteCtx, mixedContext } = useRemoteContext({
    ElementDecorator,
    schema,
    context,
    loadRemoteContext,
  });
  mixedContext.enableAutoLock = enableAutoLock;
  mixedContext.formEntity = schema?.[Symbol.for('formEntity')];

  const defaultValues = useMemo(() => {
    if (loadingRemoteCtx) return undefined;

    const fieldDefaultValues = extractDefaultData({ children, schema });

    const fieldDefaultValuesResolved = resolveInitialValues({
      defaultValues: fieldDefaultValues,
      context: mixedContext,
      params,
    });

    // values in defaultData will cover values in fieldDefaultValuesResolved
    return mergeDefaultValues(fieldDefaultValuesResolved, defaultData);
    // defaultValues 一般只在初始化的时候设置到 store，不支持更新
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingRemoteCtx]);

  const valueExpressions = useMemo(() => {
    if (loadingRemoteCtx) return undefined;

    return extractValueExpressions({ children, schema });
    // valueExpressions 一般只在初始化的时候设置到 store，不支持更新
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingRemoteCtx]);

  const [transforming, transformedDefaultValues] = useDataTransformed(
    defaultValues,
    transformRetrievedData,
    mixedContext,
  );

  if (translateRetrievedData) {
    translateEntireObj(transformedDefaultValues);
  }

  // 设计模式下不发请求取数据
  const isFetching = isInDesign ? false : Boolean(retrieveUrl);

  // 将 event 全部放到 events 对象里，同时名字由 onChange -> change，使用遍历给 ref 设置值保证 events 对象本身不变同时每次调用的函数都是最新的
  const events = useRef({});
  // legacy onDateChange api support
  if (!other.onChange && other.onDataChange) {
    other.onChange = other.onDateChanged;
    delete other.onDataChange;
  }
  extractPropsEvents(events.current, other, JS_API_EVENT_TYPES);

  return {
    loading: loadingRemoteCtx || transforming,
    context: mixedContext,
    yupSchema,
    defaultValues: transformedDefaultValues,
    valueExpressions,
    isFetching,
    events: events.current,
  };
}
