import './cellRenderers.css';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { ensureDisplayText } from '@icp/utils';
import { ConditionalPropertyPropType } from '../../../propTypes';
import LinkWrapper from '../../../wrappers/LinkWrapper';
import withCurrentData from './withCurrentData';
import UploadElement from '../../UploadElement';
import DateCellRenderer from './DateCellRenderer';
import NumberCellRenderer from './NumberCellRenderer';

function AutoCellRenderer(props) {
  const {
    value,
    valueFormatted,
    href,
    hrefSelector,
    hrefIsSiteBased,
    suppressBasePath,
    suppressInheritIncludeDeleted,
  } = props;

  // upload 统一显示成文件 link 的形式，点击会在新标签页打开，如果有别的需求的话可以改用 UPLOAD_COLUMN
  if (
    value?.value === null &&
    Array.isArray(value?.resources) &&
    value?.resources?.[0]?.storageId
  ) {
    return <UploadElement className="table-upload-cell" value={value} readonly={true} />;
  }

  // '[object Object]' 是因为 ag-grid 在 valueFormatted 做了多余的事情，最后判断如果 value 是 array 会强制调用 value.join(', '),
  // 导致即使 colDef 没有提供 valueFormatter 的时候 [{}, {}] 会变成 '[object Object], [object Object]'
  const displayText =
    typeof valueFormatted === 'number' ||
    (typeof valueFormatted === 'string' && !valueFormatted.startsWith('[object Object]'))
      ? valueFormatted
      : AutoCellRenderer.toDisplayText(value);

  const className =
    (typeof value === 'number' && 'table-number-cell') ||
    (isDate(value) && 'table-date-cell') ||
    '';

  if (href || hrefSelector) {
    return (
      <LinkWrapper
        className={className}
        componentProps={{
          href,
          hrefSelector,
          hrefIsSiteBased,
          suppressBasePath,
          suppressInheritIncludeDeleted,
          suppressLinkColor: false,
        }}
      >
        {ensureDisplayText(displayText)}
      </LinkWrapper>
    );
  }

  return <span className={className}>{ensureDisplayText(displayText)}</span>;
}

function getLabel(item) {
  if (item === undefined || item === null || typeof item === 'number' || typeof item === 'string') {
    return item;
  }

  if (typeof item === 'object') {
    return item.label ?? item.value;
  }

  return null;
}

function isDate(value) {
  // 不考虑 timestamp
  if (typeof value !== 'string') {
    return false;
  }

  const dataPattern = [
    'YYYY-MM-DD',
    'YYYY-M-DD',
    'YYYY-MM-D',
    'YYYY-M-D',
    'YYYY/MM/DD',
    'YYYY/M/DD',
    'YYYY/MM/D',
    'YYYY/M/D',
  ];

  return dataPattern.some((pattern) => dayjs(value).format(pattern) === value);
}

// 先不支持配置 cellRendererParams 用默认显示。有需求自己制定自己的 cellRenderer
AutoCellRenderer.toDisplayText = (value) => {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    value === null ||
    value === undefined
  ) {
    return value;
  }

  if (isDate(value)) {
    return DateCellRenderer.toDisplayText(value);
  }

  if (typeof value === 'number') {
    return NumberCellRenderer.toDisplayText(value);
  }

  if (Array.isArray(value)) {
    const str = value.map(getLabel).join('; ');
    // 如果返回 undefined 或者 null，ag-grid 在它的源代码 valueService.ts 里会兜底让[{}] 会变成 '[object Object]'，
    // 如果返回 ''，没找到源代码在哪里，但是 clientSide 调用 ag-grid 自己的导出 excel 功能的时候，发现依然会 让[{}] 会变成 '[object Object]，
    // 测试下来返回一个空白字符是可以的，空白字符在 ui 上显示也不会有问题。
    if (str === '' && value.length) {
      return ' ';
    }
    return str;
  }

  if (value?.longText) {
    return value?.longText;
  }

  return getLabel(value);
};

AutoCellRenderer.keyCreator = (params) => {
  return AutoCellRenderer.toDisplayText(params.value);
};

AutoCellRenderer.comparator = (valueA, valueB) => {
  return `${AutoCellRenderer.toDisplayText(valueA) ?? ''}`.localeCompare(
    `${AutoCellRenderer.toDisplayText(valueB) ?? ''}`,
  );
};

AutoCellRenderer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.shape({}),
  // TODO, support pattern '/path/{id}'
  href: ConditionalPropertyPropType(PropTypes.string),
  hrefSelector: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      matched: ConditionalPropertyPropType(PropTypes.bool),
    }),
  ),
  /**
   * 如果值为 `false` 并且此链接位于 pbc 下，href 将自动拼接上 pbc 的 `token` 作为 `basename`，移动端将自动拼接上 `mobile` 的前缀
   */
  hrefIsSiteBased: PropTypes.bool,
  /**
   * 是否直接链接不通过 react router 的 base path
   */
  suppressBasePath: PropTypes.bool,
  suppressInheritIncludeDeleted: PropTypes.bool,
};

export default withCurrentData(AutoCellRenderer);
