import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Result } from 'antd';
import { Loading } from '@icp/components';
import { useTranslation } from 'react-i18next';
import { fetchViewForm, PAGE_TYPE } from '@icp/page-renderer-core';
import { setRef } from '@icp/utils';
import { FORM_API_STAGE } from '@icp/form-renderer-core';
import FormRendererSelector from './FormRendererSelector';

const FormEntityDefaultViewRenderer = forwardRef(
  function FormEntityDefaultViewRenderer(props, ref) {
    const params = useParams();
    const { t } = useTranslation(['icp-common']);

    const {
      context: contextProp,
      formEntityToken = params.formEntityToken,
      formEntityDataId = params.formEntityDataId,
      forceLayoutEnv,
      suppressMakeFormReadonly = false,
      FormRendererProps,
    } = props;

    const [instanceData, setInstanceData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
      if (!instanceData || error) {
        setRef(ref, { stage: FORM_API_STAGE.getView, error });
      }
    }, [error, instanceData, ref]);

    // context must memo, every context change will trigger FormRender make new store
    const context = useMemo(() => {
      return { ...contextProp, formEntityDataId, pageType: PAGE_TYPE.formView };
    }, [contextProp, formEntityDataId]);

    const { pbcToken } = context;

    useEffect(() => {
      setInstanceData(null);
      setError(null);
      fetchViewForm({ formEntityDataId, pbcToken, formEntityToken, forceLayoutEnv })
        .then(setInstanceData)
        .catch((e) => {
          console.error(e);
          setError(e);
        });
    }, [forceLayoutEnv, formEntityDataId, formEntityToken, pbcToken]);

    if (error) {
      return (
        <Result
          title={t('error-occurred')}
          subTitle={error.message}
          status={[404, 403, 500].includes(error.status) ? error.status : 'error'}
        />
      );
    }

    if (!instanceData) {
      return <Loading />;
    }

    return (
      <FormRendererSelector
        {...FormRendererProps}
        formEntityToken={instanceData.formEntityToken}
        layoutToken={instanceData.layoutToken}
        layoutId={instanceData.layoutId}
        notAllowedFields={instanceData.notAllowedFields}
        defaultData={instanceData.data}
        context={context}
        // When view a form entity, should not change data
        readonly={!suppressMakeFormReadonly ? true : undefined}
        ref={ref}
      />
    );
  },
);

FormEntityDefaultViewRenderer.propTypes = {
  context: PropTypes.shape({}),
  formEntityToken: PropTypes.string,
  formEntityDataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  forceLayoutEnv: PropTypes.oneOf(['MOBILE', 'PC']),
  suppressMakeFormReadonly: PropTypes.bool,
  FormRendererProps: PropTypes.shape({}),
};

export default FormEntityDefaultViewRenderer;
