import { isValidElement } from 'react';
import { translateEntireObj } from '@icp/i18n';

// this method mutates props
export default function useTranslatedProps(props, skip = false) {
  if (skip) return;
  translateEntireObj(props, {
    skip: (k, v) => {
      return k === 'defaultData' || k === 'defaultValue' || isValidElement(v);
    },
    deleteI18nVariantProps: true,
  });
}
