import { v4 as uuidv4 } from 'uuid';
import { useCallback, useMemo, useState } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import { chooseFile } from '@icp/utils';
import { useTranslation } from 'react-i18next';
import useDeferredPromise from './useDeferredPromise';
import { CancelError } from './utils';

function useScanCode(props = {}) {
  const { t } = useTranslation(['icp-components']);
  const scannerId = useMemo(() => props.scannerId || `icp-scan-code-${uuidv4()}`, [props]);
  const codeReader = useMemo(() => new BrowserMultiFormatReader(), []);
  const [scanning, setScanning] = useState(false);
  const { deferred, createPromise } = useDeferredPromise();

  const scanFromVideo = useCallback(async () => {
    try {
      const videoDevices = await codeReader.listVideoInputDevices();
      if (videoDevices.length === 0) {
        return;
      }
      const constraints = { video: { facingMode: 'environment' } };
      await codeReader.decodeFromConstraints(constraints, scannerId, (res, err) => {
        if (res) {
          deferred?.resolve(res);
          return;
        }
        if (err && !(err instanceof NotFoundException)) {
          deferred?.reject(err);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [codeReader, deferred, scannerId]);

  const scanFromImage = useCallback(async () => {
    try {
      const file = await chooseFile({ accept: 'image/png, image/jpeg, image/webp' });
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = async () => {
          try {
            const result = await codeReader.decodeFromImageElement(image);
            deferred?.resolve(result);
          } catch (error) {
            deferred?.reject(
              error instanceof NotFoundException ? Error(t('scan-code.not-detected')) : error,
            );
          }
        };
      };
      reader.readAsDataURL(file);
    } catch (e) {
      console.error(e);
    }
  }, [codeReader, deferred, t]);

  const stopScan = useCallback(() => {
    codeReader.reset();
    setScanning(false);
  }, [codeReader]);

  const quitScan = useCallback(() => {
    stopScan();
    deferred?.reject(new CancelError());
  }, [deferred, stopScan]);

  const startScan = useCallback(async () => {
    setScanning(true);
    return createPromise().promise;
  }, [createPromise]);

  return useMemo(
    () => ({ scannerId, scanning, startScan, stopScan, quitScan, scanFromVideo, scanFromImage }),
    [scannerId, scanning, startScan, stopScan, quitScan, scanFromVideo, scanFromImage],
  );
}

export default useScanCode;
