import PropTypes from 'prop-types';
import { isValidElement } from 'react';
import { CurrentDataProvider } from '../../currentDataCtx';
import RecursionRenderer from '../../RecursionRenderer';

function ItemField(props) {
  const { keyPath, itemField, option } = props;

  return (
    <CurrentDataProvider value={option}>
      {isValidElement(itemField) ? (
        itemField
      ) : (
        <RecursionRenderer
          fields={[itemField]}
          keyPath={(keyPath || []).concat('componentProps', 'itemField')}
        />
      )}
    </CurrentDataProvider>
  );
}

ItemField.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  itemField: PropTypes.shape({}),
  option: PropTypes.shape({}),
};

export default ItemField;
