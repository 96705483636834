// eslint-disable-next-line import/no-unresolved
import { camelCase, capitalCase } from 'case-anything';
import { restApi } from '@icp/settings';
import { chooseFile, parseJSON, readFileAsDataURL } from '@icp/utils';
import { fetchJsonBySSE } from '@icp/components';
import { forEachFieldInSchema, findSchemaFieldByToken, isField } from '@icp/form-schema';
import { get, set } from 'lodash-es';
import { translateEntireObj } from '@icp/i18n';
import { extractDataFieldsFromSchema } from '../store/utils/formEntityHelper';
import { dataSourceToColumnDefs } from '../DesignerSettings/fieldSettings/EditableTableSettings/utils';

export function generateFormByText({
  pbcToken,
  pbcId,
  payload,
  model,
  onUpdateSchema,
  onGenerateSubFormEntityList,
  onThink,
  onDone,
  signal,
}) {
  model = 'openrouter/openai/gpt-4o';
  const { chatMsg } = payload;
  if (chatMsg.startsWith('>')) {
    return fetchJSONdiffData({ payload, model, onUpdateSchema, signal, onDone });
  }
  const params = model ? { model, stream: true, type: 'form' } : { stream: true, type: 'form' };
  const queryString = new URLSearchParams(params).toString();
  const request = {
    signal,
    url: `/aip/api/ai/form?${queryString}`,
    body: JSON.stringify(payload),
  };
  return fetchJsonBySSE({
    request,
    onThink,
    onJson: async (json) => {
      if (!(Array.isArray(json.result) && json.result.length > 0)) {
        // AI 直接返回了form没返回约定的格式
        await onUpdateSchema(json);
        return;
      }
      // 约定 result是数组, 第一个是主表, 第二个是子表的map by token
      const [mainForm, subFormMap] = json.result || [];
      const [fixedMainForm, subFormEntityList] = fixMainForm({
        mainForm,
        subFormMap,
        pbcToken,
        pbcId,
      });
      if (fixedMainForm) {
        await onUpdateSchema(fixedMainForm);
      }
      if (subFormEntityList) {
        await onGenerateSubFormEntityList?.(subFormEntityList);
      }
    },
  }).then(onDone);
}

function fetchJSONdiffData({ payload, model, onUpdateSchema, signal, onDone }) {
  const params = model ? { model, stream: false, type: 'form' } : { stream: false, type: 'form' };
  const newPayload = {
    ...payload,
    chatMsg: `${payload.chatMsg},Please locate and identify the "specified element" in the JSON data, and record its full path, which should start from the root node of the JSON:
If you need to add new fields before or after the "specified element", please add them to the 'insert' array in the following format: {"insert":[{'should be the full path of the 'specified element' separated by commas': {"id":"unique identifier generated", "title":"title of the field", "component":"type of the field component", "isBefore":"boolean value, indicating whether to insert before the 'specified element'"}}]}. eg: {insert: [{'fields,0,fields,0,fields,2': {"id": "6C5F319C","field name 1": "title name","component": "Input", isBefore: true}, {"id": "6C5F3212C","title": "field name 2","component": "Input", isBefore: true}}]}
If you need to change or modify the field of the "specified element", please add them to the 'update' array in the following format: {"update":[{'should be the full path of the 'specified element' separated by commas': {"id":"keep the specified id unchanged", "title":"updated title of the field", "component":"updated type of the field component"}}]}。 eg: {insert: [{'fields,0,fields,0,fields,2': {"id": "6C5F319C","field name 1": "title name","component": "Input"}, {"id": "6C319C","title": "field name 2","component": "Input"}}]} Please note that the 'id' field in the 'update' array must remain unchanged to identify the original field. Note that the "specified element" is an existing element in the original form JSON; the path for the inserted element is based on the path of the "specified element". The final returned JSON format should be: {"form":{},"fields":[],"insert":[],"update":[]}`,
  };
  const queryString = new URLSearchParams(params).toString();
  const request = { url: `/aip/api/gpt/create-chat-message?${queryString}`, newPayload };
  return restApi.post(request.url, newPayload, { signal }).then((result) => {
    // update form layout
    const responseJson = parseJSON(result?.generatedJson?.longText);
    const { insert = [], update = [] } = responseJson;
    insert.forEach((diff) => {
      const key = Object.keys(diff)[0];
      const keyPath = key.split(',');
      const { isBefore, ...field } = diff[key];
      // snippet 支持传入完成的field信息
      const source = { type: 'snippet', value: JSON.stringify(field) };
      const target = {
        isBefore,
        keyPath,
        operation: 'insert',
      };
      if (isField(field)) {
        onUpdateSchema(null, false, { incremental: true, source, target });
      }
    });

    update.forEach((diff) => {
      const key = Object.keys(diff)[0];
      const keyPath = key.split(',');
      const field = diff[key];
      if (isField(field)) {
        onUpdateSchema(null, false, { incremental: true, keyPath, updateValue: field });
      }
    });

    // finish ai chat
    onDone();
  });
}

export function chooseImageFile() {
  return chooseFile({ accept: 'image/png, image/jpeg, image/gif, image/webp' }).then(
    readFileAsDataURL,
  );
}

export function generateFormByImage(base64Image, model, signal) {
  const params = model ? { model, stream: false } : { stream: false };
  const queryString = new URLSearchParams(params).toString();
  const formData = new FormData();
  formData.set('base64Image', base64Image);
  return restApi.post(`/aip/api/gpt/image2form?${queryString}`, formData, { signal });
}

// ensure field id case camel
// ensure there are submit and cancel buttons
// ensure there is a status field
export function ensureSchemaSatisfies(schema) {
  let hasSubmitButton = get(schema, 'form.defaultSubmitButton');
  let hasCancelButton = get(schema, 'form.defaultCancelButton');
  let hasStatusField = false;

  forEachFieldInSchema(schema, (field) => {
    if (field.id && typeof field.id === 'string') {
      field.id = camelCase(field.id);
    }

    if (field.component === 'Button') {
      const actionType = field.componentProps?.action?.type;
      if (actionType === 'submit') {
        hasSubmitButton = true;
      } else if (actionType === 'cancel') {
        hasCancelButton = true;
      }
    }

    if (/(status|状态|状態)/i.test(field.title)) {
      hasStatusField = true;
    }
  });

  if (!hasSubmitButton) {
    set(schema, 'form.defaultSubmitButton', true);
  }
  if (!hasCancelButton) {
    set(schema, 'form.defaultCancelButton', true);
  }

  if (!hasStatusField) {
    const statusField = {
      id: 'status',
      component: 'Input',
      title: 'Status',
      'title_i18n_en-US': 'Status',
      'title_i18n_zh-CN': '状态',
      'title_i18n_ja-JP': '状態',
      hidden: true,
    };
    translateEntireObj(statusField, { deleteI18nVariantProps: true });
    if (!Array.isArray(schema.fields)) {
      schema.fields = [];
    }
    schema.fields.push(statusField);
  }
}

/* 父子表生成
  约定api返回父表中可编辑表格:
    id - AI生成, 没有的话前端补全, 用formEntityToken的camel case
    title - AI生成, 没有的话前端补全, 用formEntityToken的capital case
    fieldConfig.formEntityToken - AI生成
    fieldConfig.pbcToken - 前端补全, 用当前表单的pbcToken
    fieldConfig.relations - 前端补全, 固定内容
    componentProps.columnDefs - 前端补全, 在subFormMap中按token查找子表form前端产生columnDefs

  如果subFormMap中子表没出现在主表中, 前端补全:
    id - 用formEntityToken转case camel
    title - 用formEntityToken转case title
*/
function fixMainForm({ mainForm, subFormMap, pbcToken, pbcId }) {
  if (!subFormMap || !Object.keys(subFormMap).length) return [mainForm];
  const mainFormCopy = JSON.parse(JSON.stringify(mainForm));
  const subFormEntityList = [];

  for (const [subFormToken, subForm] of Object.entries(subFormMap)) {
    if (!subForm?.fields?.length) continue;
    if (!findSchemaFieldByToken(subForm, 'parentDataId').field) {
      subForm.fields.push({
        id: 'parentDataId',
        component: 'Input',
      });
    }
    const subFormDataFields = extractDataFieldsFromSchema(subForm, pbcToken);
    const subFormEntity = {
      pbcId,
      name: capitalCase(subFormToken, { keepSpecialCharacters: false }),
      token: subFormToken,
      fields: subFormDataFields,
      layouts: [
        {
          defaultLayout: true,
          name: 'Layout',
          token: 'default',
          schema: subForm,
        },
      ],
    };
    subFormEntityList.push(subFormEntity);
    const columnDefs = dataSourceToColumnDefs({
      fields: subFormDataFields,
      dataSourceFormEntity: subFormEntity,
    });

    let fixed = false;
    let lastEditableTablePosition = null;

    forEachFieldInSchema(mainFormCopy, (field, keyPath) => {
      if (field.component !== 'EditableTable') return;
      const formEntityToken = get(field, 'fieldConfig.formEntityToken');
      if (formEntityToken !== subFormToken) return;

      if (!field.id) {
        field.id = camelCase(subFormToken);
      }
      if (!field.title) {
        field.title = capitalCase(subFormToken, { keepSpecialCharacters: false });
      }
      set(field, 'fieldConfig.pbcToken', pbcToken);
      set(field, 'fieldConfig.relations', [
        {
          colId: 'parentDataId',
          filterType: 'text',
          type: 'equals',
          filter: '$CURRENT_FORM_DATA_ID',
        },
      ]);
      set(field, 'componentProps.columnDefs', columnDefs);

      fixed = true;
      lastEditableTablePosition = keyPath;
    });

    if (!fixed) {
      if (!mainFormCopy.fields) {
        mainFormCopy.fields = [];
      }

      const newItem = {
        id: camelCase(subFormToken),
        title: capitalCase(subFormToken, { keepSpecialCharacters: false }),
        component: 'EditableTable',
        fieldConfig: {
          pbcToken,
          formEntityToken: subFormToken,
          relations: [
            {
              colId: 'parentDataId',
              filterType: 'text',
              type: 'equals',
              filter: '$CURRENT_FORM_DATA_ID',
            },
          ],
        },
        componentProps: {
          columnDefs,
        },
      };

      if (!lastEditableTablePosition) {
        mainFormCopy.fields.push(newItem);
      } else {
        const parentKp = lastEditableTablePosition.slice(0, -1);
        const idx = lastEditableTablePosition.slice(-1)[0];
        const parent = get(mainFormCopy, parentKp);
        const before = parent.slice(0, idx);
        const after = parent.slice(idx);
        set(mainFormCopy, parentKp, [...before, newItem, ...after]);
        lastEditableTablePosition = [...parentKp, idx + 1];
      }
    }
  }

  return [mainFormCopy, subFormEntityList];
}
