// flow form node 的 新建、编辑、审批
const flowRoutes = {
  // 表单节点在流程第一个，新建、编辑表单触发流程
  'flow/:flowToken/new': {
    formType: 'FLOW-FORM',
  },
  // 表单节点在流程中间，编辑流程中间的表单节点
  'flow/:flowInstanceId/edit': {
    formType: 'FLOW-FORM',
  },
  // 审批节点在流程中间，审批流程中间的表单节点
  'flow/:flowInstanceId/approval': {
    formType: 'FLOW-APPROVAL',
  },
  // 审批节点在流程第一个，审批节点触发流程
  'flow/:flowToken/approval-new': {
    formType: 'FLOW-APPROVAL',
  },
};

// form 表单的 新建、编辑、查看
const formRoutes = {
  'form/:formEntityToken/layout/:layoutToken': {
    formType: 'ENTITY',
  },
  'form/:formEntityToken/layout/:layoutToken/:formEntityDataId': {
    formType: 'ENTITY',
  },
  // v1 view
  'form/:formEntityDataId/view': {
    formType: 'ENTITY-VIEW',
  },
  // v2 view
  'form/:formEntityToken/:formEntityDataId/view': {
    formType: 'ENTITY-VIEW',
  },
};

// 普通页面
const pageRoutes = {
  'page/:schemaId': {},
  '/': {
    schemaId: 'entry',
  },
};

export const generalRoutes = {
  desktop: { ...flowRoutes, ...formRoutes, ...pageRoutes },
  mobile: { ...flowRoutes, ...formRoutes, ...pageRoutes },
  // TODO, pad: { ...flowRoutes, ...formRoutes }
};
