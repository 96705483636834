import './Resizable.css';
import PropTypes from 'prop-types';
import { cloneElement, forwardRef, isValidElement, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useForkRef } from '@icp/hooks';
import { removeUndefined } from '@icp/utils';
import {
  computeNewValues,
  getDefaultSize,
  getSavedSize,
  normalizeMinMax,
  setSavedSize,
} from './utils';
import Draggable from '../Draggable';
import Icon from '../Icon';

const Resizable = forwardRef(function Resizable(props, ref) {
  const {
    children,
    placement,
    isPercent = false,
    defaultSize, // 使用 defaultSize 可以实现当 defaultSize 设置为 0 初始化 children 不 render
    min: minProp = 24, // TODO, min max as function
    max: maxProp,
    settingKey,
    maxGap = 24,
    showControlIcon = false,
    showHoverEffect = false,
    suppressResize = false,
    disableResize = suppressResize,
    ControlChildren,
    onResizeStart,
    onResize,
    onResizeEnd,
    ...other
  } = props;

  const [values, setValues] = useState(
    getSavedSize(placement, settingKey) || getDefaultSize(placement, defaultSize),
  );
  const [resizing, setResizing] = useState(false);

  const startValues = useRef({ width: 0, height: 0, translateX: 0, translateY: 0 });
  const elRef = useRef(null);
  const childrenRef = useForkRef(children?.ref, ref);
  const handleRef = useForkRef(childrenRef, elRef);
  const movePlacement = useRef(null);

  useEffect(() => {
    if (disableResize || !elRef.current) return;
    const cssPosition = window.getComputedStyle(elRef.current).position;
    // .icp-resize-control need parent is absolute or relative to set place
    if (cssPosition === 'static') {
      elRef.current.style.position = 'relative';
    }
  }, [disableResize]);

  if (!isValidElement(children)) {
    console.error('Resizable children is not an valid react element', children);
    return null;
  }

  if (isPercent && placement === 'all') {
    console.warn('Resizable placement all not support isPercent true');
  }

  // 'all' means has 8 placements is resizable, in most cases it is like popover or dialog, no need support percent
  const unit = placement !== 'all' && isPercent ? '%' : 'px';

  const computeValues = (move) => {
    const { min, max } = normalizeMinMax({ minProp, maxProp, maxGap, placement, elRef });
    return computeNewValues({
      move,
      elRef,
      movePlacement,
      placement,
      isPercent,
      min,
      max,
      maxGap,
      startValues,
    });
  };

  const handleResizeStart = (event, p) => {
    const style = window.getComputedStyle(elRef.current);
    const { m41: translateX, m42: translateY } = new window.DOMMatrixReadOnly(style.transform);

    movePlacement.current = p || placement;
    startValues.current = {
      width: elRef.current.offsetWidth,
      height: elRef.current.offsetHeight,
      translateX,
      translateY,
    };

    setResizing(true);

    if (onResizeStart) {
      onResizeStart(event);
    }
  };

  const handleResize = (event, move) => {
    const newValues = computeValues(move);

    // Directly modify style for performance
    if (['left', 'right', 'all'].includes(placement)) {
      elRef.current.style.width = `${newValues.width}${unit}`;
    }
    if (['top', 'bottom', 'all'].includes(placement)) {
      elRef.current.style.height = `${newValues.height}${unit}`;
    }
    if (placement === 'all') {
      elRef.current.style.transform = `translate(${newValues.translateX}px, ${newValues.translateY}px)`;
    }

    if (onResize) {
      onResize(event, newValues);
    }
  };

  const handleResizeEnd = (event, move) => {
    const movedSize = computeValues(move);
    const newValues = { ...values, ...movedSize };

    setValues(newValues);
    setResizing(false);

    if (settingKey) {
      setSavedSize(placement, settingKey, newValues);
    }

    if (onResizeEnd) {
      onResizeEnd(event, newValues);
    }
  };

  // double click to restore initial size
  const handleDoubleClick = (event) => {
    const newValues = getDefaultSize(placement, defaultSize);

    setValues(newValues);

    if (settingKey) {
      localStorage.removeItem(settingKey);
    }

    if (onResizeEnd) {
      onResizeEnd(event, newValues);
    }
  };

  if (disableResize) {
    return children;
  }

  const resizedStyle = {
    width:
      ['left', 'right', 'all'].includes(placement) && values.width >= 0
        ? `${values.width}${unit}`
        : undefined,
    height:
      ['top', 'bottom', 'all'].includes(placement) && values.height >= 0
        ? `${values.height}${unit}`
        : undefined,
    transform: ['all'].includes(placement)
      ? `translate(${values.translateX}px, ${values.translateY}px)`
      : undefined,
    transition: resizing ? 'none' : undefined,
    flex: resizing || values.width >= 0 || values.height >= 0 ? 'none' : undefined,
  };

  return cloneElement(children, {
    ...other,
    className: clsx(children.props.className, 'icp-resizable', {
      resizing,
      'is-all': placement === 'all',
      ...other.className,
    }),
    style: {
      ...other.style,
      ...children.props.style,
      ...removeUndefined(resizedStyle),
    },
    children: (
      <>
        {!resizing && (values.width === 0 || values.height === 0) ? null : children.props.children}
        {placement !== 'all' ? (
          <Draggable
            onDragStart={(event) => handleResizeStart(event)}
            onDrag={handleResize}
            onDragEnd={handleResizeEnd}
          >
            <div
              className={clsx('icp-resize-control', placement, {
                'line-vertical': placement === 'top' || placement === 'bottom',
                'line-horizontal': placement === 'left' || placement === 'right',
                'hover-effect': showHoverEffect,
              })}
              onDoubleClick={handleDoubleClick}
            >
              {ControlChildren}
              {!ControlChildren && showControlIcon ? (
                <Icon
                  name={
                    ['top', 'bottom'].includes(placement)
                      ? 'material:more-horiz-outlined'
                      : 'material:more-vert-outlined'
                  }
                  size={16}
                />
              ) : null}
            </div>
          </Draggable>
        ) : null}
        {placement === 'all'
          ? [
              'top',
              'right',
              'left',
              'bottom',
              'top-left',
              'top-right',
              'bottom-left',
              'bottom-right',
            ].map((p) => {
              return (
                <Draggable
                  key={p}
                  onDragStart={(event) => handleResizeStart(event, p)}
                  onDrag={handleResize}
                  onDragEnd={handleResizeEnd}
                >
                  <div
                    className={clsx('icp-resize-control', p, {
                      'line-vertical': ['top', 'bottom'].includes(p),
                      'line-horizontal': ['right', 'left'].includes(p),
                      corner: p.includes('-'),
                    })}
                    onDoubleClick={handleDoubleClick}
                  />
                </Draggable>
              );
            })
          : null}
      </>
    ),
    ref: handleRef,
  });
});

Resizable.propTypes = {
  children: PropTypes.element,
  // 目前只支持单边 resize，或者所有方向均可 resize
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'all']),
  isPercent: PropTypes.bool,
  defaultSize: PropTypes.number,
  // null 表示不限制
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ]),
  // null 表示不限制
  max: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ]),
  // 元素放到到最大的时候与父元素的间距，null 表示不限制
  maxGap: PropTypes.number,
  showControlIcon: PropTypes.bool,
  showHoverEffect: PropTypes.bool,
  /**
   * @deprecated
   */
  suppressResize: PropTypes.bool,
  disableResize: PropTypes.bool,
  settingKey: PropTypes.string,
  ControlChildren: PropTypes.node,
  onResizeStart: PropTypes.func,
  onResize: PropTypes.func,
  onResizeEnd: PropTypes.func,
};

export default Resizable;
