import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { composeEvent } from '@icp/utils';
import { TextField } from '@mui/material';

const NoI18nTextarea = forwardRef(function NoI18nTextarea(props, ref) {
  const { value: valueProp, onChange, onBlur, changeDelayToBlur, readonly, ...other } = props;

  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleChange = (event) => {
    if (changeDelayToBlur) {
      setValue(event.target.value);
    } else {
      onChange(event.target.value);
    }
  };

  const handleBlur = (event) => {
    if (changeDelayToBlur) {
      onChange(event.target.value);
    }
  };

  return (
    <TextField
      {...other}
      multiline={true}
      value={value ?? ''}
      onChange={handleChange}
      onBlur={composeEvent(handleBlur, onBlur)}
      InputProps={{ readOnly: readonly }}
      ref={ref}
    />
  );
});

NoI18nTextarea.propTypes = {
  readonly: PropTypes.bool,
  changeDelayToBlur: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default NoI18nTextarea;
