import PropTypes from 'prop-types';
import { Input } from 'antd';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { composeEvent } from '@icp/utils';
import ValueLanguageSelect from '../../../ValueLanguageSelect';

const I18nTextareaMulti = forwardRef(function I18nTextareaMulti(props, ref) {
  const { id, value: valueProp, onChange, onBlur, changeDelayToBlur, ...other } = props;

  const { i18n } = useTranslation();

  const [valueLanguage, setValueLanguage] = useState(i18n.language);

  const valueI18nKey = `longText_i18n_${valueLanguage}`;

  const valueI18n = valueProp?.[valueI18nKey];

  const [value, setValue] = useState(valueI18n);

  useEffect(() => {
    setValue(valueI18n);
  }, [valueI18n]);

  const handleChange = (event) => {
    if (changeDelayToBlur) {
      setValue(event.target.value);
    } else {
      onChange(event.target.value, valueLanguage);
    }
  };

  const handleBlur = (event) => {
    if (changeDelayToBlur) {
      onChange(event.target.value, valueLanguage);
    }
  };

  return (
    <>
      <ValueLanguageSelect value={valueLanguage ?? ''} onChange={setValueLanguage} />
      <Input.TextArea
        {...other}
        value={value ?? ''}
        onChange={handleChange}
        onBlur={composeEvent(handleBlur, onBlur)}
        ref={ref}
      />
    </>
  );
});

I18nTextareaMulti.propTypes = {
  id: PropTypes.string,
  changeDelayToBlur: PropTypes.bool,
  value: PropTypes.shape({
    longText: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default I18nTextareaMulti;
