import './ScanCode.css';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Icon from '../Icon';
import { disableScrollAndMove } from './utils';

function ScanCodeScreen(props) {
  const { scanCodeApi } = props;
  const { scannerId, scanning, scanFromVideo, scanFromImage, stopScan, quitScan } = scanCodeApi;

  useEffect(() => {
    if (!scanning) {
      return () => {};
    }
    scanFromVideo();
    const undo = disableScrollAndMove();
    return () => {
      undo();
      stopScan();
    };
  }, [scanFromVideo, scanning, stopScan]);

  return scanning
    ? ReactDOM.createPortal(
        <div className="icp-scan-code">
          <video id={scannerId} className="icp-scan-code-video" />
          <div className="icp-scan-code-panel">
            <div className="icp-scan-code-panel-header">
              <Button
                className="icp-scan-code-panel-button"
                onClick={quitScan}
                icon={<Icon name="oct:x" size={24} />}
              />
            </div>
            <div className="icp-scan-code-panel-footer">
              <Button
                className="icp-scan-code-panel-button"
                type="primary"
                onClick={scanFromImage}
                icon={<Icon name="ant:picture-outlined" size={24} />}
              />
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
}

ScanCodeScreen.propTypes = {
  scanCodeApi: PropTypes.shape({
    scannerId: PropTypes.string,
    scanFromVideo: PropTypes.func,
    scanFromImage: PropTypes.func,
    stopScan: PropTypes.func,
    quitScan: PropTypes.func,
  }),
};

export default ScanCodeScreen;
