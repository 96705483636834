import { formatDate, formatNumber, getTFunc } from '@icp/i18n';
import dayjs from 'dayjs';
import { AG_FILTER_TYPES_UNARY } from '@icp/components-core';
import { filterHasValue, getSetFilterOptions, getSetItemValue } from './utils';

function getUnaryTypeText(model) {
  const { type } = model;
  const t = getTFunc('icp-vendor-aggrid');
  return t(type);
}

function getTextTypeText(model) {
  const { type, filter, filterTo } = model;
  const t = getTFunc('icp-vendor-aggrid');

  if (type === 'inRange') {
    return (
      <span className="icp-filter-value">
        {filter} → {filterTo}
      </span>
    );
  }

  return (
    <>
      <span className="icp-filter-type">{t(type)}</span>
      <span className="icp-filter-value">{filter}</span>
    </>
  );
}

function getNumberTypeText(model) {
  const { type, filter, filterTo } = model;
  const t = getTFunc('icp-vendor-aggrid');

  if (type === 'inRange') {
    return (
      <span className="icp-filter-value">
        {formatNumber(filter)} → {formatNumber(filterTo)}
      </span>
    );
  }

  return (
    <>
      <span className="icp-filter-type">{t(type)}</span>
      <span className="icp-filter-value">{formatNumber(filter)}</span>
    </>
  );
}

function getDateTypeText(model) {
  const { type, dateFrom, dateTo } = model;
  const t = getTFunc('icp-vendor-aggrid');

  if (type === 'inRange') {
    return (
      <span className="icp-filter-value">
        {formatDate(dayjs(dateFrom).toDate())} → {formatDate(dayjs(dateTo).toDate())}
      </span>
    );
  }

  return (
    <>
      <span className="icp-filter-type">{t(type)}</span>
      <span className="icp-filter-value">{formatDate(dayjs(dateFrom).toDate())}</span>
    </>
  );
}

function getAsyncSetTypeText(model, context, colDef) {
  const { type, values, filter, filterTo } = model;

  const result = getSetFilterOptions(context, colDef);

  const format = (options) => {
    if (type === 'inRange') {
      return (
        <span className="icp-filter-value">
          {(options || []).find((op) => op.value === filter.value)?.label || filter.value}→
          {(options || []).find((op) => op.value === filterTo.value)?.label || filterTo.value}
        </span>
      );
    }
    return (
      <div className="icp-filter-value">
        {values
          .map((v) => (options || []).find((op) => op.value === v.value)?.label || v.value)
          .filter(Boolean)
          .join(', ')}
      </div>
    );
  };

  if (result instanceof Promise) {
    return result.then(format);
  }

  return format(result);
}

function getSyncSetTypeText(model) {
  const { type, values, filter, filterTo } = model;

  if (type === 'inRange') {
    return (
      <span className="icp-filter-value">
        {getSetItemValue(filter, 'label')} → {getSetItemValue(filterTo, 'label')}
      </span>
    );
  }

  return (
    <div className="icp-filter-value">
      {values.map((item) => getSetItemValue(item, 'label')).join(', ')}
    </div>
  );
}

export default function getFilterValueText(context, model, colDef) {
  if (!colDef || !filterHasValue(model)) {
    return '';
  }

  const { filterType } = model;

  if (AG_FILTER_TYPES_UNARY.includes(model.type)) {
    return getUnaryTypeText(model);
  }

  if (filterType === 'text') {
    return getTextTypeText(model);
  }

  if (filterType === 'number') {
    return getNumberTypeText(model);
  }

  if (filterType === 'date') {
    return getDateTypeText(model);
  }

  // 结果可能是异步, 只针对 ENUM_COLUMN 配置的情况，ENUM 列的选项以前基本是固定写死在了 columnDefs 里的。
  // 新完善 set filter 过后其他类型的列通过 Toolbar 操作保存的 set filterType 一定是 { value, label } 格式
  if (filterType === 'set' && ['ENUM_COLUMN', 'STATUS_COLUMN'].includes(colDef.type)) {
    return getAsyncSetTypeText(model, context, colDef);
  }

  if (filterType === 'set') {
    return getSyncSetTypeText(model);
  }

  return '';
}
