import { v4 as uuidv4 } from 'uuid';
import { parseJSON } from '@icp/utils';
import { CUSTOM_I18N_KEY_SEPARATOR } from '@icp/i18n';
import {
  CROSS_LAYOUT_PROPERTIES_MAPPING,
  findSchemaFieldByToken,
  forEachFieldInSchema,
  forEachInputFieldInSchema,
  schemaFieldToDataField,
} from '@icp/form-schema';
import { get, isEqual, set } from 'lodash-es';
import { ACTION_COLUMN, AUTO_INDEX_COLUMN } from '@icp/form-renderer';
import { restApi } from '@icp/settings';

export function getUniqueId() {
  let id;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    id = uuidv4().split('-')[0];
    // 不要全 number 的 id
    if (Number.isNaN(+id)) {
      break;
    }
  }
  return id;
}

export function dataFieldToSchemaField(dataField, initial = {}) {
  const schemaField = JSON.parse(JSON.stringify(initial));

  CROSS_LAYOUT_PROPERTIES_MAPPING.FIELDS_TO_LAYOUTS.forEach(
    ([keyPathOfField, keyPathOfLayout, converter]) => {
      const value = converter(get(dataField, keyPathOfField));

      // dataField申明锁定的，强制使用dataField的值，哪怕是空的。token domain module 强制锁定
      const locked = ['token', 'domain', 'module'].includes(keyPathOfField)
        ? true
        : get(dataField, `${keyPathOfField}Locked`);

      if (locked) {
        set(schemaField, keyPathOfLayout, value);
        return;
      }

      // 没被锁的，优先使用initial的值，initial没值的使用dataField的值
      if (get(schemaField, keyPathOfLayout) == null) {
        set(schemaField, keyPathOfLayout, value);
      }
    },
  );

  if (schemaField.title) {
    schemaField.title_i18n_key = [
      'standard',
      dataField.domain || 'any',
      dataField.module || 'any',
      dataField.token,
      // 分隔符不能用.否则需要翻译文件是嵌套结构
      // 分隔符不能用i18next保留的分隔符, _ 是context和plurals的分隔符 :是namespace分隔符
    ]
      .map((x) => x.replace(/[.:_]/g, CUSTOM_I18N_KEY_SEPARATOR))
      .join(CUSTOM_I18N_KEY_SEPARATOR);
    schemaField.title_i18n_ns = 'standard';
  }

  return schemaField;
}

export function isCrossLayoutProperty(propertyKeyPath) {
  return CROSS_LAYOUT_PROPERTIES_MAPPING.KEY_PATHS.OF_LAYOUTS.some((kp) => {
    // OF_LAYOUTS 里有些是用数组写的，有些是直接写的，本身 syncOneInputOneProperty 支持的 keyPath 就可以是数组也可以是直接的 key。
    // [].concat 一下就可以同时支持 kp 等于 fieldConfig 或者 ['fieldConfig'] 的用法
    return isEqual([].concat(kp), [].concat(propertyKeyPath));
  });
}

export function getMapOfField(keyPathOfLayout) {
  return CROSS_LAYOUT_PROPERTIES_MAPPING.FIELDS_TO_LAYOUTS.find((map) => {
    return isEqual([].concat(map[1]), [].concat(keyPathOfLayout));
  });
}

export function removeDuplicateFields(fields, key) {
  const keys = new Set();

  const newFields = [];

  for (const field of fields) {
    if (!keys.has(field[key])) {
      newFields.push(field);
      keys.add(field[key]);
    }
  }

  return newFields;
}

function extractInputFieldsFromSchema(schema) {
  const inputFields = [];

  forEachInputFieldInSchema(schema, (field) => {
    inputFields.push(field);
  });

  return removeDuplicateFields(inputFields, 'id');
}

export function extractDataFieldsFromSchema(schema, pbcToken) {
  const inputFields = extractInputFieldsFromSchema(schema);
  return inputFields.map((schemaField) => schemaFieldToDataField(schemaField, pbcToken));
}

// TODO，检查 ID 重复发出警告
export function checkIdDuplicated() {}

export function extractAllIds(schema) {
  const ids = new Set();

  forEachFieldInSchema(schema, (field) => {
    if (field.id) {
      ids.add(field.id);
    }
  });

  return ids;
}

export function findHiddenFields(fields, schema) {
  const existingFields = extractDataFieldsFromSchema(schema);

  return fields.filter((itemA) => {
    return !existingFields.find((itemB) => itemA.token === itemB.token);
  });
}

export function findNearestField(el) {
  let parentField = el;

  while (
    parentField &&
    parentField !== document.documentElement &&
    !parentField.classList.contains('fd-canvas-container')
  ) {
    if (
      parentField.classList.contains('form-element') ||
      parentField.classList.contains('form-layout') ||
      parentField.classList.contains('form-wrapper') ||
      parentField.classList.contains('form-renderer')
    ) {
      if (
        parentField.classList.contains('table-element') &&
        parentField.parentNode.parentNode.classList.contains('editable-table-element')
      ) {
        return parentField.parentNode.parentNode;
      }
      return parentField;
    }
    parentField = parentField.parentNode;
  }

  return null;
}

export function pageToFormEntity(pageData) {
  const { pbcId, name, schemaId, description, contentVersion } = pageData;

  return {
    pbcId,
    name,
    token: schemaId,
    description,
    fields: [],
    layouts: [{ ...pageData, token: schemaId }], // 保留完整的 pageData 到 layout 里，方便 DiffView 查看 diff 用
    contentVersion,
  };
}

export function formEntityToPage(entity) {
  const layout = entity.layouts[0];
  const { token, defaultLayout, ...other } = layout;

  return {
    ...other,
    pbcId: entity.pbcId,
    schemaId: entity.token,
    name: entity.name,
    description: entity.description,
    contentVersion: entity.contentVersion,
  };
}

function getAllSchemaFields(layouts) {
  return removeDuplicateFields(
    layouts.map((layout) => extractInputFieldsFromSchema(layout.schema)).flatMap((x) => x),
    'id',
  );
}

export function filterFieldsNotInAnyLayouts(fields, layouts, allSchemaFields) {
  if (!allSchemaFields) {
    allSchemaFields = getAllSchemaFields(layouts);
  }

  const allIds = new Set(allSchemaFields.map((x) => x.id));

  return fields.filter((field) => {
    const has = allIds.has(field.token);
    if (!has) {
      // log 一下方便开发发现
      // console.log('This field not existing in any layout', JSON.parse(JSON.stringify(field)));
    }
    return has;
  });
}

export function assignDataFieldProperties(dataField, newDataField) {
  const newField = { ...dataField };

  // 必须增量式的 assign，否则可能会把未存在于 schema 的属性给覆盖掉，例如 storageField
  for (const key of Object.keys(newDataField)) {
    // 后端 api 会把 field 里不存在的值都设置成 null，undefined 再去覆盖一下 null 只会造成无意义的 diff 显示。
    if (!(newDataField[key] === undefined && dataField[key] === null)) {
      newField[key] = newDataField[key];
    }
  }

  return newField;
}

function reExtractDataFields(existingFields, allSchemaFields, pbcToken) {
  return existingFields.map((dataField) => {
    const schemaField = allSchemaFields.find((x) => x.id === dataField.token);
    const newDataField = schemaFieldToDataField(schemaField, pbcToken);
    return assignDataFieldProperties(dataField, newDataField);
  });
}

export function ensureFields(fields, layouts, pbcToken) {
  // Remove duplicate (there should be no duplicated fields, maybe dirty data?)
  let existingFields = removeDuplicateFields(fields, 'token');

  // Add field in layouts but not in fields
  const allSchemaFields = getAllSchemaFields(layouts);

  // Remove not existing in any layout
  existingFields = filterFieldsNotInAnyLayouts(existingFields, layouts, allSchemaFields);

  // 重新从 layout 里读取 data field 的属性设置回 fields 里。因为 formEntity.fields 里可能是早起保存的数据，
  // 当后端给 data field 加了新的属性（例如 referenceMappingFields）的时候，需要从 layout schema 里面新生成一下。
  existingFields = reExtractDataFields(existingFields, allSchemaFields, pbcToken);

  let restFields = allSchemaFields.filter((schemaField) => {
    return !existingFields.find((dataField) => schemaField.id === dataField.token);
  });
  restFields = restFields.map((schemaField) => schemaFieldToDataField(schemaField, pbcToken));

  return existingFields.concat(restFields);
}

export function findSchemaFieldInEntityByToken(formEntity, token) {
  let schemaField;
  formEntity.layouts.find((layout) => {
    const { field } = findSchemaFieldByToken(layout.schema, token);
    if (field) {
      schemaField = field;
      return true;
    }
    return false;
  });
  return schemaField;
}

// 不考虑 colId 重复的情况，colId 不应该重复
export function colIdToColIndex(fieldInfo, colId) {
  const { columnDefs } = fieldInfo?.componentProps || {};

  if (!Array.isArray(columnDefs)) {
    return -1;
  }

  const colIndex = columnDefs.findIndex((colDef) => {
    // 如果只写了 field 没有写 colId，ag-grid 会自动将 field 作为 colId
    return colDef.colId || colDef.field ? colDef.colId === colId || colDef.field === colId : false;
  });

  if (colIndex !== -1) {
    return colIndex;
  }

  if (colId === ACTION_COLUMN) {
    return columnDefs.findIndex((colDef) => colDef.type === ACTION_COLUMN);
  }

  if (colId === AUTO_INDEX_COLUMN) {
    return columnDefs.findIndex((colDef) => colDef.type === AUTO_INDEX_COLUMN);
  }

  // gantt 对没有 colId 的列会自动生成 timestamp，我们的代码无法转换，只能用选择第一个未填 id 的列去模糊的匹配一下，
  // 处理新添加一列显示 “请选择数据源” 这种大部分简单情况
  /* if (typeof colId === 'string' && colId.length === 13 && typeof Number(colId) === 'number') {
    return columnDefs.findIndex((colDef) => !colDef.colId);
  }

  // 没有传 colId ag-grid 会自动生成数字递增的 id
  if (typeof Number(colId) === 'number') {
    let step = 0;
    for (let index = 0; index < columnDefs.length; index++) {
      if (!columnDefs[index].colId) {
        if (step === Number(colId)) {
          return index;
        }
        step += 1;
      }
    }
  } */

  return -1;
}

export function colIndexToColId(fieldInfo, colIndex) {
  const { columnDefs } = fieldInfo?.componentProps || {};

  if (!Array.isArray(columnDefs)) {
    return null;
  }

  const colDef = columnDefs[colIndex];

  if (!colDef) {
    return null;
  }

  if (colDef.colId) {
    return colDef.colId;
  }

  if (colDef.type === ACTION_COLUMN) {
    return ACTION_COLUMN;
  }

  if (colDef.field) {
    return colDef.field;
  }

  // 没有传 colId ag-grid 会自动生成数字递增的 id
  /* let step = 0;
  for (let index = 0; index < columnDefs.length; index++) {
    if (!columnDefs[index].colId) {
      if (index === colIndex) {
        return String(step);
      }
      step += 1;
    }
  } */

  return null;
}

export function getReferenceReciprocal(dataField) {
  return JSON.stringify({
    referenceField: dataField.token,
    keyField: (parseJSON(dataField.referenceMappingFields, { silent: true }) || {}).value,
  });
}

// -1 表示应该给自己设置
// 1 表示给对方设置
// 0 表示随便一方
function reReferenceShouldBe(thisDataField, reciprocalDataField) {
  if (thisDataField.multiple && !reciprocalDataField.multiple) {
    return -1;
  }

  if (!thisDataField.multiple && reciprocalDataField.multiple) {
    return 1;
  }

  return 0;
}

export function shouldSetReference(thisDataField, reciprocalDataField) {
  const x = parseJSON(thisDataField.referenceReciprocal, { silent: true }) || {};
  const y = parseJSON(reciprocalDataField.referenceReciprocal, { silent: true }) || {};

  // 任意一边用户主动在设置里选了 none，都不自动设置
  if (x.disabled || y.disabled) {
    return false;
  }

  const canBeSelf = reReferenceShouldBe(thisDataField, reciprocalDataField) !== 1;
  return (
    canBeSelf &&
    // 已经有了
    thisDataField.referenceReciprocal !== getReferenceReciprocal(reciprocalDataField) &&
    // 对方没有设置成自己
    reciprocalDataField.referenceReciprocal !== getReferenceReciprocal(thisDataField)
  );
}

export async function handleReferenceReciprocal(formEntity, formListInProject) {
  const fieldsToBeValidate = formEntity.fields.filter((dataField) => {
    return (
      (dataField.type === 'ACL' || dataField.type === 'Select') &&
      dataField.referencePbc &&
      dataField.referenceEntity &&
      dataField.referenceField
    );
  });

  const remoteToUpdate = [];

  for (const dataField of fieldsToBeValidate) {
    const localDataSourceEntity = formListInProject.find((entity) => {
      return (
        entity.token === dataField.referenceEntity && entity.pbcToken === dataField.referencePbc
      );
    });

    if (!localDataSourceEntity) {
      continue;
    }

    // eslint-disable-next-line no-await-in-loop
    const remoteDataSourceEntity = await restApi.get(
      `/form/api/form-entity/${localDataSourceEntity.id}`,
    );

    const reciprocalDataField = remoteDataSourceEntity.fields.find((field) => {
      return field.referenceEntity === formEntity.token;
    });

    // 对方 entity 没有 acl 或者 select 选择己方 entity
    if (!reciprocalDataField) {
      continue;
    }

    if (shouldSetReference(dataField, reciprocalDataField)) {
      dataField.referenceReciprocal = getReferenceReciprocal(reciprocalDataField);

      if (reciprocalDataField.referenceReciprocal) {
        // 自身设置了，对方有老的 referenceReciprocal 需要清空的
        reciprocalDataField.referenceReciprocal = undefined;
        remoteToUpdate.push(remoteDataSourceEntity);
      }
    } else if (shouldSetReference(reciprocalDataField, dataField)) {
      dataField.referenceReciprocal = undefined;
      // 应该设置给对方，但是对方没有，需要给对方加上
      reciprocalDataField.referenceReciprocal = getReferenceReciprocal(dataField);
      remoteToUpdate.push(remoteDataSourceEntity);
    }
  }

  return {
    newFormEntity: formEntity,
    remoteToUpdate,
  };
}
