import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { OOTB_VALIDATION } from '@icp/form-renderer-core';
import { CurrentDataProvider } from '../../../currentDataCtx';
import InputElement from '../../InputElement';

function TextCellEditor(props) {
  const { value, onValueChange, cellStartedEdit, eventKey, data, context, validation } = props;

  const refInput = useRef(null);

  useEffect(() => {
    let highlightAllOnFocus = true;

    if (typeof eventKey === 'string' && eventKey.length === 1) {
      highlightAllOnFocus = false;
      onValueChange(eventKey);
    }

    if (highlightAllOnFocus) {
      refInput.current.node.querySelector('input').select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrentDataProvider value={data}>
      <InputElement
        className="table-text-editor"
        suppressFormControl={true}
        value={value}
        onChange={() => {}}
        // InputElement 本身onBlur触发change，ag-grid中改为onChange触发change
        componentProps={{
          size: context.tableSize,
          maxLength: validation?.maxLength,
          autoFocus: cellStartedEdit,
          onChangeCapture: (e) => onValueChange(e.target.value),
        }}
        ref={refInput}
      />
    </CurrentDataProvider>
  );
}

TextCellEditor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onValueChange: PropTypes.func,
  cellStartedEdit: PropTypes.bool,
  eventKey: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    minLength: PropTypes.number,
    minLengthErrorMessage: PropTypes.string,
    maxLength: PropTypes.number,
    maxLengthErrorMessage: PropTypes.string,
    ootb: PropTypes.oneOf(OOTB_VALIDATION),
    regex: PropTypes.string,
    regexErrorMessage: PropTypes.string,
  }),
};

export default TextCellEditor;
