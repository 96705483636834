import { useEffect, useMemo, useRef, useState } from 'react';
import { useEventCallback } from '@icp/hooks';
import { debounce } from 'lodash-es';
import { getSetFilterOptions } from './utils';

function useSetFilterOptionsLazy({ searchText, colDef }) {
  // getOptions 是我们组件自定义的配置，必须返回 promise
  const { getOptions, cacheBlockSize = 100 } = colDef.filterParams || {};

  const skip = typeof getOptions !== 'function';

  const [options, setOptions] = useState(null);
  const [freshLoading, setFreshLoading] = useState(!skip);
  const [moreLoading, setMoreLoading] = useState(false);

  const allDataIsFetched = useRef(false);
  const hasMore = useRef(!skip);
  const dataLength = useRef(0);

  const fetchOptions = useEventCallback((signal, isLoadMore) => {
    if (isLoadMore) {
      setMoreLoading(true);
    } else {
      setFreshLoading(true);
    }

    return getOptions(
      {
        searchText,
        startRow: isLoadMore ? dataLength.current : 0,
        endRow: (isLoadMore ? dataLength.current : 0) + cacheBlockSize,
      },
      signal,
    ).then((data) => {
      setOptions((exists) => {
        const newOptions = isLoadMore ? (exists || []).concat(data) : data;
        dataLength.current = newOptions.length;
        return newOptions;
      });

      hasMore.current = data.length && dataLength.current % cacheBlockSize === 0;
      allDataIsFetched.current = !searchText && !hasMore.current;

      setFreshLoading(false);
      setMoreLoading(false);
      return data;
    });
  });

  const leading = !searchText;
  const debounceRequest = useMemo(() => {
    return debounce((cb) => cb(), 200, { leading });
  }, [leading]);

  useEffect(() => {
    if (skip) {
      return () => {};
    }

    const controller = new AbortController();
    const { signal } = controller;

    debounceRequest(() => {
      fetchOptions(signal, false);
    });

    return () => {
      controller.abort();
    };
    // 只相应 searchText 的变化重新加载数据
  }, [debounceRequest, fetchOptions, searchText, skip]);

  const loadMore = (signal) => {
    if (skip || !hasMore.current || moreLoading) {
      return;
    }

    debounceRequest(() => {
      fetchOptions(signal, true);
    });
  };

  if (skip) {
    return null;
  }

  return {
    loading: freshLoading,
    moreLoading,
    options,
    hasMore: hasMore.current,
    loadMore,
    allDataIsFetched: allDataIsFetched.current,
  };
}

function useSetFilterOptionsNotLazy(context, colDef) {
  const [options, setOptions] = useState(null);

  const { getOptions } = colDef.filterParams || {};

  const skip = typeof getOptions === 'function';

  useEffect(() => {
    // getSetFilterOptions 里是使用 ag-grid 官方的 filterParams.values 的配置
    const res = getSetFilterOptions(context, colDef);
    if (res instanceof Promise) {
      res.then(setOptions);
    } else {
      setOptions(res);
    }
  }, [colDef, context]);

  if (skip) {
    return null;
  }

  return { options, allDataIsFetched: !!options };
}

export default function useSetFilterOptions({ searchText, context, colDef }) {
  const res1 = useSetFilterOptionsLazy({ searchText, colDef });
  const res2 = useSetFilterOptionsNotLazy(context, colDef);

  return res1 || res2;
}
