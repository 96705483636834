export function mergeSearchTextToFilterModel(searchText, searchTextColIds, filterModel) {
  const searchColConditions = searchTextColIds.map((colId) => {
    return {
      colId,
      filterType: 'text',
      type: 'contains',
      filter: searchText,
    };
  });
  const searchColFilterModel =
    searchTextColIds.length > 1
      ? [
          {
            operator: 'OR',
            conditions: searchColConditions,
          },
        ]
      : searchColConditions;
  if (filterModel?.length) {
    filterModel = [
      {
        operator: 'AND',
        conditions: [...filterModel, ...searchColFilterModel],
      },
    ];
  } else {
    filterModel = searchColFilterModel;
  }
  return filterModel;
}
