import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  useCallback,
  useState,
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { Upload, Button, Progress, Modal } from 'antd';
import { message } from '@icp/settings';
import { ImageViewer } from 'antd-mobile';
import {
  uploadFile,
  filterAcceptedFiles,
  UPLOAD_TYPE_MAP,
  HELPER_TEXT_TYPES,
  helpersIsEmpty,
} from '@icp/form-renderer-core';
import { useTranslation } from 'react-i18next';
import { useEventCallback } from '@icp/hooks';
import { Icon } from '@icp/components';
import { fileIsImage } from '@icp/utils';
import { useElementDecorator } from '../FormRenderCtx';
import FieldTitle from '../FieldTitle';
import FormHelperText from '../FormHelperText';
import { useDispatch } from '../store';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

const empty = [];

const FileStatus = {
  UPLOADING: 'UPLOADING',
  DONE: 'DONE',
  FAILED: 'FAILED',
  REMOVED: 'REMOVED',
};

const NOT_FINISHED_FILE_STATUSES = [FileStatus.UPLOADING, FileStatus.FAILED];

const processPreviewUrl = (url) => {
  if (!url) return url;
  if (url.startsWith('http://') || url.startsWith('https://')) {
    const overrideHost = window.__bot_conf__?.['fss.override-host'];
    if (overrideHost != null) {
      return url.replace(/^http(s)?:\/\/[^/]+/i, overrideHost);
    }
    return url;
  }
  return url;
};

const UploadElement = forwardRef(function UploadElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value,
    disabled,
    readonly,
    style,
    componentProps = {},
    fieldTitleProps,
    validation,
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  const {
    uploadTitle,
    subTitle,
    listType = 'text',
    maxCount = 4,
    showCount,
    accept: acceptProp,
    sizeLimit,
    multiple,
    buttonProps,
    useOnlinePreview = false,
    onlinePreviewUseIframe = true,
  } = componentProps;
  const { resources = empty } = value || {};

  const { t } = useTranslation(['icp-form-renderer']);
  const ElementDecorator = useElementDecorator();
  const dispatch = useDispatch();

  const [fileList, setFileList] = useState(() => {
    return resources.map((item) => ({
      ...item,
      uid: item.storageId,
      name: item.fileName,
      url: processPreviewUrl(item.url),
    }));
  });

  const resourceList = useRef(resources);
  const fileListIdSet = useMemo(() => new Set(fileList.map((file) => file.uid)), [fileList]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [kkfileviewUrl, setKkfileviewUrl] = useState('');
  const [, setRefresh] = useState(Date.now());
  const fileStatusMap = useRef({});
  // antd 的 bug，在上传过程中反复调用 itemRenderer 的时候， thumbUrl 会反复的在 undefined 和 base 64 当中切换，导致图片 render 反复闪烁，
  // 自己缓存一份有值的，当 undefined 的时候就用缓存起来的。
  const imgThumbCache = useRef({});

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const accept =
    acceptProp || (listType === 'picture-card' && UPLOAD_TYPE_MAP.img) || UPLOAD_TYPE_MAP.file;

  // useEventCallback to promise the newest resources
  const triggerChange = useEventCallback((item) => {
    // 这里如果直接使用 value 中的 resources 不一定能取到最新的值，所以改为使用 resourceList 来记录
    resourceList.current = resourceList.current.concat(item);
    return onChange({ resources: resourceList.current });
  });

  // 处理 FormRenderer re-fetch 的新数据
  useEffect(() => {
    const hasUploading = Object.values(fileStatusMap.current).some(
      (fileStatus) => fileStatus === FileStatus.UPLOADING,
    );
    if (!hasUploading) {
      setFileList(
        resources.map((item) => ({
          ...item,
          uid: item.storageId,
          name: item.fileName,
          url: processPreviewUrl(item.url),
        })),
      );
    }
  }, [resources]);

  const doUpload = (params) => {
    const { uid } = params.file;
    fileStatusMap.current[uid] = FileStatus.UPLOADING;
    setRefresh(Date.now());
    dispatch(
      uploadFile([params.file], {
        onUploadProgress: ({ total, loaded }) => {
          params.onProgress({ percent: Math.floor((loaded / total) * 100) });
        },
      }),
    )
      .then(
        async (newResources) => {
          // 可能在上传过程中被 remove 了
          if (fileStatusMap.current[uid] !== FileStatus.UPLOADING) {
            return;
          }
          const item = newResources[0];
          await triggerChange(item);
          params.onSuccess(item);
          fileStatusMap.current[uid] = FileStatus.DONE;
        },
        () => {
          message.error(t('error.upload'));
          params.onError();
          fileStatusMap.current[uid] = FileStatus.FAILED;
        },
      )
      .finally(() => {
        setRefresh(Date.now());
        if (onTouchChanged) {
          onTouchChanged();
        }
      });
  };

  const handleChange = (params) => {
    // 需要在 onChange 的时候过滤掉不然会出现在 antd 的 file list 里
    const [acceptedFiles, notMatchedMsg] = filterAcceptedFiles(params.fileList, accept, sizeLimit);

    if (notMatchedMsg.length) {
      notMatchedMsg.forEach((msg) => message.warning(msg));
    }

    setFileList(acceptedFiles.map((file) => ({ ...file, url: processPreviewUrl(file.url) })));
  };

  const customRequest = (params) => {
    const { file } = params;
    if (!fileListIdSet.has(file.uid)) return;

    // 需要再过滤一遍，antd 好像是不管 handleChange 里 set 的新的 fileList，不支持的格式依然会调用 customRequest
    const [acceptedFiles] = filterAcceptedFiles([file], accept, sizeLimit);

    if (acceptedFiles.length) {
      doUpload(params);
    }
  };

  const fileCount = useMemo(
    () =>
      resources.length +
      Object.keys(fileStatusMap.current).filter(
        (key) =>
          fileListIdSet.has(key) && NOT_FINISHED_FILE_STATUSES.includes(fileStatusMap.current[key]),
      ).length,
    [fileListIdSet, resources.length],
  );

  const onRemove = async (file) => {
    if (fileStatusMap.current[file.uid]) {
      fileStatusMap.current[file.uid] = FileStatus.REMOVED;
    }

    const newResources = resources.filter((item) => {
      return item.storageId !== (file.storageId || file.response?.storageId);
    });

    if (newResources.length !== resources.length) {
      await onChange({ resources: newResources });
      resourceList.current = newResources;
      if (onTouchChanged) {
        onTouchChanged();
      }
    }
  };
  const onPreview = (file) => {
    let url = file.url || file.response?.url || '';
    if (!url.startsWith('http')) {
      url = window.location.origin + url;
    }
    if (listType === 'picture-card') {
      setPreviewVisible(true);
      setPreviewImage(url);
    } else if (useOnlinePreview) {
      const filePathname = new URL(url).pathname;
      if (fileIsImage(filePathname)) {
        setPreviewVisible(true);
        setPreviewImage(url);
      } else if (
        // kkfileview服务目前只支持这几种类型
        ['pdf', 'doc', 'docx', 'xls', 'xlsx'].includes(filePathname.split('.').pop().toLowerCase())
      ) {
        let previewUrl;
        if (url.includes('/fss/api/public/')) {
          // /fss/api file url
          previewUrl = url.replace('/fss/api/public/', '/kkfileview/onlinePreview/fss/');
        } else {
          // other file url
          previewUrl = `/kkfileview/onlinePreview?url=${encodeURIComponent(btoa(url))}`;
        }
        if (onlinePreviewUseIframe) {
          setKkfileviewUrl(previewUrl);
          return;
        }
        window.open(previewUrl);
      } else {
        window.open(url);
      }
    } else {
      window.open(url);
    }
  };

  const handleClosePreview = () => {
    setPreviewVisible(false);
    setPreviewImage('');
  };

  const uploadButton = useCallback(() => {
    const countText = `( ${fileCount} / ${maxCount} )`;
    if (listType === 'text') {
      return (
        <div className="upload-button upload-button-text">
          <Button icon={<Icon name="oct:upload" size={16} />} {...buttonProps}>
            {uploadTitle}
            {subTitle && <span className="upload-button-subtitle">{subTitle}</span>}
          </Button>
          {showCount && <span className="upload-button-count">{countText}</span>}
        </div>
      );
    }

    if (fileCount >= maxCount) {
      return null;
    }

    return (
      <div className="upload-button upload-button-image">
        <div className="upload-button-title">{uploadTitle}</div>
        <Icon className="upload-button-icon" name="oct:plus" size={16} />
        <span className="upload-button-count">{showCount ? countText : null}</span>
      </div>
    );
  }, [listType, fileCount, maxCount, uploadTitle, buttonProps, subTitle, showCount]);

  const imgItemRender = (originNode, file, fl, actions) => {
    const url = file.url || file.response?.url || file.thumbUrl || imgThumbCache.current[file.uid];
    const isUploading = fileStatusMap.current[file.uid] === FileStatus.UPLOADING;
    const isFailed = fileStatusMap.current[file.uid] === FileStatus.FAILED;
    if (file.thumbUrl) {
      imgThumbCache.current[file.uid] = file.thumbUrl;
    }

    return (
      <div
        className={clsx('upload-item-render', { uploading: isUploading, failed: isFailed })}
        onClick={!isUploading ? () => onPreview(file) : null}
      >
        <img alt={file.name} src={url} />
        {!isUploading ? <Icon className="eye-icon" name="ant:eye-outlined" size={16} /> : null}
        {isUploading ? (
          <Progress
            className="uploading-progress"
            size={['100%', 2]}
            percent={file.percent}
            showInfo={false}
          />
        ) : null}
        {!(disabled || readonly) && (
          <span
            className="upload-item-remove-icon"
            onClick={(event) => {
              event.stopPropagation();
              actions.remove(file);
            }}
          >
            <Icon name="oct:x-circle" size={16} />
          </span>
        )}
      </div>
    );
  };
  const escKeyHandle = (e) => {
    if (e.key === 'Escape' && previewVisible) {
      handleClosePreview();
    }
  };
  useEffect(() => {
    if (previewVisible) {
      window.addEventListener('keydown', escKeyHandle);
    }
    return () => window.removeEventListener('keydown', escKeyHandle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewVisible]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'upload-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        <div>
          <Upload
            {...componentProps}
            className={classNameComp}
            name={id}
            accept={accept}
            fileList={fileList}
            listType={listType}
            disabled={disabled}
            multiple={multiple}
            maxCount={maxCount}
            onChange={handleChange}
            customRequest={customRequest}
            onRemove={onRemove}
            onPreview={onPreview}
            itemRender={listType === 'picture-card' ? imgItemRender : undefined}
            showUploadList={{ showRemoveIcon: !readonly }}
          >
            {!readonly && maxCount > fileCount ? uploadButton() : null}
          </Upload>
          <FormHelperText helpers={helpers} />
        </div>
        <ImageViewer
          visible={previewVisible}
          image={previewImage}
          onClose={handleClosePreview}
          getContainer={document.body}
        />
        {kkfileviewUrl ? (
          <Modal
            className="upload-preview-modal"
            open={true}
            centered={true}
            destroyOnClose={true}
            footer={null}
            onCancel={() => setKkfileviewUrl('')}
            width="100%"
          >
            <iframe
              style={{ width: '100%', height: '100%', border: 'none', verticalAlign: 'bottom' }}
              title="preview"
              src={kkfileviewUrl}
              frameBorder="0"
            />
          </Modal>
        ) : null}
      </div>
    </ElementDecorator>
  );
});

UploadElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.shape({
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        storageId: PropTypes.string,
        fileName: PropTypes.string,
        url: PropTypes.string,
        // authorization: PropTypes.string,
      }),
    ),
  }),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 上传组件提供的默认样式
     */
    listType: PropTypes.oneOf(['picture-card', 'text']),
    /**
     * 上传按钮的文字标题
     */
    uploadTitle: PropTypes.string,
    /**
     * 上传按钮的文字副标题
     */
    subTitle: PropTypes.string,
    /**
     * 上传文件的最大数
     */
    maxCount: PropTypes.number,
    /**
     * 上传文件接受的 mimetype
     */
    accept: PropTypes.string,
    /**
     * 上传文件的尺寸限制
     */
    sizeLimit: PropTypes.number,
    /**
     * 是否支持多文件上传
     * @default false
     */
    multiple: PropTypes.bool,
    /**
     * 上传按钮的属性
     */
    buttonProps: PropTypes.shape({ type: PropTypes.string }),
    /**
     * 使用在线预览服务进行预览，否则调用浏览器默认打开链接的行为
     */
    useOnlinePreview: PropTypes.bool,
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
UploadElement.displayName = 'Upload';

export default withFieldWrapper(UploadElement);
