import PropTypes from 'prop-types';
import { ensureDisplayText } from '@icp/utils';
import { formatNumber } from '@icp/i18n';
import { InputNumber } from 'antd';
import { HELPER_TEXT_TYPES } from '@icp/form-renderer-core';
import FieldTitle from '../../FieldTitle';
import FormHelperText from '../../FormHelperText';

function NumberPickerAnt(props) {
  const {
    id,
    title,
    value,
    disabled,
    readonly,
    componentProps,
    fieldTitleProps,
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,

    min,
    max,
    step,
    precision,
    formatOptions,
  } = props;

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        {readonly ? (
          <span className="readonly-text">
            {ensureDisplayText(
              formatNumber(value, {
                ...(precision != null && {
                  maximumFractionDigits: precision,
                  minimumFractionDigits: precision,
                }),
                ...formatOptions,
              }),
            )}
          </span>
        ) : (
          <InputNumber
            {...componentProps}
            name={id}
            value={typeof value === 'string' ? +value : value}
            min={min}
            max={max}
            step={step}
            precision={precision}
            disabled={disabled}
            onChange={onChange}
            onBlur={onTouchChanged}
            status={status}
            type="number"
          />
        )}
        <FormHelperText helpers={helpers} />
      </div>
    </>
  );
}

NumberPickerAnt.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  componentProps: PropTypes.shape({}),
  fieldTitleProps: PropTypes.shape({}),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  precision: PropTypes.number,
  formatOptions: PropTypes.shape({
    // Intl.NumberFormat的属性，支持冒号语法引用表单数据
    currency: PropTypes.string,
  }),
};

export default NumberPickerAnt;
