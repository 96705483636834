import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tooltip } from 'antd';
import { Icon } from '@icp/components';

function FieldTitle(props) {
  const { children, style, showColon, tooltip } = props;

  if (!children) {
    return null;
  }

  return (
    <label
      className={clsx(
        'field-title',
        showColon === true && 'field-title-colon',
        showColon === false && 'field-title-no-colon',
      )}
      style={style}
    >
      <span className="field-title-text">{children}</span>
      {tooltip ? (
        <Tooltip title={tooltip} placement="right">
          <span className="field-title-tooltip">
            <Icon name="oct:info" size={14} />
          </span>
        </Tooltip>
      ) : null}
    </label>
  );
}

FieldTitle.propTypes = {
  children: PropTypes.string,
  showColon: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default FieldTitle;
