import {
  selectCurrentModel,
  selectMessages,
  selectModelList,
  setCurrentModel,
  //
} from './agentSlice';

export default [
  // 失败的action打印日志
  {
    predicate: (action) => {
      return action.type?.endsWith(`/rejected`);
    },
    effect: (action) => {
      if (action.error?.stack) {
        console.error(action.error.stack);
      } else {
        console.error(action.error);
      }
    },
  },
  // 空时自动选默认模型 (模型列表加载完后模型永不为空)
  {
    predicate: (action, currentState) => {
      return selectCurrentModel(currentState) == null;
    },
    effect: (action, { dispatch, getState }) => {
      const modelList = selectModelList(getState());
      if (modelList?.length > 0) {
        const model = modelList.find((x) => x.default) || modelList[0];
        dispatch(setCurrentModel(model));
      }
    },
  },
  // messageList变化时触发
  {
    predicate: (action, currentState, previousState) => {
      return selectMessages(currentState) !== selectMessages(previousState);
    },
    effect: async (action, { getState, extra }) => {
      if (!extra?.onMessagesChanged) return;
      const messages = selectMessages(getState());
      await extra.onMessagesChanged(messages);
    },
  },
];
