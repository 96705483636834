import { useRef } from 'react';
import { EventService, extractPropsEvents } from '@icp/form-renderer-core';

// 通过实现同时触发通过 jsx 绑定的 onXx 事件以及通过 js fieldApi.on('xx') 绑定的事件
export default function useEventService({ otherComponentProps, JS_API_EVENT_TYPES }) {
  const propsEvents = useRef({});

  // 会 mutable 的删除 otherComponentProps 里的 onXx 事件
  extractPropsEvents(propsEvents.current, otherComponentProps, JS_API_EVENT_TYPES);

  return useRef(new EventService(propsEvents.current, JS_API_EVENT_TYPES));
}
