import { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Icon } from '@icp/components';
import { useTranslation } from 'react-i18next';
import { HELPER_TEXT_TYPES } from '@icp/form-renderer-core';
import FormHelperText from '../../FormHelperText';
import FieldTitle from '../../FieldTitle';
import ACL from './ACL';
import { getReadonlyText } from './utils';

function ACLAnt(props) {
  const {
    id,
    title,
    value,
    disabled,
    readonly,
    validation,
    status,
    helpers,
    componentProps = {},
    fieldTitleProps,
    onChange,

    componentLibrary,
    idListUrl,
    dataUrl,
    isStandardDataSource,
    dataResponseKeyPath,
    translateDataResponse,
    outerFilterModel,
    dataExclusion,
    selectColId,
    unit,
    mapping,
    columnDefs,
    multiple,
    placeholder,
    supportImport,
    supportExport,
    stringEqual,
    exportColumns,
    transformKey,
    transformUrl,
    maxSizePerReq,
    AgTableProps,
    ACLElementOriginalProps,
  } = props;

  const { t } = useTranslation(['icp-form-renderer', 'icp-common']);

  const [exportLoading, setExportLoading] = useState(false);

  const aclRef = useRef(null);

  const handleExport = () => {
    setExportLoading(true);
    aclRef.current.export().finally(() => {
      setExportLoading(false);
    });
  };

  const readonlyText = useMemo(() => {
    if (!readonly) {
      return '';
    }
    return getReadonlyText(value);
  }, [readonly, value]);

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        {readonly ? (
          <span className="readonly-text">
            {value?.length ? (
              <>
                <span className="acl-readonly-text" title={readonlyText}>
                  {readonlyText}
                </span>
                {value.length > 1 ? (
                  <span>{t('acl.count', { length: value.length, unit })}</span>
                ) : null}
              </>
            ) : null}
            {supportExport && (
              <Button
                icon={<Icon name="excel" size={16} />}
                onClick={handleExport}
                disabled={disabled}
                loading={exportLoading}
                style={{ marginLeft: 4 }}
              >
                {t('export', { ns: 'icp-common' })}
              </Button>
            )}
          </span>
        ) : null}
        {/* export 调用的是 aclRef.current.export 来进行导出的，所以组件需要渲染，但是 display none 不可见 */}
        {!readonly || supportExport ? (
          <ACL
            {...componentProps}
            componentLibrary={componentLibrary}
            id={id}
            value={value}
            mapping={mapping}
            status={status}
            idListUrl={idListUrl}
            dataUrl={dataUrl}
            isStandardDataSource={isStandardDataSource}
            dataResponseKeyPath={dataResponseKeyPath}
            translateDataResponse={translateDataResponse}
            title={title}
            unit={unit}
            placeholder={placeholder}
            multiple={multiple}
            stringEqual={stringEqual}
            columnDefs={columnDefs}
            outerFilterModel={outerFilterModel}
            dataExclusion={dataExclusion}
            selectColId={selectColId}
            supportImport={supportImport}
            supportExport={supportExport}
            exportColumns={exportColumns}
            transformKey={transformKey}
            transformUrl={transformUrl}
            maxSizePerReq={maxSizePerReq}
            onChange={onChange}
            style={readonly ? { display: 'none' } : componentProps.style}
            disabled={disabled}
            AgTableProps={AgTableProps}
            ACLElementOriginalProps={ACLElementOriginalProps}
            ref={aclRef}
          />
        ) : null}
        <FormHelperText helpers={helpers} />
      </div>
    </>
  );
}

ACLAnt.propTypes = {
  componentLibrary: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  componentProps: PropTypes.shape({
    style: PropTypes.shape({}),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  unit: PropTypes.string,
  mapping: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // Other key map to save
  }),
  idListUrl: PropTypes.string,
  dataUrl: PropTypes.string,
  isStandardDataSource: PropTypes.bool,
  dataResponseKeyPath: PropTypes.string,
  translateDataResponse: PropTypes.bool,
  outerFilterModel: PropTypes.arrayOf(PropTypes.shape({})),
  dataExclusion: PropTypes.arrayOf(PropTypes.string),
  selectColId: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  supportImport: PropTypes.bool,
  maxSizePerReq: PropTypes.number,
  supportExport: PropTypes.bool,
  exportColumns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  transformKey: PropTypes.string,
  transformUrl: PropTypes.string,
  stringEqual: PropTypes.bool,
  AgTableProps: PropTypes.shape({}),
  ACLElementOriginalProps: PropTypes.shape({}),
  onChange: PropTypes.func,
};

export default ACLAnt;
