import { get } from 'lodash-es';
import { fetchSingleDataSource } from '@icp/form-renderer-core';
import {
  excludeData,
  getRowsFromCachedData,
  logDuplicate,
  resolveIdsToFetch,
  setRowsToCachedData,
} from './utils';

class ACLDataSource {
  constructor(props) {
    this.props = props;

    // serverSide cache, rowId => rows detail information
    this.cachedData = new Map();
    // clientSide all rows
    this.cachedAllRowsData = props.staticAllRowsData || null;

    this.fetchAllRowsPromise = null;
  }

  fetchAllIds(request) {
    const { idListUrl, outerFilterModel, dataExclusion, stringEqual } = this.props;

    return fetchSingleDataSource({
      dataUrl: idListUrl,
      filterModel: (request.filterModel || []).concat(outerFilterModel).filter(Boolean),
      searchText: request.searchText,
      sortModel: request.sortModel,
    }).then((allIds) => {
      allIds = excludeData(allIds, dataExclusion);
      return stringEqual ? allIds.map((id) => String(id)) : allIds;
    });
  }

  fetchRowsByIds(ids) {
    const {
      dataUrl,
      translateDataResponse,
      transformDataResponse,
      selectColId,
      stringEqual,
      mapping,
    } = this.props;

    const idsToFetch = resolveIdsToFetch(this.cachedData, ids);

    return Promise.resolve(
      idsToFetch.length
        ? fetchSingleDataSource({
            dataUrl,
            selectColId,
            transformDataResponse,
            translateDataResponse,
            queryParams: {
              ids: String(idsToFetch),
            },
          })
        : [],
    ).then(async (rows) => {
      setTimeout(() => {
        logDuplicate(rows, mapping);
      }, 100);

      setRowsToCachedData(this.cachedData, rows, stringEqual, mapping);

      return getRowsFromCachedData(this.cachedData, ids);
    });
  }

  fetchRowsSlice(request) {
    const {
      dataUrl,
      outerFilterModel,
      dataResponseKeyPath,
      dataExclusion,
      mapping,
      translateDataResponse,
      transformDataResponse,
      selectColId,
      stringEqual,
    } = this.props;

    let count;
    return fetchSingleDataSource({
      ...request,
      dataUrl,
      filterModel: (request.filterModel || []).concat(outerFilterModel).filter(Boolean),
      selectColId,
      dataResponseKeyPath: (res) => {
        count = res.count;
        return dataResponseKeyPath ? get(res, dataResponseKeyPath) : res;
      },
      transformDataResponse,
      translateDataResponse,
    }).then(async (rows) => {
      rows = excludeData(rows, dataExclusion, mapping.value);

      setTimeout(() => {
        logDuplicate(rows, mapping);
      }, 100);

      setRowsToCachedData(this.cachedData, rows, stringEqual, mapping);

      // 使用 ag-grid 的 serverSide 模式必须要返回 count
      return { rowData: rows, rowCount: count };
    });
  }

  fetchAllRows() {
    const {
      dataUrl,
      outerFilterModel,
      dataResponseKeyPath,
      dataExclusion,
      mapping,
      translateDataResponse,
      transformDataResponse,
      stringEqual,
    } = this.props;

    if (this.fetchAllRowsPromise) {
      return this.fetchAllRowsPromise;
    }

    if (this.cachedAllRowsData) {
      return Promise.resolve(this.cachedAllRowsData);
    }

    this.fetchAllRowsPromise = fetchSingleDataSource({
      dataUrl,
      filterModel: outerFilterModel,
      dataResponseKeyPath,
      transformDataResponse,
      translateDataResponse,
    }).then(async (rows) => {
      rows = excludeData(rows, dataExclusion, mapping.value);

      this.cachedAllRowsData = stringEqual
        ? rows.map((item) => {
            // api response, mutable change doesn't matter
            if (item[mapping.value] !== undefined && item[mapping.value] !== null) {
              item[mapping.value] = String(item[mapping.value]);
            }
            return item;
          })
        : rows;

      setTimeout(() => {
        logDuplicate(rows, mapping);
      }, 100);

      return this.cachedAllRowsData;
    });

    return this.fetchAllRowsPromise;
  }
}

export default ACLDataSource;
