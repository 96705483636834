import * as Yup from 'yup';
import { yupToFormErrors, setNestedObjectValues } from 'formik';
import { get, setWith, isEqual } from 'lodash-es';
import { getTFunc, translate } from '@icp/i18n';
import { isValidRegex } from '@icp/utils';
import { validationRulesObj } from './validationUtils';

export { setNestedObjectValues };

function initYup(field) {
  switch (field.component) {
    case 'Input':
    case 'Password':
    case 'DatePicker':
    case 'TimePicker':
    case 'IconUpload':
    case 'CodeEditor':
      return Yup.string().label(field.title);
    case 'NumberPicker':
      return Yup.number().label(field.title);
    case 'ACL':
    case 'Cascader':
      return Yup.array().label(field.title);
    case 'Checkbox':
    case 'Select':
    case 'TreeSelect':
      return Yup.mixed().label(field.title);
    case 'Upload':
      return Yup.object().label(field.title);
    case 'Textarea':
    case 'RichText':
    case 'EditableTable': // TODO, EditableTable 为什么是 object？
      return Yup.object().label(field.title);
    default:
      return Yup.mixed().label(field.title);
  }
}

function inputValidateSchema({ field /* , required */, yup }) {
  const {
    minLength,
    maxLength,
    regex,
    ootb,
    // deprecated
    verifyFieldEqual,
    //
  } = field.validation;

  const t = getTFunc('icp-form-renderer');

  /* if (required) {
    yup = yup.trim();
  } */
  if (minLength) {
    const minLengthErrorMessage =
      translate('minLengthErrorMessage', field.validation) ||
      t('validation.string.minLengthErrorMessage', { minLength });

    yup = yup.min(minLength, minLengthErrorMessage);
  }
  if (maxLength) {
    const maxLengthErrorMessage =
      translate('maxLengthErrorMessage', field.validation) ||
      t('validation.string.maxLengthErrorMessage', { maxLength });

    yup = yup.max(maxLength, maxLengthErrorMessage);
  }
  if (regex) {
    if (isValidRegex(regex)) {
      const regexErrorMessage =
        translate('regexErrorMessage', field.validation) ||
        t('validation.string.regexErrorMessage');

      yup = yup.matches(new RegExp(regex), regexErrorMessage);
    } else {
      console.error(`${regex} is not a valid regex string`);
    }
  }
  if (ootb) {
    const newOotb = Array.isArray(ootb) ? ootb : [ootb];
    newOotb.forEach((validatorName) => {
      const validatorObj = validationRulesObj[validatorName];
      const errMsg = t(`validation.string.ootb.${validatorName}`);

      yup = yup.test(field.id, errMsg, (value) => {
        // Do not validate when no value
        if (value === '' || value === null || value === undefined) {
          return true;
        }
        return validatorObj.validator(value);
      });
    });
  }
  if (verifyFieldEqual?.id) {
    const errMsg =
      translate('message', verifyFieldEqual) || t('validation.string.verifyFieldEqualErrorMessage');

    yup = yup.oneOf([Yup.ref(verifyFieldEqual.id), null], errMsg);
  }
  return yup;
}

function numberValidateSchema({ field, yup }) {
  const { maxValue, minValue } = field.validation;

  const t = getTFunc('icp-form-renderer');

  if (maxValue != null) {
    const maxValueErrorMessage =
      translate('maxValueErrorMessage', field.validation) ||
      t('validation.number.maxValueErrorMessage', { maxValue });

    yup = yup.max(maxValue, maxValueErrorMessage);
  }
  if (minValue != null) {
    const minValueErrorMessage =
      translate('minValueErrorMessage', field.validation) ||
      t('validation.number.minValueErrorMessage', { minValue });

    yup = yup.min(minValue, minValueErrorMessage);
  }

  return yup;
}

function multiValueValidateSchema({ field, required, requiredErrorMessage, yup }) {
  const { minLength = required ? 1 : null, maxLength, length } = field.validation;

  const t = getTFunc('icp-form-renderer');

  if (minLength != null) {
    const minLengthErrorMessage =
      (required && minLength === 1 && requiredErrorMessage) ||
      translate('minLengthErrorMessage', field.validation) ||
      t('validation.array.minLengthErrorMessage', { minLength });

    yup = yup.min(minLength, minLengthErrorMessage);
  }
  if (maxLength != null) {
    const maxLengthErrorMessage =
      translate('maxLengthErrorMessage', field.validation) ||
      t('validation.array.maxLengthErrorMessage', { maxLength });

    yup = yup.max(maxLength, maxLengthErrorMessage);
  }
  if (length != null) {
    const lengthErrorMessage =
      translate('lengthErrorMessage', field.validation) ||
      t('validation.array.lengthErrorMessage', { length });

    yup = yup.length(length, lengthErrorMessage);
  }

  return yup;
}

function selectValidateSchema({ field, required, requiredErrorMessage, yup }) {
  const { valueType } = field.componentProps || {};
  const {
    minLength = required ? 1 : null,
    maxLength,
    length,
    //
  } = field.validation;

  const t = getTFunc('icp-form-renderer');

  // only validate length for array
  if (valueType && valueType !== 'valueAlwaysArray' && valueType !== 'itemAlwaysArray') {
    return yup;
  }

  if (minLength != null) {
    const minLengthErrorMessage =
      (required && minLength === 1 && requiredErrorMessage) ||
      translate('minLengthErrorMessage', field.validation) ||
      t('validation.array.minLengthErrorMessage', { minLength });

    yup = yup.test(
      'Select_MinLength',
      minLengthErrorMessage,
      (value) => value?.length >= minLength,
    );
  }
  if (maxLength != null) {
    const maxLengthErrorMessage =
      translate('maxLengthErrorMessage', field.validation) ||
      t('validation.array.maxLengthErrorMessage', { maxLength });

    yup = yup.test(
      'Select_MaxLength',
      maxLengthErrorMessage,
      (value) => !value || value.length <= maxLength,
    );
  }
  if (length != null) {
    const lengthErrorMessage =
      translate('lengthErrorMessage', field.validation) ||
      t('validation.array.lengthErrorMessage', { length });

    yup = yup.test('Select_Length', lengthErrorMessage, (value) => value?.length === length);
  }

  return yup;
}

function textareaValidateSchema({ required, requiredErrorMessage, yup }) {
  if (required) {
    yup = yup.test(
      'Textarea_required',
      requiredErrorMessage,
      (value) => value?.longText?.trim?.()?.length > 0,
    );
  }

  return yup;
}

function uploadValidateSchema({ field, required, requiredErrorMessage, yup }) {
  const { minLength = required ? 1 : null } = field.validation;

  const t = getTFunc('icp-form-renderer');

  if (minLength != null) {
    const minLengthErrorMessage =
      (required && minLength === 1 && requiredErrorMessage) ||
      translate('minLengthErrorMessage', field.validation) ||
      t('validation.array.minLengthErrorMessage', { minLength });

    yup = yup.test(
      'Upload_resources_min',
      minLengthErrorMessage,
      (value) => value?.resources?.length >= minLength,
    );
  }

  return yup;
}

export function richTextValidateSchema({ field, required, requiredErrorMessage, yup }) {
  if (required) {
    yup = yup.test('RichText_required', requiredErrorMessage, (value, ctx) => {
      const { validatorDelegates } = ctx.options.context.store.formData;
      const validator = get(validatorDelegates, field.id);
      try {
        return !validator?.isEmpty(value);
      } catch (err) {
        return ctx.createError({ path: ctx.path, message: err.message });
      }
    });
  }

  return yup;
}

function editableTableValidateSchema({ field, required, requiredErrorMessage, yup }) {
  if (required) {
    yup = yup.test('EditableTable_required', requiredErrorMessage, (value, ctx) => {
      const { validatorDelegates } = ctx.options.context.store.formData;
      const validator = get(validatorDelegates, field.id);
      try {
        return !validator?.isEmpty(value);
      } catch (err) {
        return ctx.createError({ message: err.message });
      }
    });
  }

  const t = getTFunc('icp-form-renderer');

  yup = yup.test('EditableTable_data_validation', t('validation.dataIsInvalid'), (value, ctx) => {
    const { validatorDelegates } = ctx.options.context.store.formData;
    const validator = get(validatorDelegates, field.id);
    try {
      return validator?.isDataValid(value);
    } catch (err) {
      return ctx.createError({ message: err.message });
    }
  });

  return yup;
}

const isBooleanValueCheckbox = (field) => {
  if (field.component !== 'Checkbox') return false;
  const { childEnum, options: optionsProp } = field.componentProps || {};
  const options = optionsProp || childEnum;
  return !options;
};

function fieldSchemaBuilder(field, yup) {
  if (isBooleanValueCheckbox(field)) return yup;

  const { required, equalToField, notEqualToField } = field.validation;

  const t = getTFunc('icp-form-renderer');

  const requiredErrorMessage =
    translate('requiredErrorMessage', field.validation) ||
    t('validation.requiredErrorMessage', { fieldTitle: field.title });

  if (required && field.component !== 'EditableTable') {
    yup = yup.required(requiredErrorMessage).nullable();
  } else {
    yup = yup.optional().nullable();
  }

  if (equalToField && field.component !== 'EditableTable') {
    const equalToFieldErrorMessage =
      translate('equalToFieldErrorMessage', field.validation) ||
      t('validation.equalToFieldErrorMessage', { fieldTitle: field.title });

    yup = yup.when(equalToField, (targetValue, schema) =>
      schema.test('Generic_equalToField', equalToFieldErrorMessage, (value) =>
        isEqual(value, targetValue),
      ),
    );
  }

  if (notEqualToField && field.component !== 'EditableTable') {
    const notEqualToFieldErrorMessage =
      translate('notEqualToFieldErrorMessage', field.validation) ||
      t('validation.notEqualToFieldErrorMessage', { fieldTitle: field.title });

    yup = yup.when(notEqualToField, (targetValue, schema) =>
      schema.test(
        'Generic_notEqualToField',
        notEqualToFieldErrorMessage,
        (value) => !isEqual(value, targetValue),
      ),
    );
  }

  switch (field.component) {
    case 'Input':
    case 'Password': {
      return inputValidateSchema({ field, required, yup });
    }
    case 'NumberPicker': {
      return numberValidateSchema({ field, yup });
    }
    case 'Cascader':
    case 'ACL': {
      return multiValueValidateSchema({ field, required, requiredErrorMessage, yup });
    }
    case 'Checkbox':
    case 'Select':
    case 'TreeSelect': {
      return selectValidateSchema({ field, required, requiredErrorMessage, yup });
    }
    case 'Upload': {
      return uploadValidateSchema({ field, required, requiredErrorMessage, yup });
    }
    case 'Textarea': {
      return textareaValidateSchema({ required, requiredErrorMessage, yup });
    }
    case 'RichText': {
      return richTextValidateSchema({
        field,
        required,
        requiredErrorMessage,
        yup,
      });
    }
    case 'EditableTable': {
      return editableTableValidateSchema({
        field,
        required,
        requiredErrorMessage,
        yup,
      });
    }
    default:
      break;
  }

  return yup;
}

export function makeYupSchema(validations) {
  return Yup.lazy((formData, ctx) => {
    const validatorDelegates = ctx?.context?.store?.formData?.validatorDelegates || {};
    const validationsFromDelegates = extractValidationsFromDelegates(validatorDelegates);

    const finalValidations = {};
    (validations || []).forEach((x) => {
      finalValidations[x.id] = x;
    });
    // 代理优先
    validationsFromDelegates.forEach((x) => {
      finalValidations[x.id] = x;
    });
    return internalMakeYupSchema(Object.values(finalValidations)) || Yup.object({}).nullable();
  });
}

function internalMakeYupSchema(validations) {
  if (!validations?.length) {
    return null;
  }

  const obj = {};

  for (const field of validations) {
    if (
      field.hidden === true ||
      (field.readonly === true && !field.forceValidation) ||
      (field.disabled === true && !field.forceValidation)
    ) {
      continue;
    }

    let yup = initYup(field);

    if (
      typeof field.hidden === 'object' ||
      typeof field.readonly === 'object' ||
      typeof field.disabled === 'object'
    ) {
      yup = yup.when('$conditionalPropertyResolver', (conditionalPropertyResolver, yupSchema) => {
        const hidden = conditionalPropertyResolver(field.hidden);
        const readonly = conditionalPropertyResolver(field.readonly);
        const disabled = conditionalPropertyResolver(field.disabled);

        if (
          hidden ||
          (readonly && !field.forceValidation) ||
          (disabled && !field.forceValidation)
        ) {
          return yupSchema.nullable();
        }

        return fieldSchemaBuilder(field, yupSchema);
      });
    } else {
      yup = fieldSchemaBuilder(field, yup);
    }

    setWith(obj, field.id, yup, Object);
  }

  const schemafyNestedObj = (o) => {
    for (const [k, v] of Object.entries(o)) {
      if (!Yup.isSchema(v)) {
        o[k] = Yup.object(schemafyNestedObj(v));
      }
    }
    return o;
  };

  schemafyNestedObj(obj);

  return Yup.object(obj);
}

function extractValidationsFromDelegates(delegates) {
  return Object.values(delegates)
    .map((obj) => {
      if (!obj || typeof obj !== 'object') return null;

      const validation = obj[Symbol.for('validation')];
      // validation key length 对应普通字段配置的 field.validation 验证
      // obj key length 对应 EditableTable 这种自定义了 isDataValid() isEmpty() 的验证
      if (Object.keys(validation?.validation || {}).length || Object.keys(obj).length) {
        return validation;
      }

      return null;
    })
    .filter(Boolean);
}

export function runValidationSchema(values, yupSchema, conditionalPropertyResolver, ctx, field) {
  if (!yupSchema) return Promise.resolve(null);

  const context = {
    ...ctx,
    values,
    conditionalPropertyResolver,
  };

  if (field) {
    try {
      Yup.reach(yupSchema, field, values, context);
    } catch {
      // cannot reach
      return Promise.resolve(null);
    }
  }

  const promise = field
    ? yupSchema.validateAt(field, values, { context })
    : yupSchema.validate(values, { context, abortEarly: false });

  return promise.then(
    () => null,
    (err) => {
      if (err.name === 'ValidationError') {
        return yupToFormErrors(err);
      }
      console.error('Fatal Error: non-ValidationError during form validation');
      throw err;
    },
  );
}
