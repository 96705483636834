import { useCallback, useLayoutEffect, useRef } from 'react';

export default function useEventCallback(func) {
  const ref = useRef(null);

  useLayoutEffect(() => {
    ref.current = func;
  });

  return useCallback(function callback(...args) {
    return ref.current.apply(this, args);
  }, []);
}
