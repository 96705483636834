import PropTypes from 'prop-types';
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { ConfigProvider as AntConfigProvider, message as antMessage } from 'antd';
import { setupMessage, restApi } from '@icp/settings';
import { I18nextProvider } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { initAllConfig } from '@icp/app-core';
import { RouterProvider } from 'react-router-dom';
import { Loading } from '@icp/components';
import { useEventCallback } from '@icp/hooks';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { AppContextProvider } from './appCtx';
import useTheme from './useTheme';
import makeIcpRouter from './router/makeIcpRouter';
import NoticeBoard from './components/NoticeBoard';

function useContextRef(contextProp) {
  // 为了解决项目 App.js 登陆前 context 里的 userPermissionMap, userProfile 无值，而登陆过后马上有值导致的
  // IcpApp context 一定会多改变一次导致的 makeIcpRouter 一定会多运行一次的问题，这里使用 ref 来简单代理一下 context
  // 的属性的访问。所以在 makeIcpRouter 里的任何函数不能去解构 { ...context } 来使用
  const context = useRef({});
  if (contextProp) {
    for (const [key, value] of Object.entries(contextProp)) {
      context.current[key] = value;
    }
  }
  return context;
}

function IcpApp(props) {
  const { context: contextProp, otherRoutes, AuthWrapper, onLocationChanged, children } = props;

  const [config, setConfig] = useState(null);
  const { i18n, antdLocale, appConfig, appRoutes, pbcList } = config || {};

  const context = useContextRef(contextProp);
  const { antdTheme, materialTheme } = useTheme(appConfig);

  const msgDuration = config?.appConfig?.messageConfig?.duration;
  const msgOptions = msgDuration && msgDuration > 0 ? { duration: msgDuration } : undefined;
  const [message, messageContextHolder] = antMessage.useMessage(msgOptions);
  setupMessage(message);

  useEffect(() => {
    initAllConfig().then(setConfig);
  }, []);

  const reportUsageOnLocationChanged = useEventCallback(() => {
    if (!context.current.userProfile) return;
    restApi.post(
      '/user-management/api/user/usage',
      {
        url: window.location.href,
      },
      {
        skipResponseInterceptors: true,
      },
    );
  });

  // 保证每次调用的 onLocationChanged 是最新的
  const handleLocationChanged = useEventCallback((...args) => {
    reportUsageOnLocationChanged();
    if (onLocationChanged) {
      onLocationChanged(...args);
    }
  });

  const router = useMemo(
    () => {
      if (!config) {
        return null;
      }
      return makeIcpRouter({
        AuthWrapper,
        appConfig,
        appRoutes,
        otherRoutes,
        pbcList,
        context: context.current,
        onLocationChanged: handleLocationChanged,
        appChildren: children,
      });
    },
    // router config 只需要构建一次，不需要响应 config 意外的属性变化
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config, children],
  );

  if (!config) {
    // TODO，是不是应该 APP 的 loading 放到 html 里，然后在 config 和 template 加载完过后去清楚掉,
    // 否则当前状态是 app loading  template loading authing loading 会闪两下
    return (
      <div className="app-loading-wrapper">
        <Loading />
      </div>
    );
  }

  return (
    <I18nextProvider i18n={i18n}>
      <MaterialThemeProvider theme={materialTheme}>
        <AntConfigProvider locale={antdLocale} theme={antdTheme}>
          <Suspense fallback={null}>
            <AppContextProvider pbcList={pbcList} contextFromAppProp={context.current}>
              <Helmet>
                <title>{appConfig.title}</title>
                <meta name="description" content={appConfig.description} />
              </Helmet>
              <NoticeBoard />
              {messageContextHolder}
              <RouterProvider router={router} />
            </AppContextProvider>
          </Suspense>
        </AntConfigProvider>
      </MaterialThemeProvider>
    </I18nextProvider>
  );
}

IcpApp.propTypes = {
  context: PropTypes.shape({
    userPermissionMap: PropTypes.shape({}),
    userProfile: PropTypes.shape({}),
  }),
  otherRoutes: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.shape({
        noNeedAuth: PropTypes.bool,
      }),
    ),
  ]),
  AuthWrapper: PropTypes.func,
  onLocationChanged: PropTypes.func,
  children: PropTypes.node,
};

export default IcpApp;
