import { forwardRef, useImperativeHandle, useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { getSearchParam, updateSearchParam } from '@icp/utils';
import RecursionRenderer from '../RecursionRenderer';
import { useClassName, useConditionalPropertyForItemOfArray } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { ConditionalPropertyPropType } from '../propTypes';

const TabsElement = forwardRef(function TabsElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    style,
    fields: fieldsProp,
    componentProps,
  } = props;
  const {
    defaultActiveKey: defaultActiveKeyProp,
    tabNames,
    tabItemProps,
    items: itemsProp,
    showUnderLine = false,
    suppressUrlSearchStorage = false,
    ...otherComponentProps
  } = componentProps || {};

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  useImperativeHandle(
    ref,
    () => ({
      node: null,
    }),
    [],
  );

  const defaultActiveKey = suppressUrlSearchStorage
    ? defaultActiveKeyProp
    : getSearchParam('activeTab') || defaultActiveKeyProp;

  // legacy support
  const itemsMerged = useMemo(() => {
    if (Array.isArray(itemsProp)) {
      return itemsProp;
    }

    if (tabNames) {
      return Object.keys(tabNames).map((key) => {
        return {
          ...tabItemProps?.[key],
          key,
          label: tabNames[key],
          fields: [fieldsProp.find((info) => info.id === key)].filter(Boolean),
        };
      });
    }

    return [];
  }, [fieldsProp, itemsProp, tabItemProps, tabNames]);

  const items = useConditionalPropertyForItemOfArray(itemsMerged, 'hidden');

  const handleChange = (newTab) => {
    if (!suppressUrlSearchStorage) {
      updateSearchParam('activeTab', newTab);
    }
    if (componentProps.onChange) {
      componentProps.onChange(newTab);
    }
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <Tabs
        key={isInDesign ? `${defaultActiveKey}` : undefined}
        defaultActiveKey={defaultActiveKey}
        items={items
          .filter((item) => !item.hidden)
          .map((item, itemIndex) => {
            const { key, label, fields, children, ...other } = item;
            return {
              ...other,
              key,
              label,
              children: children || (
                <RecursionRenderer
                  keyPath={(keyPath || []).concat('componentProps', 'items', itemIndex, 'fields')}
                  fields={fields}
                />
              ),
            };
          })}
        {...otherComponentProps}
        className={clsx(
          'tabs-element form-element',
          { 'show-underline': showUnderLine },
          className,
          classNameComp,
        )}
        style={{ ...style, ...componentProps.style }}
        onChange={handleChange}
      />
    </ElementDecorator>
  );
});

TabsElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  /**
   * @deprecated
   */
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * @deprecated
     */
    tabNames: PropTypes.shape({}),
    /**
     * @deprecated
     */
    tabItemProps: PropTypes.shape({}),
    /**
     * 默认打开的 tab
     */
    defaultActiveKey: PropTypes.string,
    /**
     * 标签组的 items
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        fields: PropTypes.arrayOf(PropTypes.shape({})),
        // code generator 会将 fields 转换为 children
        children: PropTypes.node,
        hidden: ConditionalPropertyPropType(PropTypes.bool),
      }),
    ),
    /**
     * 是否显示下划线
     */
    showUnderLine: PropTypes.bool,
    /**
     * 是否不要在地址栏中存储状态
     */
    suppressUrlSearchStorage: PropTypes.bool,
    onChange: PropTypes.func,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
TabsElement.displayName = 'Tabs';

export default withFieldWrapper(TabsElement);
