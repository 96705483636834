import { Empty, TreeSelect } from 'antd';
import { Loading } from '@icp/components';
import PropTypes from 'prop-types';
import { HELPER_TEXT_TYPES } from '@icp/form-renderer-core';
import FieldTitle from '../../FieldTitle';
import FormHelperText from '../../FormHelperText';

function TreeSelectAnt(props) {
  const {
    title,
    value,
    disabled,
    componentProps,
    fieldTitleProps,
    validation,
    readonly,
    status,
    helpers,
    onChange,
    onTouchChanged,

    multiple,
    mapping,
    readonlyText,
    loading,
    options,

    treeLazyLoading,
    loadData,
    showSearch,
    onSearch,
  } = props;

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        {readonly ? (
          <span className="readonly-text">{readonlyText}</span>
        ) : (
          <TreeSelect
            filterTreeNode={!treeLazyLoading}
            treeNodeFilterProp={mapping.label}
            loadData={loadData}
            showSearch={showSearch}
            {...componentProps}
            notFoundContent={
              loading ? (
                <Loading size={14} delayed={false} style={{ height: 56 }} />
              ) : (
                componentProps.notFoundContent || (
                  <Empty className="ant-empty-small" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              )
            }
            value={value}
            treeData={options}
            loading={loading}
            fieldNames={mapping}
            multiple={multiple}
            disabled={disabled}
            status={status}
            onChange={onChange}
            onBlur={onTouchChanged}
            onSearch={onSearch}
          />
        )}
        <FormHelperText helpers={helpers} />
      </div>
    </>
  );
}

TreeSelectAnt.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      }),
    ),
  ]),
  componentProps: PropTypes.shape({
    notFoundContent: PropTypes.node,
  }),
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    // Other key map to save
  }),
  multiple: PropTypes.bool,
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
  readonlyText: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  treeLazyLoading: PropTypes.bool,
  loadData: PropTypes.func,
  showSearch: PropTypes.bool,
  onSearch: PropTypes.func,
};

export default TreeSelectAnt;
