import PropTypes from 'prop-types';
import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  selectPermissionsByCategory,
  fetchPermissionList,
  selectContext,
  HELPER_TEXT_TYPES,
  helpersIsEmpty,
  buildPermissionCategoryKey,
} from '@icp/form-renderer-core';
import { resolveVariablePattern } from '@icp/utils';
import { useTranslation } from 'react-i18next';
import { Icon, Loading } from '@icp/components';
import { Segmented } from 'antd';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useChangeLanguage } from '@icp/i18n';
import { useEventCallback } from '@icp/hooks';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector, useStore } from '../../store';
import { useComponentLibrary, useElementDecorator, useIsInDesign } from '../../FormRenderCtx';
import FieldTitle from '../../FieldTitle';
import FormHelperText from '../../FormHelperText';
import { useClassName } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import { ButtonUI, TableElement } from '../index';
import PermissionsCellEditor from './PermissionsCellEditor';

const PermissionsElement = forwardRef(function PermissionsElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value,
    style,
    disabled,
    readonly,
    componentProps = {},
    fieldTitleProps,
    validation,
    onChange,
    helpers,
  } = props;

  const { style: compStyle, dataUrl: urlPattern, dataResponseKeyPath } = componentProps;

  const { t } = useTranslation(['icp-form-renderer']);
  const ElementDecorator = useElementDecorator();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.permission.isLoading);
  const systemPermissionsByCategory = useSelector(selectPermissionsByCategory);

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const permissions = value || [];
  const permissionIds = permissions.map((perm) => perm.id);

  const store = useStore();
  const context = selectContext(store.getState());
  const dataUrl = resolveVariablePattern({ context, pattern: urlPattern });
  const isInDesign = useIsInDesign();

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  useEffect(() => {
    if (isInDesign) {
      return () => {};
    }
    const p = dispatch(fetchPermissionList({ dataUrl, dataResponseKeyPath }));

    return () => {
      p.abort();
    };
  }, [dispatch, dataUrl, dataResponseKeyPath, isInDesign]);

  const [language] = useChangeLanguage();
  const componentLibrary = useComponentLibrary();
  const editable = !disabled && !readonly && !isInDesign;
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [showAll, setShowAll] = useState(editable && permissions.length === 0);
  const grantedPermissionsColumnWidth = useRef(null);
  const idMap = useRef(new Map());
  const allSelectedMap = useRef(new Map());

  const updateIdWhenAllSelected = (category, grantedPermissions) => {
    const wasAllSelected = allSelectedMap.current.get(category.key) === true;
    const isAllSelected = grantedPermissions.length === category.permissions.length;
    allSelectedMap.current.set(category.key, isAllSelected);
    // 点击 全选按钮，如果不更新id无法立刻刷新UI，所以当数据从非全选变成全选，更新一下id
    if (!wasAllSelected && isAllSelected) {
      idMap.current.set(category.key, uuidv4());
    }
  };

  const getRowId = (category) => {
    if (!idMap.current.has(category.key)) {
      idMap.current.set(category.key, uuidv4());
    }
    return idMap.current.get(category.key);
  };

  const allRowData = useMemo(
    () =>
      systemPermissionsByCategory.map((category) => {
        const grantedPermissions = category.permissions.filter((perm) =>
          permissionIds.includes(perm.id),
        );
        updateIdWhenAllSelected(category, grantedPermissions);
        return {
          ...category,
          id: getRowId(category),
          grantedPermissions,
        };
      }),
    [permissionIds, systemPermissionsByCategory],
  );

  const permissionsRowData = useMemo(() => {
    return showAll
      ? allRowData
      : allRowData.filter((category) => selectedCategories?.includes(category.key) || false);
  }, [allRowData, selectedCategories, showAll]);

  useEffect(() => {
    if (selectedCategories === null && allRowData.length > 0) {
      setSelectedCategories(
        allRowData
          .filter((category) => category.grantedPermissions.length > 0)
          .map((category) => category.key),
      );
    }
  }, [selectedCategories, allRowData]);

  const handleChange = useEventCallback(({ data, values }) => {
    // 这是针对单个 category(target) 的更新，data 是当前行 category 的数据，values 是当前行已选的权限集合。
    // newPermissions 是当前所有已选权限的集合：从之前的权限集合把当前行的权限都过滤掉，再重新加上当前行的已选权限。
    const newPermissions = permissions
      .filter((perm) => perm.target !== data.categoryName || perm.pbcToken !== data.pbcToken)
      .concat(data.permissions.filter((perm) => values.includes(perm.id)));
    const newSelectedCategories = [...new Set(newPermissions.map(buildPermissionCategoryKey))];
    if (showAll) {
      setSelectedCategories(newSelectedCategories);
    } else {
      // 在已选状态下，如果某一个权限目标下的授予权限清空，该行不会马上消失。
      const newAddedCategories = newSelectedCategories.filter(
        (categoryKey) => !selectedCategories.includes(categoryKey),
      );
      setSelectedCategories(selectedCategories.concat(newAddedCategories));
    }
    onChange?.(newPermissions);
  });

  const handleSelectAll = useEventCallback(() => {
    const allPermissions = allRowData.flatMap((rowData) => rowData.permissions);
    onChange?.(allPermissions);
    setSelectedCategories(allRowData.map((category) => category.key));
  });

  const toolbarChildren = useMemo(() => {
    const selectedModeToggle =
      componentLibrary === 'material-ui' ? (
        <ToggleButtonGroup
          color="primary"
          value={showAll}
          exclusive={true}
          size="small"
          onChange={(e, newValue) => setShowAll(newValue)}
        >
          <ToggleButton value={false} style={{ gap: 4 }}>
            <Icon name="material:checklist-rounded" />
            {t('permissions.selected')}
          </ToggleButton>
          <ToggleButton value={true} style={{ gap: 4 }}>
            <Icon name="material:menu-rounded" />
            {t('permissions.all')}
          </ToggleButton>
        </ToggleButtonGroup>
      ) : (
        <Segmented
          value={showAll}
          onChange={setShowAll}
          options={[
            {
              icon: <Icon name="material:checklist-rounded" />,
              label: t('permissions.selected'),
              value: false,
            },
            {
              icon: <Icon name="material:menu-rounded" />,
              label: t('permissions.all'),
              value: true,
            },
          ]}
        />
      );
    return (
      <div className="permissions-element-table-toolbar">
        {showAll && (
          <ButtonUI
            icon="material:done-all-rounded"
            disabled={isInDesign}
            onClick={handleSelectAll}
          >
            {t('permissions.select-all')}
          </ButtonUI>
        )}
        {selectedModeToggle}
      </div>
    );
  }, [componentLibrary, handleSelectAll, isInDesign, showAll, t]);

  const columnDefs = useMemo(
    () => [
      {
        colId: 'pbcToken',
        field: 'pbcToken',
        headerName: 'PBC Token',
        enableRowGroup: true,
        rowGroup: true,
        hide: true,
      },
      {
        colId: 'categoryName',
        field: 'categoryName',
        headerName: t('permissions.target'),
        flex: 1,
        lockVisible: true,
        cellStyle: { lineHeight: '1.5' },
      },
      {
        colId: 'grantedPermissions',
        field: 'grantedPermissions',
        headerName: t('permissions.granted'),
        flex: 2,
        lockVisible: true,
        wrapText: !editable,
        cellStyle: { lineHeight: '1.5' },
        cellRenderer: (params) =>
          editable ? (
            <PermissionsCellEditor {...params} onChange={handleChange} />
          ) : (
            params.data.grantedPermissions
              .map((perm) => `${perm.name}${perm.customScope ? `(${perm.customScope})` : ''}`)
              .join(', ')
          ),
      },
    ],
    [editable, handleChange, t],
  );

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'input-element',
          'form-element',
          'permissions-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
          classNameComp,
        )}
        style={{ ...style, ...compStyle }}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        {isLoading ? (
          <Loading />
        ) : (
          <TableElement
            noElementDecorator={true}
            keyPath={keyPath}
            componentProps={{
              getRowStyle: (params) => (params.node.group ? { fontWeight: 'bold' } : {}),
              groupDefaultExpanded: 1,
              groupDisplayType: 'groupRows',
              suppressAddButton: true,
              suppressExcelExport: true,
              suppressToolbarRefresh: true,
              suppressFilterPanel: true,
              fuzzySearchPlaceholder: t('permissions.search-placeholder'),
              toolbarChildren: editable ? toolbarChildren : null,
              rowModelType: 'clientSide',
              rowData: permissionsRowData,
              columnDefs: isInDesign ? [] : columnDefs,
              onColumnResized(params) {
                if (params.finished) {
                  grantedPermissionsColumnWidth.current =
                    params.api.getColumn('grantedPermissions')?.getActualWidth() || 800;
                  params.api.resetRowHeights();
                }
              },
              getRowHeight(params) {
                if (params.node.group) {
                  return 54;
                }
                // TODO: 应该按照 权限名称实际上是什么语言 而不是按照 当前语言 来计算文字宽度
                const data = params.data;
                const items = data.grantedPermissions;
                const columnWidth = grantedPermissionsColumnWidth.current;
                if (columnWidth === null || items.length === 0) {
                  // 单行高度 54
                  return 54;
                }
                const text = items.map((perm) => perm.name).join('');
                // 字体宽度按 14 算，英文要除以 2
                const textWidth = (text.length * 14) / (language === 'en-US' ? 2 : 1);
                // 只读模式下除了文字以外，水平padding还有 14 宽度，然后每个选项之间用逗号分隔，约占 3.5 宽度
                const textRows = Math.floor(textWidth / (columnWidth - 14 - 3.5 * items.length));
                // 选择框里的每个标签除了文字以外额外有 32 的宽度需要加上
                const tagsWidth = textWidth + 32 * items.length;
                // 选择框的箭头与外面的padding等大概占宽度42，另外由于一个标签只能在一行内展示，所以额外减去标签宽度平均值的一半来估算
                const tagsRows = Math.floor(
                  tagsWidth / (columnWidth - 42 - tagsWidth / items.length / 2),
                );
                // 每增加一行增加 24 的高度，防止上面的取值有负值加上max
                return 54 + Math.max(0, editable ? tagsRows : textRows) * 24;
              },
            }}
          />
        )}
        <FormHelperText helpers={helpers} />
      </div>
    </ElementDecorator>
  );
});

PermissionsElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 获取数据的地址
     */
    dataUrl: PropTypes.string,
    /**
     * TODO, 设置 projectId 好像不合适，FormRenderer 里没有 project 的概念
     */
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
PermissionsElement.displayName = 'Permissions';

export default withFieldWrapper(PermissionsElement);
