import parseJSON from './parseJSON';

// 在 `inMilliseconds` 时间内调用本方法超过 `maxCount` 次则熔断，返回true。
// 历史记录记在 `sessionStorage` 中而不在内存中。
export function circuitBreakerViaSessionStorage({ storageKey, maxCount, inMilliseconds }) {
  const now = +new Date();
  const history = parseJSON(sessionStorage.getItem(storageKey)) || [];

  const result = history.length >= maxCount && now - history[maxCount - 1] < inMilliseconds;

  history.unshift(now);
  sessionStorage.setItem(storageKey, JSON.stringify(history.slice(0, maxCount)));

  return result;
}
