import { restApi } from '@icp/settings';
import { deCompressSchema, getSearchParams, parseJSON } from '@icp/utils';

const allLocalOldSchema = process.env.ALL_LOCAL_OLD_SCHEMA;

export function fetchLocalPbcEntityLayoutSchema({ pbcToken, formEntityToken, layoutToken }) {
  return Promise.all([
    restApi
      .getStatic(
        `${window.ICP_PUBLIC_PATH}pbc/${pbcToken}/forms/${formEntityToken}/layouts/${layoutToken}.json`,
        { params: { v: process.env.BUILD_NUMBER || 'DEV' } },
      )
      .then((schema) => {
        if (process.env.NODE_ENV === 'production') {
          return deCompressSchema(schema);
        }
        return schema;
      }),
    restApi.getStatic(
      `${window.ICP_PUBLIC_PATH}pbc/${pbcToken}/forms/${formEntityToken}/form-entity.json`,
      { params: { v: process.env.BUILD_NUMBER || 'DEV' } },
    ),
  ]).then(([schema, formEntity]) => {
    schema[Symbol.for('formEntity')] = {
      ...formEntity,
      isMasterData: !!formEntity.isMasterData,
      isAutoLock: !!formEntity.isAutoLock,
    };
    return schema;
  });
}

export function fetchLocalPbcPageSchema({ pbcToken, schemaId }) {
  const allLocalPbcSchema = process.env.ALL_LOCAL_PBC_SCHEMA;
  const pbc = allLocalPbcSchema[pbcToken];
  // url 的两种情况分别对应 3.0.21 版本过后 hasLocalSchema 里的两种判断 pbc.pages 和 pbc.noInfoPages
  const url = pbc?.pages?.[schemaId]
    ? `${window.ICP_PUBLIC_PATH}pbc/${pbcToken}/pages/${schemaId}/${schemaId}.json`
    : `${window.ICP_PUBLIC_PATH}pbc/${pbcToken}/pages/${schemaId}.json`;
  return restApi
    .getStatic(url, { params: { v: process.env.BUILD_NUMBER || 'DEV' } })
    .then((schema) => {
      if (process.env.NODE_ENV === 'production') {
        return deCompressSchema(schema);
      }
      return schema;
    });
}

export function fetchLocalSchemaFromOldSchemaDir(schemaId) {
  const url = `${window.ICP_PUBLIC_PATH}schema/${allLocalOldSchema[schemaId]}`;
  return restApi
    .getStatic(url, { params: { v: process.env.BUILD_NUMBER || 'DEV' } })
    .then((schema) => {
      if (process.env.NODE_ENV === 'production') {
        return deCompressSchema(schema);
      }
      return schema;
    });
}

export function fetchRemotePbcEntityLayoutSchema({ pbcToken, formEntityToken, layoutToken }) {
  const searchParams = getSearchParams();

  return Promise.all([
    restApi.getStatic(
      `/form/api/v2/form-entity-layout/${pbcToken}/${formEntityToken}/${layoutToken}/schema-json`,
      { params: searchParams },
    ),
    restApi.getStatic(`/form/api/v2/form-entity/${pbcToken}/${formEntityToken}`, {
      params: { needLayouts: false },
    }),
  ]).then(([schema, formEntity]) => {
    schema[Symbol.for('formEntity')] = {
      ...formEntity,
      isMasterData: !!formEntity.isMasterData,
      isAutoLock: !!formEntity.isAutoLock,
      autoLock: parseJSON(formEntity.autoLockJson),
    };
    return schema;
  });
}

export function fetchRemotePbcPageSchema({ pbcToken, schemaId }) {
  const url = `/form/api/form-entity-page/get-by-schema-id/${pbcToken}/${schemaId}`;
  return restApi.getStatic(url).then((res) => JSON.parse(res.schemaJson));
}

export function fetchRemoteSchemaFromLayoutId({ layoutId }) {
  const searchParams = getSearchParams();
  const url = `/form/api/form-entity-layout/${layoutId}/schema-json`;
  return restApi.getStatic(url, { params: searchParams });
}
