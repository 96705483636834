import { v4 as uuidv4 } from 'uuid';
import { escapeRegExp } from 'lodash-es';
import { restApi } from '@icp/settings';
import { parseJSON } from '@icp/utils';
import fetchSSE from './fetchSSE';

export const FileStatus = {
  Uploading: 'uploading',
  Done: 'done',
  Error: 'error',
};

export const MessageType = {
  Prompt: 'prompt',
  Answer: 'answer',
  Pending: 'pending',
  Image: 'image',
  Stream: 'stream',
  Error: 'Error',
};

export const MessageContentType = {
  PlainText: 'plaintext',
  Markdown: 'markdown',
};

export function uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file);
  return restApi.post('/aip/api/kimi/files', formData);
}

export function makeSerializableFiles(files) {
  // for keeping file meta information during JSON serialization
  return files.map((file) => {
    return {
      uid: file.uid,
      fileId: file.fileId,
      status: file.status,
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
    };
  });
}

export function createMessage(type, content, isSelf) {
  return {
    type,
    content,
    isSelf: isSelf || false,
    id: uuidv4(),
  };
}

export function getContentForContext(message) {
  const { content } = message;
  switch (message.type) {
    case MessageType.Prompt:
      return content.prompt;
    case MessageType.Error:
      return content.errorType;
    case MessageType.Image:
      return '[image]';
    case MessageType.Answer:
      return content.text;
    default:
      return typeof content === 'string' ? content : '';
  }
}

/*
  @deprecated use `fetchJsonBySSE` instead
*/
export async function fetchJSONStream(request, handlers, signal) {
  const { url, payload } = request;

  let total = '';
  for await (const e of fetchSSE({
    signal,
    url,
    body: JSON.stringify(payload),
  })) {
    if (e.event === '') {
      total += e.data;
      const partialResult = parseJSON(total, { silent: true, autocomplete: true });
      if (partialResult) {
        handlers.processPartialResult(partialResult);
      }
    } else if (e.event === 'done') {
      const finalResult = parseJSON(e.data, { silent: true, autocomplete: true });
      if (finalResult) {
        handlers.processFinalResult(finalResult);
      }
      handlers.doneCallback?.();
      return; // 遇done退出
    }
  }
  // 请求提前结束没收到done事件时也触发一下done回调，用于终止消息loading状态
  handlers.doneCallback?.();
}

// 假定如果有think则一定在前面，非think一定在后面，有开标签<think>没闭标签</think>时全都是think
// return [foundText, subsequentText]
function findTextWithin(text, startFlag, endFlag) {
  const startFlagMatch = text.match(new RegExp(`^${escapeRegExp(startFlag)}\n`, 'm'));
  if (!startFlagMatch) {
    return [null, text];
  }
  const startFlagIndex = startFlagMatch.index;
  const endFlagMatch = text.match(new RegExp(`\n${escapeRegExp(endFlag)}$`, 'm'));
  if (!endFlagMatch) {
    const foundText = text.slice(startFlagIndex + `${startFlag}\n`.length);
    return [foundText, null];
  }
  const endFlagIndex = endFlagMatch.index;
  const foundText = text.slice(startFlagIndex + `${startFlag}\n`.length, endFlagIndex);
  const subsequentText = text.slice(endFlagIndex + `\n${endFlag}\n`.length);
  return [foundText, subsequentText];
}

// 有think闭合标签但没开标签时补一个开标签
export function fixThinkTag(text) {
  if (text.includes('</think>') && !text.includes('<think>')) {
    return `<think>\n${text}`;
  }
  return text;
}

// 约定sse消息格式 data: { "content": "xxx" }, 不兼容消息格式 data: xxx
// 目前只支持解析 think标签块, json代码块, 普通文本视为json
export async function fetchJsonBySSE({ request, onThink, onJson }) {
  let total = '';
  let think = null;
  let json = null;
  for await (const message of fetchSSE(request)) {
    if (message.event === '') {
      const data = parseJSON(message.data);
      if (!data?.content) continue;
      total += data.content;
      total = fixThinkTag(total);
      const [thinkPart, nonThinkPart] = findTextWithin(total, '<think>', '</think>');
      if (thinkPart) {
        think = thinkPart;
        await onThink?.(think);
      }
      if (nonThinkPart) {
        const [jsonTextPart, nonJsonTextPart] = findTextWithin(nonThinkPart, '```json', '```');
        // AI返回可能包在 ```json 里也可能不包, 匹配到json代码块用代码块, 否则视为直接返回json
        const jsonText = jsonTextPart || nonJsonTextPart;
        json = parseJSON(jsonText, { silent: true, autocomplete: true });
        if (json) {
          await onJson?.(json);
        }
      }
    }
  }
  return { think, json };
}

export function findQuestionFromMessages(answerMessageId, messages) {
  const answerMessageIdx = messages.findIndex((x) => x.id === answerMessageId);
  if (answerMessageIdx <= 0) return null;
  for (let i = answerMessageIdx - 1; i >= 0; i--) {
    if (messages[i].type === 'prompt') {
      return messages[i].content.prompt;
    }
  }
  return null;
}
