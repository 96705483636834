export function composeGeneralHref(type, options) {
  const {
    hrefIsSiteBased,
    pbcToken,
    schemaId,
    formEntityToken,
    layoutToken,
    formEntityDataId,
    flowToken,
    flowInstanceId,
    threadId,
  } = options || {};

  if (type === 'site-home') {
    return '/';
  }

  let href = '';

  if (type === 'pbc-home') {
    href = '/';
  }

  if (type === 'page') {
    href = `/page/${schemaId}`;
  }

  if (type === 'form-create') {
    href = `/form/${formEntityToken}/layout/${layoutToken}`;
  }

  if (type === 'form-edit') {
    href = `/form/${formEntityToken}/layout/${layoutToken}/${formEntityDataId}`;
  }

  if (type === 'form-view') {
    href = `/form/${formEntityDataId}/view`;
  }

  if (type === 'flow-create') {
    href = `/flow/${flowToken}/new`;
  }

  if (type === 'flow-create-by-edit-form') {
    href = `/flow/${flowToken}/new?formEntityDataId=${formEntityDataId}`;
  }

  if (type === 'flow-edit') {
    href = `/flow/${flowInstanceId}/edit?formEntityDataId=${formEntityDataId}&threadId=${threadId}`;
  }

  if (type === 'flow-approval') {
    href = `/flow/${flowInstanceId}/approval`;
  }

  if (type === 'flow-create-by-approval') {
    href = `/flow/${flowToken}/approval-new?formEntityDataId=${formEntityDataId}`;
  }

  if (hrefIsSiteBased) {
    href = `/${pbcToken}${href}`;
  }

  // 处理多余斜杠 // 的情况
  if (href !== '/' && href.endsWith('/')) {
    href = href.slice(0, href.length - 1);
  }

  return href;
}

export function parseGeneralHref(href, options) {
  const { hrefIsSiteBased } = options;

  if (typeof href !== 'string') {
    return {};
  }

  if (href === '/') {
    return {
      type: hrefIsSiteBased ? 'site-home' : 'pbc-home',
    };
  }

  const paths = href.split('/');

  let pbcToken;
  if (hrefIsSiteBased) {
    pbcToken = paths.splice(1, 1)[0];
  }

  let config = {};

  if (paths.length === 1) {
    config = {
      type: 'pbc-home',
    };
  }

  if (paths.length === 3 && paths[1] === 'page') {
    config = {
      type: 'page',
      schemaId: paths[2],
    };
  }

  if (paths.length === 5 && paths[1] === 'form' && paths[3] === 'layout') {
    config = {
      type: 'form-create',
      formEntityToken: paths[2],
      layoutToken: paths[4],
    };
  }

  if (paths.length === 6 && paths[1] === 'form' && paths[3] === 'layout') {
    config = {
      type: 'form-edit',
      formEntityToken: paths[2],
      layoutToken: paths[4],
      formEntityDataId: paths[5],
    };
  }

  if (paths.length === 4 && paths[1] === 'form' && paths[3] === 'view') {
    config = {
      type: 'form-view',
      formEntityDataId: paths[2],
    };
  }

  if (paths.length === 4 && paths[1] === 'flow' && paths[3].startsWith('new')) {
    const searchParams = new URLSearchParams(paths[3].split('?')[1] || '');
    if (searchParams.size === 0) {
      config = {
        type: 'flow-create',
        flowToken: paths[2],
      };
    } else if (searchParams.size === 1 && searchParams.get('formEntityDataId')) {
      config = {
        type: 'flow-create-by-edit-form',
        formEntityDataId: searchParams.get('formEntityDataId'),
        flowToken: paths[2],
      };
    }
  }

  if (paths.length === 4 && paths[1] === 'flow' && paths[3].startsWith('edit')) {
    const searchParams = new URLSearchParams(paths[3].split('?')[1] || '');
    if (
      searchParams.size === 2 &&
      searchParams.get('formEntityDataId') &&
      searchParams.get('threadId')
    ) {
      config = {
        type: 'flow-edit',
        flowInstanceId: paths[2],
        formEntityDataId: searchParams.get('formEntityDataId'),
        threadId: searchParams.get('threadId'),
      };
    }
  }

  if (paths.length === 4 && paths[1] === 'flow' && paths[3] === 'approval') {
    config = {
      type: 'flow-approval',
      flowInstanceId: paths[2],
    };
  }

  if (paths.length === 4 && paths[1] === 'flow' && paths[3].startsWith('approval-new')) {
    const searchParams = new URLSearchParams(paths[3].split('?')[1] || '');
    if (searchParams.get('formEntityDataId')) {
      config = {
        type: 'flow-create-by-approval',
        flowToken: paths[2],
        formEntityDataId: searchParams.get('formEntityDataId'),
      };
    }
  }

  // config.type 有值才表示上面进过 if 条件，否则表示自定义链接
  if (config.type && pbcToken) {
    config.pbcToken = pbcToken;
  }

  return config;
}

export function configToTabName(config, options) {
  const { href, hrefIsSiteBased } = options;

  if (!config.type && href) {
    return 'customized';
  }

  if (config.type === 'site-home' || (config.type === 'pbc-home' && hrefIsSiteBased)) {
    return 'pbc';
  }

  if (config.type === 'pbc-home') {
    return 'page';
  }

  if (config.type?.startsWith('form')) {
    return 'form';
  }

  if (config.type?.startsWith('flow')) {
    return 'flow';
  }

  return 'page';
}

export function isValidFormConfig(config, hrefIsSiteBased) {
  if (config.type === 'form-view') {
    if (!hrefIsSiteBased) {
      return true;
    }
    return !!config.pbcToken;
  }

  if (config.type === 'form-create' || config.type === 'form-edit') {
    return !!(config.formEntityToken && config.layoutToken);
  }

  return false;
}

export function isValidFlowConfig(config, hrefIsSiteBased) {
  if (config.type === 'flow-edit' || config.type === 'flow-approval') {
    if (!hrefIsSiteBased) {
      return true;
    }
    return !!config.pbcToken;
  }

  if (config.type === 'flow-create') {
    return !!config.flowToken;
  }

  if (config.type === 'flow-create-by-edit-form' || config.type === 'flow-create-by-approval') {
    return !!config.flowToken && !!config.formEntityDataId;
  }

  return false;
}
