import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Loading } from '@icp/components';
import FormRenderer from '@icp/form-renderer';
import { transformToTableElement } from './utils';
import { makeTitleElement } from '../utils';

export default function IcpTable({ children: data }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [schema, setSchema] = useState();

  useEffect(() => {
    setLoading(true);
    transformToTableElement(data)
      .then((res) => {
        const [tableElementConfig, title] = res || [];
        if (!tableElementConfig) return;
        setSchema({ fields: [makeTitleElement(title), tableElementConfig] });
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <div>{error.message || 'Unknown error'}</div>;
  if (!schema) return null;

  return <FormRenderer schema={schema} />;
}

IcpTable.propTypes = {
  children: PropTypes.string,
};
