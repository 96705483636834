import loadable from '@loadable/component';
import { withStoreProvider } from './store';
import InputArea from './InputArea';

export default loadable(
  () =>
    import(
      /* webpackChunkName: "aiAgent" */
      /* webpackPrefetch: true */
      './AIAgent'
    ),
);

export { default as NewChatButton } from './NewChatButton';
export { default as useDefaultFeedbackSettings } from './hooks/useDefaultFeedbackSettings';
export {
  MessageType,
  MessageContentType,
  createMessage,
  fetchJSONStream,
  fetchJsonBySSE,
  findQuestionFromMessages,
} from './utils';

export { AGENT_ABORT_REASON } from './consts';

export const AIAgentInputArea = withStoreProvider(InputArea);
