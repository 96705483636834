export const SESSION_DATE_CATEGORY = {
  TODAY: 'today',
  THIS_WEEK: 'this-week',
  THIS_YEAR: 'this-year',
  OTHER: 'other',
};

export const VIEW_ENUM = {
  AGENT: 'agent',
  SESSION: 'session',
  MESSAGE: 'message',
};

export const API_URLS_DEFAULT = {
  getAgentList: '/gpt-wrapper-api/api/v1/agent/get-my-agents',
  getThreadList: '/gpt-wrapper-api/api/v1/agent/list-threads',
  getMessageList: '/gpt-wrapper-api/api/v1/agent/list-thread-messages',
  getActionList:
    '/form/api/v2/form-entity-data/system-agent/system-agent-form/{agent-id}/grid-list/actions',
};

export const AGENT_ABORT_REASON = {
  START_NEW_CHAT: 'start-new-chat',
  REGENERATE: 'regenerate',
};
